import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    FolderAddOutlined,
    MailOutlined,
    MessageOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Col, Popover, Row, Spin, notification } from 'antd';
import { Block, DataItem } from 'commons';
import { Barcode, FormattedDatetime } from 'components';
import { MODALS, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ClientVehicleTransfer, TecDocInfoModal, VehicleModal } from 'modals';
import {
    createOrder,
    deleteVehicle,
    fetchVehicle,
    fetchVehicleAttributes,
    fetchVehicleOrdersLatest,
    selectClient,
    selectFetchingOrdersLatest,
    selectFetchingVehicle,
    selectFetchingVehicleAttributes,
    selectFetchingVehicleClient,
    selectGeneralData,
    /* -------Selectors----*/
    selectVehicle,
    selectVehicleAttributes,
    sendEmailWithServiceBookLink,
    sendSmsWithServiceBookLink
} from 'pages/VehiclePage/redux/duck';
import { generateOnlineServiceBookLink, handleCopyToClipboard } from 'pages/VehiclePage/utils';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import history from 'store/history';
import { getLocale, getSessionId, linkTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { v4 } from 'uuid';
import Styles from './styles.m.css';

const DATE_FORMATT = 'DD.MM.YYYY';

const mapStateToProps = state => ({
    user: state.auth,
    vehicle: selectVehicle(state),
    client: selectClient(state),
    generalData: selectGeneralData(state),
    vehicleAttributes: selectVehicleAttributes(state),
    fetchingVehicle: selectFetchingVehicle(state),
    fetchingVehicleClient: selectFetchingVehicleClient(state),
    fetchingOrdersLatest: selectFetchingOrdersLatest(state),
    fetchingVehicleAttributes: selectFetchingVehicleAttributes(state)
});

const mapDispatchToProps = {
    setModal,
    fetchVehicle,
    fetchVehicleAttributes,
    fetchVehicleOrdersLatest,
    sendEmailWithServiceBookLink,
    sendSmsWithServiceBookLink,

    createOrder,
    deleteVehicle
};

/**
 * Supposed to represent general vehicle informations and its specific parameters.
 */
@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class GeneralInfoTab extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        this.props.fetchVehicleAttributes({ vehicleId: id });
        this.props.fetchVehicleOrdersLatest({ vehicleId: id });
    }

    /**
     * This event handler is used to create an order which will contain specific client and may contain vehicle if id was provided
     * @param {*} param0 Contains clientId which is used to define client in order and vehicleId of this client
     */
    onCreateOrder = ({ clientId, vehicleId }) => {
        const { user } = this.props;

        if (!clientId) {
            notification.error({
                message: this.props.intl.formatMessage({
                    id: 'vehicle_page.add_owner_vehicle'
                })
            });
        } else {
            this.props.createOrder({ clientId, managerId: user.id, vehicleId });
        }
    };

    onEditVehicle = ({ vehicleId }) => {
        this.props.setModal(MODALS.VEHICLE, {
            mode: 'EDIT',
            vehicleId,
            onSubmit: () => window.location.reload()
        });
    };

    onAddVehicle = ({ clientId }) => {
        this.props.setModal(MODALS.VEHICLE, { mode: 'ADD', clientId });
    };

    onViewVehicle = ({ vehicleId }) => {
        this.props.setModal(MODALS.VEHICLE, { mode: 'VIEW', vehicleId });
    };

    render() {
        const {
            user: { businessId },
            client,
            vehicle,
            generalData,
            vehicleAttributes,
            fetchingVehicle,
            fetchingVehicleClient,
            fetchingOrdersLatest,
            fetchingVehicleAttributes,
            deleteVehicle,
            sendEmailWithServiceBookLink,
            sendSmsWithServiceBookLink,
            disabled,

            intl: { formatMessage },
            user
        } = this.props;

        const sessionBussiness = getSessionId();

        const accessGranted = isGrantAccessed(
            user,
            grants.DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_SERVICE_BOOK,
            accesses.ROWO
        );

        return (
            <div className={Styles.tabContent}>
                <Block
                    className={Styles.block}
                    controls={
                        <div>
                            <Popover content={<FormattedMessage id='vehicle_page.hint_barcode_modal' />}>
                                <span style={{ width: '2em' }}>
                                    <Barcode
                                        barcodeClassName={Styles.barcodeIcon}
                                        iconStyle={{
                                            fontSize: 24,
                                            marginLeft: 4
                                        }}
                                        onConfirm={() => {
                                            window.location.reload();
                                        }}
                                        prefix='CVH'
                                        provideHint={false}
                                        referenceId={vehicle.id}
                                        table='CLIENTS_VEHICLES'
                                        value={vehicle.barcode}
                                    />
                                </span>
                            </Popover>

                            {isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_CAR_INFO) && (
                                <Popover content={<FormattedMessage id='vehicle_page.hint_vehicle_info_modal' />}>
                                    <span>
                                        <TecDocInfoModal modificationId={vehicle && vehicle.modificationId} />
                                    </span>
                                </Popover>
                            )}

                            {/* <Popover
                                content={
                                    <FormattedMessage id='vehicle_page.hint_view_vehicle_modal' />
                                }
                            >
                                <EyeOutlined
                                    className={Styles.editIcon}
                                    onClick={() =>
                                        this.onViewVehicle({ vehicleId: _.get(vehicle, 'id') })
                                    }
                                />
                            </Popover> */}

                            {!disabled && (
                                <React.Fragment>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_edit_vehicle_modal' />}>
                                        <EditOutlined
                                            className={Styles.editIcon}
                                            onClick={() => this.onEditVehicle({ vehicleId: _.get(vehicle, 'id') })}
                                        />
                                    </Popover>

                                    <Popover content={<FormattedMessage id='vehicle_page.hint_add_vehicle_modal' />}>
                                        <FolderAddOutlined
                                            className={Styles.editIcon}
                                            onClick={() => this.onAddVehicle({ clientId: _.get(client, 'clientId') })}
                                        />
                                    </Popover>

                                    <Popover content={<FormattedMessage id='vehicle_page.hint_delete_vehicle' />}>
                                        <DeleteOutlined
                                            className={Styles.deleteIcon}
                                            onClick={() => {
                                                deleteVehicle({ vehicleId: _.get(vehicle, 'id') });
                                                history.push({
                                                    pathname: `${book.vehicles}`
                                                });
                                            }}
                                        />
                                    </Popover>

                                    {isGrantAccessed(
                                        user,
                                        grants.DIRECTORIES_CARS_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE
                                    ) && (
                                        <Popover
                                            content={<FormattedMessage id='vehicle_page.hint_change_vehicle_owner' />}
                                        >
                                            {fetchingVehicleClient ? (
                                                <Spin />
                                            ) : (
                                                <span className={Styles.changeVehicleOwnerIcon}>
                                                    <ClientVehicleTransfer
                                                        clientId={client.clientId}
                                                        vehicleId={vehicle.id}
                                                        vehicles={[vehicle]}
                                                    />
                                                </span>
                                            )}
                                        </Popover>
                                    )}

                                    <Popover
                                        content={
                                            <FormattedMessage id='vehicle_page.hint_create_order_for_this_vehicle' />
                                        }
                                    >
                                        <Button
                                            className={Styles.iconButton}
                                            onClick={() =>
                                                this.onCreateOrder({
                                                    clientId: _.get(client, 'clientId'),
                                                    vehicleId: _.get(vehicle, 'id'),
                                                    businessRequisiteId: _.get(user, 'businessRequisitesId')
                                                })
                                            }
                                            type='primary'
                                        >
                                            <PlusOutlined className={Styles.plusIcon} />
                                        </Button>
                                    </Popover>
                                </React.Fragment>
                            )}
                        </div>
                    }
                    loading={fetchingVehicle}
                    title={<FormattedMessage id='orders.vehicle' />}
                >
                    <div>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='add_client_form.number' />}>
                            {vehicle.vehicleNumber}
                        </DataItem>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='add_order_form.vin' />}>
                            {vehicle.vehicleVin}
                        </DataItem>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='add_client_form.make' />}>
                            {vehicle.make}
                        </DataItem>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='add_client_form.model' />}>
                            {vehicle.model}
                        </DataItem>
                        <DataItem
                            className={Styles.dataItem}
                            label={<FormattedMessage id='add_client_form.modification' />}
                        >
                            {vehicle.modification}
                        </DataItem>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='add_client_form.year' />}>
                            {vehicle.year}
                        </DataItem>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='add_client_form.color' />}>
                            {vehicle.colorName || '-'}
                        </DataItem>
                        <DataItem
                            className={Styles.dataItem}
                            label={<FormattedMessage id='online_service_book_page.manager_name' />}
                        >
                            {vehicle.managerName || '-'}
                        </DataItem>
                        <DataItem
                            className={Styles.dataItem}
                            label={<FormattedMessage id='online_service_book_page.manager_phone' />}
                        >
                            {vehicle.managerPhone || '-'}
                        </DataItem>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='add_client_form.comment' />}>
                            {vehicle.comment || '-'}
                        </DataItem>
                        <DataItem
                            className={Styles.dataItem}
                            label={<FormattedMessage id='add_client_form.print_name' />}
                        >
                            {vehicle.registrationName || '-'}
                        </DataItem>
                    </div>

                    {fetchingVehicleAttributes ? (
                        <Spin />
                    ) : (
                        <div>
                            {_.get(vehicleAttributes, 'tecdocData') && (
                                <div>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='vehicle_page.body_type' />}
                                    >
                                        {vehicleAttributes.tecdocData.bodyType}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='vehicle_page.engine' />}
                                    >
                                        {vehicleAttributes.tecdocData.engineCode}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='vehicle_page.capacity' />}
                                    >
                                        {vehicleAttributes.tecdocData.capacity}
                                    </DataItem>

                                    {!_.get(vehicleAttributes, 'tecdocData.fuelType') && (
                                        <DataItem
                                            className={Styles.dataItem}
                                            label={<FormattedMessage id='vehicle_page.fuel_type' />}
                                        >
                                            {vehicleAttributes.tecdocData.fuelType}
                                        </DataItem>
                                    )}
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='vehicle_page.power' />}
                                    >
                                        {vehicleAttributes.tecdocData.power}
                                    </DataItem>
                                    <DataItem
                                        className={Styles.dataItem}
                                        label={<FormattedMessage id='vehicle_page.drive_type' />}
                                    >
                                        {vehicleAttributes.tecdocData.driveType}
                                    </DataItem>
                                    {getLocale() === 'uk' && (
                                        <DataItem
                                            className={Styles.dataItem}
                                            label={<FormattedMessage id='vehicle_page.kind' />}
                                        >
                                            {vehicleAttributes.tecdocData.kindType || vehicleAttributes.stateData?.kind}
                                        </DataItem>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    <div className={Styles.buttonsContainer}>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <Button
                                    className={Styles.button}
                                    disabled={!accessGranted}
                                    onClick={async () => {
                                        const link = await generateOnlineServiceBookLink({
                                            vehicleId: _.get(vehicle, 'id'),
                                            businessId,
                                            sessionBussiness
                                        });
                                        window.open(link, '_blank'); // Open in a new tab
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='vehicle_page.service_book' />
                                </Button>
                            </Col>
                            {accessGranted && (
                                <Col span={18}>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_send_via_sms' />}>
                                        <MessageOutlined
                                            className={Styles.sendSMSIcon}
                                            onClick={() => sendSmsWithServiceBookLink()}
                                        />
                                    </Popover>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_send_via_email' />}>
                                        <MailOutlined
                                            className={Styles.sendMailIcon}
                                            onClick={() => sendEmailWithServiceBookLink()}
                                        />
                                    </Popover>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_copy_to_clipboard' />}>
                                        <CopyOutlined
                                            className={Styles.copyIcon}
                                            onClick={async () => {
                                                const link = await generateOnlineServiceBookLink({
                                                    vehicleId: _.get(vehicle, 'id'),
                                                    businessId,
                                                    sessionBussiness
                                                });
                                                handleCopyToClipboard(link);
                                                notification.success();
                                            }}
                                        />
                                    </Popover>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Block>

                {/* --------------------------------------------------------------------------- */}

                <Block
                    className={Styles.block}
                    controls={
                        <div>
                            {isGrantAccessed(
                                user,
                                grants.DIRECTORIES_AGGREGATES_LIST_GENERAL_INFORMATION_CARD_CAR_OWNER_CHANGE,
                                accesses.ROWO
                            ) && (
                                <Popover content={<FormattedMessage id='vehicle_page.hint_change_vehicle_owner' />}>
                                    <span className={Styles.changeVehicleOwnerIcon}>
                                        <ClientVehicleTransfer
                                            clientId={client.clientId}
                                            vehicleId={vehicle.id}
                                            vehicles={[vehicle]}
                                        />
                                    </span>
                                </Popover>
                            )}
                        </div>
                    }
                    loading={fetchingVehicleClient}
                    title={<FormattedMessage id='vehicle_page.clients' />}
                >
                    <div>
                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='name' />}>
                            <Link to={`${book.client}/${_.get(client, 'clientId')}`}>
                                {`${_.get(client, 'name') || ''} ${_.get(client, 'surname') || ''}`}
                            </Link>
                        </DataItem>

                        <DataItem className={Styles.dataItem} label={<FormattedMessage id='add_client_form.phones' />}>
                            {_.map(_.get(client, 'phones', []), phone => (
                                <a key={v4()} className={Styles.phoneLink} href={`tel:${phone}`}>
                                    {phone}
                                </a>
                            ))}
                        </DataItem>
                    </div>
                </Block>

                {/* --------------------------------------------------------------------------- */}

                <Block
                    className={Styles.block}
                    loading={fetchingOrdersLatest}
                    title={<FormattedMessage id='vehicle_page.last_data' />}
                >
                    <div>
                        <div>
                            <DataItem className={Styles.dataItem} label={<FormattedMessage id='order-status.order' />}>
                                <a
                                    className={Styles.orderLink}
                                    href={`${book.order}/${_.get(generalData, 'latestOrderData.orderId')}`}
                                >
                                    <FormattedDatetime
                                        datetime={_.get(generalData, 'latestOrderData.datetime')}
                                        format={DATE_FORMATT}
                                    />
                                </a>
                            </DataItem>
                            <DataItem className={Styles.dataItem} label={<FormattedMessage id='order-status.call' />}>
                                <FormattedDatetime
                                    datetime={_.get(generalData, 'callData.datetime')}
                                    format={DATE_FORMATT}
                                />
                            </DataItem>
                        </div>

                        <div className={Styles.buttonsContainer}>
                            <Row className={Styles.row}>
                                <Col span={6}>
                                    <Button
                                        className={Styles.button}
                                        disabled={disabled}
                                        onClick={() => {
                                            const orderId = _.get(generalData, 'latestOrderData.orderId');
                                            orderId && linkTo(`${book.order}/${orderId}`);
                                        }}
                                        type='primary'
                                    >
                                        {`${formatMessage({ id: 'vehicle_page.record' })}: ${dayjs(
                                            _.get(generalData, 'latestOrderData.datetime')
                                        ).format(DATE_FORMATT)}`}
                                    </Button>
                                </Col>
                                <Col span={18}>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_send_via_sms' />}>
                                        <MessageOutlined className={Styles.sendSMSIcon} />
                                    </Popover>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_send_via_email' />}>
                                        <MailOutlined className={Styles.sendMailIcon} />
                                    </Popover>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_copy_to_clipboard' />}>
                                        <CopyOutlined
                                            className={Styles.copyIcon}
                                            onClick={() =>
                                                handleCopyToClipboard(
                                                    `${book.order}/${_.get(generalData, 'latestOrderData.orderId')}`
                                                )
                                            }
                                        />
                                    </Popover>
                                </Col>
                            </Row>

                            <Row className={Styles.row}>
                                <Col span={6}>
                                    <a href={`${_.get(generalData, 'linkData.link') || '#!'}`}>
                                        <Button
                                            className={Styles.button}
                                            disabled={
                                                disabled ||
                                                !isGrantAccessed(
                                                    user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_COORDINATION
                                                )
                                            }
                                            type='primary'
                                        >
                                            <FormattedMessage id='vehicle_page.calculation' />
                                        </Button>
                                    </a>
                                </Col>
                                <Col span={18}>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_send_via_sms' />}>
                                        <MessageOutlined className={Styles.sendSMSIcon} />
                                    </Popover>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_send_via_email' />}>
                                        <MailOutlined className={Styles.sendMailIcon} />
                                    </Popover>
                                    <Popover content={<FormattedMessage id='vehicle_page.hint_copy_to_clipboard' />}>
                                        <CopyOutlined
                                            className={Styles.copyIcon}
                                            onClick={() =>
                                                handleCopyToClipboard(`${_.get(generalData, 'linkData.link')}`)
                                            }
                                        />
                                    </Popover>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Block>

                <VehicleModal />
            </div>
        );
    }
}

import { Form, Modal, notification, Radio, Row, Select, Space, TimePicker } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { DateRangePicker } from 'components';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { DATE_YMD_FORMAT, DAY_OFF_TYPES, HOURS_REGEX_FORMAT } from 'utils/constants';

const ChangeScheduleModal = ({ open, employees, handleClose, updateData, intl: { formatMessage } }) => {
    const [form] = useForm();
    // const [periodEnabled, setPeriodEnabled] = useState(false);
    // const [dayOffEnabled, setDayOffEnabled] = useState(false);
    const [okButtonLoading, setOkButtonLoading] = useState(false);
    const [radioValue, setRadioValue] = useState(0);

    const employeesOptions = useMemo(
        () => employees.map(({ id, name, surname }) => ({ value: id, label: `${surname} ${name}` })),
        [employees]
    );

    const handleFieldValueChange = (dataIndex, value) => form.setFieldValue(dataIndex, value);

    const onRadioChange = e => {
        let fieldsToValidate = [];
        if (radioValue === 2) fieldsToValidate = ['registredStartTime', 'registredEndTime'];
        else if (radioValue === 3) fieldsToValidate = ['typeOfDayOff'];
        form.validateFields(fieldsToValidate);
        setRadioValue(e.target.value);
        form.setFieldValue('radioValue', e.target.value);
    };

    const onOk = () => {
        form.submit();
    };

    const submitScheduleChange = async dataToSubmit => {
        try {
            setOkButtonLoading(true);
            await fetchAPI('PUT', '/registred_hours_batch', null, dataToSubmit, { handleErrorInternally: true });
            notification.success({ message: formatMessage({ id: 'message.success' }) });
            handleClose();
        } catch (e) {
            notification.error(formatMessage({ id: 'error' }));
        } finally {
            setOkButtonLoading(false);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        handleClose();
    };

    const handleSubmit = () => {
        const { registredStartTime, registredEndTime, typeOfDayOff, period, radioValue, ...rest } =
            form.getFieldsValue();
        const dataToSubmit = {
            ...rest,
            dateFrom: period[0].format(DATE_YMD_FORMAT),
            dateTo: period[1].format(DATE_YMD_FORMAT),
            ...(radioValue === 1 && { planFact: true }),
            ...(radioValue === 2 &&
                registredStartTime &&
                registredEndTime && {
                    registredStartTime: registredStartTime.format(HOURS_REGEX_FORMAT),
                    registredEndTime: registredEndTime.format(HOURS_REGEX_FORMAT)
                }),
            ...(radioValue === 3 && { realDayOff: true, typeOfDayOff })
        };
        submitScheduleChange(dataToSubmit);
        if (updateData) updateData();
        handleCancel();
    };

    return (
        <Modal
            maskClosable={false}
            okButtonProps={{ loading: okButtonLoading }}
            onCancel={handleCancel}
            onOk={onOk}
            open={open}
            title={formatMessage({ id: 'registered_time' })}
        >
            <Form form={form} layout='vertical' name='changeSchedule' onFinish={handleSubmit}>
                <Form.Item
                    label={formatMessage({ id: 'navigation.employees' })}
                    name='employeeIds'
                    rules={[{ required: true }]}
                >
                    <Select
                        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                        mode='multiple'
                        optionFilterProp='label'
                        options={employeesOptions}
                        showSearch
                    />
                </Form.Item>
                <Form.Item
                    label={formatMessage({ id: 'period' })}
                    name='period'
                    rules={[{ required: true }]}
                    valuePropName='dateRange'
                >
                    <DateRangePicker
                        onDateChange={dateRange => handleFieldValueChange('period', dateRange)}
                        // dateRange={[dayjs(startDate), dayjs(endDate)]}
                        // minimize // prevent default space
                        // onDateChange={this._setCallsDaterange}
                        // style={{ margin: '0 8px 0 0' }}
                    />
                </Form.Item>
                {/* <FormattedMessage id='change_type' /> */}
                {/* name='radioValue' */}
                <Form.Item label={formatMessage({ id: 'change_type' })} name='radioValue' rules={[{ required: true }]}>
                    <Radio.Group
                        label={formatMessage({ id: 'change_type' })}
                        onChange={onRadioChange}
                        value={radioValue}
                    >
                        <Space direction='vertical'>
                            <Radio value={1}>
                                {formatMessage({ id: 'employee-page.plan_eq_fact' })}
                                {/* <Form.Item
                                label={formatMessage({ id: 'employee-page.plan_fact' })}
                                name='realDayOff'
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item> */}
                            </Radio>
                            <Radio value={2}>
                                <Row>
                                    <Space>
                                        <FormattedMessage id='registered_time' />
                                        <Form.Item
                                            name='registredStartTime'
                                            rules={[{ required: radioValue === 2, message: false }]}
                                            shouldUpdate
                                            style={{ marginBottom: 0 }}
                                        >
                                            <TimePicker
                                                disabled={radioValue !== 2}
                                                format='HH:mm'
                                                minuteStep={30}
                                                placeholder={formatMessage({ id: 'start_time' })}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name='registredEndTime'
                                            rules={[{ required: radioValue === 2, message: false }]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <TimePicker
                                                disabled={radioValue !== 2}
                                                format='HH:mm'
                                                minuteStep={30}
                                                placeholder={formatMessage({ id: 'end_time' })}
                                            />
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </Radio>
                            <Radio value={3}>
                                <Space>
                                    <FormattedMessage id='reports_page.day_off' />
                                    <Form.Item
                                        name='typeOfDayOff'
                                        rules={[{ required: radioValue === 3, message: false }]}
                                        shouldUpdate
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Select
                                            disabled={radioValue !== 3}
                                            options={DAY_OFF_TYPES.map(({ value, label }) => ({
                                                value,
                                                label: formatMessage({ id: `reports_page.${label}` })
                                            }))}
                                            placeholder={formatMessage({ id: 'reports_page.day_off_type' })}
                                        />
                                    </Form.Item>
                                </Space>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                {/* <Row>
                    <Form.Item label={formatMessage({ id: 'registered_time' })}>
                        <Space>
                            <Checkbox checked={periodEnabled} onChange={e => setPeriodEnabled(e.target.checked)} />
                        </Space>
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item label={formatMessage({ id: 'reports_page.day_off' })}>
                        <Space>
                            <Checkbox checked={dayOffEnabled} onChange={e => setDayOffEnabled(e.target.checked)} />
                            
                            <Form.Item hidden={!dayOffEnabled}  name='timeEnd'>
                            <TimePicker disabled={!dayOffEnabled} />
                        </Form.Item>
                        </Space>
                    </Form.Item>
                </Row> */}
            </Form>
        </Modal>
    );
};

export default injectIntl(ChangeScheduleModal);

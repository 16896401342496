exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".styles-m__innerTable---tJ6m3{margin-left:48px}.styles-m__bold---yMPEB{font-weight:700}.styles-m__detailNumberInput---36fUV .ant-input-number .ant-input-number-input{text-align:right;padding-right:24px}.styles-m__actionMenuIcon---v0Xok{padding:0 4px 0 0;font-size:18px;vertical-align:sub;display:inline-block}", "",{"version":3,"sources":["styles.m.css"],"names":[],"mappings":"AAAA,8BACI,gBACJ,CAEA,wBACI,eACJ,CACA,+EAGE,gBAAiB,CACjB,kBACF,CAEA,kCACE,iBAAkB,CAClB,cAAe,CACf,kBAAmB,CACnB,oBACF","file":"styles.m.css","sourcesContent":[".innerTable {\n    margin-left: 48px;\n}\n\n.bold {\n    font-weight: 700;\n}\n.detailNumberInput\n:global(.ant-input-number)\n:global(.ant-input-number-input) {\n  text-align: right;\n  padding-right: 24px;\n}\n\n.actionMenuIcon {\n  padding: 0 4px 0 0;\n  font-size: 18px;\n  vertical-align: sub;\n  display: inline-block;\n}"]}]);

// Exports
exports.locals = {
	"innerTable": "styles-m__innerTable---tJ6m3",
	"bold": "styles-m__bold---yMPEB",
	"detailNumberInput": "styles-m__detailNumberInput---36fUV",
	"actionMenuIcon": "styles-m__actionMenuIcon---v0Xok"
};
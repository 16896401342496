/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import { Button, Input, Modal } from 'antd';
import { Spinner } from 'commons';
import { confirmAgreement } from 'core/agreement/saga';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { get } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI, getCurrency, getLocale, setLocale } from 'utils';
import AgreementResult from './components/AgreementResult';
import DetailElement from './components/DetailElement';
import ServiceElement from './components/ServiceElement';
import Styles from './styles.m.css';
// Я спочатку рахую загальний тотал, потім суму всіх офнутих деталей, а потім уже думаю))))
// треба буде константи норм назвати в render

@injectIntl
class AgreementPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            confirmed: false,
            dataSource: undefined,
            servicesList: [],
            detailsList: [],
            commentary: null
        };
        this.servicesTotal = 0;
        this.detailsTotal = 0;
        this.servicesDiscount = 0;
        this.detailsDiscount = 0;
        this.business = {};
        this.manager = {};
    }

    showConfirm() {
        const title = this.props.intl.formatMessage({ id: 'agreement.confirm_title' });
        const content = this.props.intl.formatMessage({ id: 'agreement.confirm_content' });
        const { confirm } = Modal;
        confirm({
            title,
            content,
            onOk: () => {
                this.handleOk();
            },
            onCancel: () => {
                console.log('Canceled');
            }
        });
    }

    handleOk() {
        const resultData = {
            disableLaborIds: [],
            disableDetailIds: [],
            comment: this.state.commentary
        };

        this.state.servicesList.forEach(elem => {
            if (!elem.checked) {
                elem.ids.forEach(id => {
                    resultData.disableLaborIds.push(id);
                });
            }
        });

        this.state.detailsList.forEach(elem => {
            if (!elem.checked) {
                elem.ids.forEach(id => {
                    resultData.disableDetailIds.push(id);
                });
                elem.variations.forEach(elem => {
                    elem.ids.forEach(id => {
                        resultData.disableDetailIds.push(id);
                    });
                });
            } else if (elem.radio) {
                elem.variations.forEach(elem => {
                    elem.ids.forEach(id => {
                        resultData.disableDetailIds.push(id);
                    });
                });
            } else {
                elem.ids.forEach(id => {
                    resultData.disableDetailIds.push(id);
                });
                elem.variations
                    .filter(({ radio }) => !radio)
                    .forEach(elem => {
                        elem.ids.forEach(id => {
                            resultData.disableDetailIds.push(id);
                        });
                    });
            }
        });

        confirmAgreement(this.sessionId, resultData, this.props.intl.locale);
        this.setState({
            confirmed: true
        });
    }

    updateData = data => {
        const { taxRate, isTaxPayer } = data.stats;
        this.taxRate = isTaxPayer ? taxRate : 1;
        this.detailsDiscount = data.detailsDiscount;
        this.servicesDiscount = data.servicesDiscount;
        this.detailsTotal = data.details.reduce((acc, det) => acc + Number(det.sum * this.taxRate), 0);
        this.business = data.business;
        this.manager = data.manager;
        this.setState({
            isOpened: data.isOpened,
            dataSource: data,
            orderDiscount: data.orderDiscount,
            loading: false,
            detailsList: this.buildVariations(
                data.details.map(elem => {
                    elem.checked = elem.agreement != 'REJECTED';
                    elem.discount = data.detailsDiscount ? data.detailsDiscount / 100 : 0;
                    elem.price = elem.price * this.taxRate || 0;
                    elem.rowDiscountPrice = elem.rowDiscountPrice * this.taxRate || 0;
                    elem.sum = elem.sum * this.taxRate || 0;
                    elem.totalDetailsSum = data.stats.detailsSum * this.taxRate || 0;
                    elem.measureUnit = elem.measureUnit || '-';

                    return elem;
                })
            ),
            servicesList: data.labors.map(elem => {
                elem.checked = elem.agreement != 'REJECTED';
                elem.discount = data.servicesDiscount ? data.servicesDiscount / 100 : 0;
                elem.price = elem.price * this.taxRate || 0;
                elem.sum = elem.sum * this.taxRate || 0;
                elem.totalServiceSum = data.stats.laborsSum * this.taxRate || 0;
                elem.measureUnit = elem.measureUnit || '-';

                return elem;
            })
        });
    };

    onSwitchService = (index, value) => {
        this.state.servicesList[index].checked = value;
        this.setState({
            update: true
        });
    };

    onSwitchDetail = (index, value) => {
        this.state.detailsList[index].checked = value;
        this.setState({
            update: true
        });
    };

    getAgreementData = async (sessionId, lang) => {
        const data = await fetchAPI('GET', 'orders/agreement', { sessionId, lang }, null, {
            handleErrorInternally: true
        });
        this.updateData(data);
    };

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.sessionId = urlParams.get('sessionId');
        this.lang = urlParams.get('lang');
        let localeLang = getLocale();
        if (localeLang == 'uk') {
            localeLang = 'ua';
        }
        if (localeLang != this.lang) {
            setLocale(this.lang);
            window.location.reload();
        }
        this.getAgreementData(this.sessionId, this.lang);
        window.addEventListener('resize', this.updateDimensions);
    }

    formatPhoneNumber = input => {
        const phoneNumber = parsePhoneNumberFromString(input);

        if (phoneNumber && phoneNumber.isValid()) {
            return phoneNumber.formatInternational(); // e.g., +1 202-555-0123
        }

        return null;
    };

    updateDimensions = () => {
        this.setState({});
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    buildVariations = orderDetails => {
        const tmp = [...orderDetails]
            .map((elem, key) => {
                return { ...elem, variations: [], radio: true };
            })
            .filter(({ groupName, agreement, id }) => {
                const res = orderDetails.filter(({ groupName: gpName }) => gpName === groupName);
                if (res.every(({ agreement }) => agreement === 'REJECTED') && id === res[0].id) {
                    return true;
                }

                return (groupName && agreement !== 'REJECTED') || !groupName;
            });

        orderDetails
            .filter(({ id }) => tmp.findIndex(({ id: ID }) => ID === id) === -1)
            .forEach((elem, key) => {
                const index = tmp.findIndex(({ groupName }) => groupName === elem.groupName);
                if (index > -1) {
                    tmp[index].variations.push({
                        ...elem,
                        leadIndex: index,
                        key,
                        radio: false
                    });
                }
            });

        return tmp.map((elem, key) => ({
            ...elem,
            key
        }));
    };

    render() {
        const { TextArea } = Input;
        const isMobile = window.innerWidth < 1200;
        const { dataSource, confirmed, loading, detailsList, orderDiscount } = this.state;
        const { business, manager } = this;
        const { formatMessage } = this.props.intl;
        const notCheckedServices = this.state.servicesList.filter(
            ({ checked, agreement }) => !checked || agreement === 'REJECTED'
        );
        const disabledSumServices = notCheckedServices.reduce((acc, service) => acc + Number(service.sum), 0);
        const notCheckedDetails = this.state.detailsList.filter(
            ({ checked, agreement, variation }) => !checked || agreement === 'REJECTED' || get(variation, 'length')
        );
        let disabledSumDetails = 0; // notCheckedDetails.reduce((acc, service) => acc + Number(service.sum), 0);

        if (loading) {
            return <Spinner spin />;
        }
        if (confirmed) {
            return <AgreementResult status='success' subtitle='You can close this tab' title='Successfully, Thanks!' />;
        }
        if (dataSource == undefined) {
            return <AgreementResult title='There are some problems with your operation.' />;
        }

        this.servicesTotal = 0;
        const vehicleNumber = dataSource.vehicle.vehiclenumber;
        const vehicleMake = dataSource.vehicle.make;
        const vehicleModel = dataSource.vehicle.model;
        const vehicleModification = dataSource.vehicle.modification;
        const { brandEnabled, codeEnabled } = get(dataSource.details, '[0]', {});

        const servicesElements = this.state.servicesList.map((data, index) => {
            if (data.checked) {
                this.servicesTotal = data.totalServiceSum - disabledSumServices;
            }

            return (
                <ServiceElement
                    key={index}
                    checked={data.checked}
                    data={data}
                    detailsList={detailsList}
                    discount={this.servicesDiscount ? this.servicesDiscount / 100 : 0}
                    isMobile={isMobile}
                    num={index + 1}
                    onSwitchService={this.onSwitchService}
                />
            );
        });

        const detailsElements = this.state.detailsList.map((data, index) => {
            if (!data.checked || !data.radio) {
                disabledSumDetails += data.sum;
            }
            if (data.variations.length) {
                data.variations.forEach(variation => {
                    if (!variation.radio || !data.checked) {
                        disabledSumDetails += variation.sum;
                    }
                });
            }

            return (
                <DetailElement
                    key={index}
                    checked={data.checked}
                    data={data}
                    detailsList={detailsList}
                    discount={this.detailsDiscount ? this.detailsDiscount / 100 : 0}
                    isMobile={isMobile}
                    isOpened={this.state.isOpened}
                    num={index + 1}
                    onRadioDetail={(index, counter) => {
                        this.setState(prevState => {
                            const el = prevState.detailsList[index];

                            return {
                                detailsList: [
                                    ...prevState.detailsList.slice(0, index),
                                    {
                                        ...prevState.detailsList[index],
                                        ...el,
                                        radio: counter === 0,
                                        variations: el.variations.map((el, nd) => ({
                                            ...el,
                                            radio: nd + 1 === counter
                                        }))
                                    },
                                    ...prevState.detailsList.slice(index + 1)
                                ]
                            };
                        });
                    }}
                    onSwitchDetail={this.onSwitchDetail}
                />
            );
        });
        const totalSum = dataSource.stats.isTaxPayer
            ? Number(dataSource.stats.totalSumWithTax * 10) / 10
            : Number((dataSource.stats.totalSum + orderDiscount || 0) * 10) / 10;

        const onlyCheckedService = this.state.servicesList.filter(({ checked }) => checked);

        const totalDiscountServices =
            this.state.servicesList
                .filter(({ checked, agreement }) => checked && agreement != 'REJECTED')
                .reduce((acc, cur) => acc + cur.price * cur.count, 0) - this.servicesTotal;
        let totalDiscountDetails = this.state.detailsList
            .filter(({ checked, agreement, radio }) => checked && radio && agreement != 'REJECTED')
            .reduce((acc, cur) => acc + (cur.price - cur.rowDiscountPrice) * cur.count, 0);
        // const totalDiscountDetails = this.detailsDiscount
        //     ? (this.detailsTotal - disabledSumDetails) / (1 - this.detailsDiscount / 100) -
        //       (this.detailsTotal - disabledSumDetails)
        //     : 0;

        if (totalDiscountDetails < 0) totalDiscountDetails = 0;

        console.log(
            totalDiscountDetails,
            this.detailsTotal,
            disabledSumDetails,
            this.detailsTotal - disabledSumDetails
        );

        return isMobile ? (
            <div className={Styles.agreementPage}>
                <div className={Styles.agreementHeader}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <div style={{ textTransform: 'uppercase' }}>
                            <p>{vehicleNumber}</p>
                            <p>
                                {vehicleMake} {vehicleModel}
                            </p>
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    this.showConfirm();
                                }}
                                style={{ height: '100%' }}
                                type='primary'
                            >
                                <FormattedMessage id='save' />
                            </Button>
                        </div>
                    </div>
                    {orderDiscount < 0 ? (
                        <div style={{ marginTop: 7, fontSize: 16 }}>
                            {`${formatMessage({
                                id: 'order_form_table.order_discount'
                            })}: ${Math.abs(orderDiscount)} ${getCurrency()}`}
                        </div>
                    ) : (
                        ' '
                    )}
                    <div style={orderDiscount < 0 ? { marginTop: 7, fontSize: 16 } : { marginTop: 8 }}>
                        {`${formatMessage({
                            id: 'order_form_table.total_sum'
                        })}: ${Number(this.servicesTotal + (this.detailsTotal - disabledSumDetails) + orderDiscount)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${getCurrency()}`}
                    </div>
                </div>
                <div className={Styles.businessInfo}>
                    <span>{business.name}:</span>{' '}
                    <a className={Styles.phoneNumber} href={`tel:${this.formatPhoneNumber(business.phones[0])}`}>
                        {this.formatPhoneNumber(business.phones[0])}
                    </a>
                </div>
                <div className={Styles.businessInfo}>
                    <span>{manager.name}:</span>{' '}
                    <a className={Styles.phoneNumber} href={`tel:${this.formatPhoneNumber(manager.phone)}`}>
                        {this.formatPhoneNumber(manager.phone)}
                    </a>
                </div>
                {servicesElements.length ? (
                    <div className={Styles.servicesWrap}>
                        <div className={Styles.sectionHeader}>
                            <span>
                                <FormattedMessage id='agreement.general_discount' />:
                                <span className={Styles.totalSum}>
                                    {Number(totalDiscountServices).toFixed(2)}
                                    {getCurrency()}
                                </span>
                            </span>

                            <span>
                                <FormattedMessage id='add_order_form.services' />:
                                <span className={Styles.totalSum}>
                                    {Number(this.servicesTotal)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                                    {getCurrency()}
                                </span>
                            </span>
                        </div>
                        {servicesElements}
                    </div>
                ) : null}
                {detailsElements.length ? (
                    <div className={Styles.detailsWrap}>
                        <div className={Styles.sectionHeader}>
                            <span>
                                <FormattedMessage id='agreement.general_discount' />:
                                <span className={Styles.totalSum}>
                                    {Number(totalDiscountDetails).toFixed(2)}
                                    {getCurrency()}
                                </span>
                            </span>

                            <span className={Styles.totalSum}>
                                <FormattedMessage id='add_order_form.details' />:
                                <span className={Styles.totalSum}>
                                    {Number(this.detailsTotal - disabledSumDetails)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                                    {getCurrency()}
                                </span>
                            </span>
                        </div>
                        {detailsElements}
                    </div>
                ) : null}
                <div className={Styles.commentWrap}>
                    <div className={Styles.sectionHeader}>
                        <FormattedMessage id='comment' />
                    </div>
                    <div className={Styles.commentElement}>
                        <TextArea
                            className={Styles.commentaryTextArea}
                            onChange={() => {
                                this.state.commentary = event.target.value;
                            }}
                            placeholder={`${this.props.intl.formatMessage({ id: 'comment' })}...`}
                            rows={5}
                        />
                    </div>
                </div>
            </div>
        ) : (
            <div className={Styles.agreementPage}>
                <div className={Styles.businessInfo}>
                    <span>{business.name}:</span>{' '}
                    <a href={`tel:${this.formatPhoneNumber(business.phones[0])}`}>
                        {this.formatPhoneNumber(business.phones[0])}
                    </a>
                </div>
                <div className={Styles.businessInfo}>
                    <span>{manager.name}:</span>{' '}
                    <a href={`tel:${this.formatPhoneNumber(manager.phone)}`}>{this.formatPhoneNumber(manager.phone)}</a>
                </div>
                <div className={Styles.vehicleInfoWrap}>
                    <div className={`${Styles.vehicleInfo} ${Styles.vehicleNumber}`}>
                        {vehicleMake} {vehicleModel} {vehicleModification} {vehicleNumber}
                    </div>
                </div>
                <div className={`${Styles.agreementHeader}`}>
                    <div className={`${Styles.columnHeader} ${Styles.rowKey}`}>
                        <span>#</span>
                    </div>
                    <div className={`${Styles.columnHeader} ${Styles.rowName}`}>
                        <span>
                            <FormattedMessage id='description' />
                        </span>
                    </div>
                    {brandEnabled && (
                        <div
                            className={`${Styles.columnHeader} ${
                                brandEnabled && codeEnabled ? Styles.rowBrand : Styles.rowBrandSeparate
                            }`}
                        >
                            <span>
                                <FormattedMessage id='brand' />
                            </span>
                        </div>
                    )}
                    {codeEnabled && (
                        <div
                            className={`${Styles.columnHeader} ${
                                brandEnabled && codeEnabled ? Styles.rowCode : Styles.rowCodeSeparate
                            }`}
                        >
                            <span>
                                <FormattedMessage id='storage.product_code' />
                            </span>
                        </div>
                    )}
                    <div className={`${Styles.columnHeader} ${Styles.rowPrice}`}>
                        <span>
                            <FormattedMessage id='price' />
                        </span>
                    </div>
                    <div className={`${Styles.columnHeader} ${Styles.rowDiscount}`}>
                        <span>
                            <FormattedMessage id='order_form_table.discount' />
                        </span>
                    </div>
                    <div className={`${Styles.columnHeader} ${Styles.rowDiscount}`}>
                        <span>
                            <FormattedMessage id='storage.measure_unit' />
                        </span>
                    </div>
                    <div className={`${Styles.columnHeader} ${Styles.rowCount}`}>
                        <span>
                            <FormattedMessage id='count' />
                        </span>
                    </div>
                    <div className={`${Styles.columnHeader} ${Styles.rowSum}`}>
                        <span>
                            <FormattedMessage id='sum' />
                        </span>
                    </div>
                    <div className={`${Styles.columnHeader} ${Styles.rowSwitch}`}>
                        <span></span>
                    </div>
                </div>
                {servicesElements.length ? (
                    <div className={Styles.servicesWrap}>
                        <div className={Styles.sectionHeader}>
                            <FormattedMessage id='add_order_form.services' />
                        </div>
                        {servicesElements}
                        <div className={Styles.totalWrap}>
                            <span>
                                <FormattedMessage id='agreement.general_discount' />:
                                <span className={Styles.totalSum}>
                                    {Number(totalDiscountServices).toFixed(2)}
                                    {getCurrency()}
                                </span>
                            </span>
                            <span>
                                <FormattedMessage id='add_order_form.services' />:
                                <span className={Styles.totalSum}>
                                    {Number(this.servicesTotal)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                                    {getCurrency()}
                                </span>
                            </span>
                        </div>
                    </div>
                ) : null}
                {detailsElements.length ? (
                    <div className={Styles.detailsWrap}>
                        <div className={Styles.sectionHeader}>
                            <FormattedMessage id='add_order_form.details' />
                        </div>
                        {detailsElements}
                        <div className={Styles.totalWrap}>
                            <span>
                                <FormattedMessage id='agreement.general_discount' />:
                                <span className={Styles.totalSum}>
                                    {Number(totalDiscountDetails).toFixed(2)}
                                    {getCurrency()}
                                </span>
                            </span>

                            <span>
                                <FormattedMessage id='add_order_form.details' />:
                                <span className={Styles.totalSum}>
                                    {Number(this.detailsTotal - disabledSumDetails)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                                    {getCurrency()}
                                </span>
                            </span>
                        </div>
                    </div>
                ) : null}
                <div>
                    <TextArea
                        className={Styles.commentaryTextArea}
                        disabled={!this.state.isOpened}
                        onChange={() => {
                            this.state.commentary = event.target.value;
                        }}
                        placeholder={`${this.props.intl.formatMessage({ id: 'comment' })}...`}
                        rows={5}
                    />
                </div>
                {orderDiscount < 0 ? (
                    <div className={`${Styles.agreementTotalSum} ${Styles.totalWrapEnd}`}>
                        <FormattedMessage id='order_form_table.order_discount' />
                        <span className={Styles.totalSum}>
                            {Number(Math.abs(orderDiscount))
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                            {getCurrency()}
                        </span>
                    </div>
                ) : (
                    ''
                )}
                <div className={`${Styles.agreementTotalSum} ${Styles.totalWrapEnd}`}>
                    <FormattedMessage id='order_form_table.total_sum' />
                    <span className={Styles.totalSum}>
                        {Number(this.servicesTotal + (this.detailsTotal - disabledSumDetails) + orderDiscount)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                        {getCurrency()}
                    </span>
                </div>
                <Button
                    disabled={!this.state.isOpened}
                    onClick={() => {
                        this.showConfirm();
                    }}
                    type='primary'
                >
                    <FormattedMessage id='save' />
                </Button>
            </div>
        );
    }
}

export default AgreementPage;

import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Modal, Radio, Select, Table } from 'antd';
import { Layout, MobileView, ResponsiveView, Spinner } from 'commons';
import { fetchClients } from 'core/clients/duck';
import { fetchAddClientForm } from 'core/forms/addClientForm/duck';
import { createOrder, fetchAddOrderForm, returnToOrdersPage, setCreateStatus } from 'core/forms/orderForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { MobileAddOrderForm, OrderForm } from 'forms';
import { recommendationsColumns } from 'forms/OrderForm/OrderFormTables/RecommendationsTable/columns';
import { convertFieldsValuesToDbEntity } from 'forms/OrderForm/extractOrderEntity';
import _ from 'lodash';
import { DashboardModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import { BREAKPOINTS, fetchAPI, withErrorMessage } from 'utils';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;

const mapStateToProps = state => {
    return {
        // addClientFormData: state.forms.addClientForm.data,
        allDetails: state.forms.orderForm.allDetails,
        allServices: state.forms.orderForm.allServices,
        createStatus: state.forms.orderForm.createStatus,
        employees: state.forms.orderForm.employees,
        managers: state.forms.orderForm.managers,
        modal: state.modals.modal,
        orderHistory: state.forms.orderForm.history,
        orderStationLoads: state.forms.orderForm.stationLoads,
        requisites: state.forms.orderForm.requisites,
        selectedClient: state.forms.orderForm.selectedClient,
        spinner: state.ui.orderFetching,
        stations: state.forms.orderForm.stations,
        user: state.auth,
        vehicles: state.forms.orderForm.vehicles,
        clients: state.clients.clients
    };
};

const mapDispatch = {
    fetchClients,
    fetchAddOrderForm,
    fetchAddClientForm,
    setModal,
    resetModal,
    createOrder,
    setCreateStatus,
    returnToOrdersPage
};

@withRouter
@connect(mapStateToProps, mapDispatch)
@withErrorMessage()
@injectIntl
class AddOrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: void 0,
            selectedRows: [],
            allLinks: [],
            helperDrawerOpen: false,
            vehicles: [],
            aggregates: []
        };
    }

    componentDidMount() {
        this.props.fetchAddOrderForm();
        // this.fetchHelperLinks();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedClient !== this.props.selectedClient || prevProps.vehicles !== this.props.vehicles) {
            this.fetchVehicles(this.props.selectedClient ? this.props.selectedClient.clientId : undefined);
            this.fetchAggregates(this.props.selectedClient ? this.props.selectedClient.clientId : undefined);
        }
    }

    handleSearchClientVehicles = _.debounce(async query => {
        const vehicles = await fetchAPI(
            'GET',
            `/client/vehicles/${this.props.selectedClient.clientId}`,
            { page: 1, pageSize: 50, query },
            undefined,
            {
                handleErrorInternally: true
            }
        );

        this.setState({
            vehicles: vehicles.result
        });
    }, 500);

    fetchVehicles = async selectedVehicleId => {
        const vehicles = await fetchAPI(
            'GET',
            `/client/vehicles/${this.props.selectedClient.clientId}`,
            { page: 1, pageSize: 10 },
            undefined,
            {
                handleErrorInternally: true
            }
        );

        const isVehicleInclude = vehicles ? vehicles.result.findIndex(({ id }) => id === selectedVehicleId) : undefined;

        if (isVehicleInclude === -1) {
            const currentVehicle = await fetchAPI(
                'GET',
                `/client/vehicles/${this.props.selectedClient.clientId}`,
                { page: 1, pageSize: 10, vehicleId: selectedVehicleId },
                undefined,
                {
                    handleErrorInternally: true
                }
            );

            this.setState({
                vehicles: isVehicleInclude > -1 ? vehicles.result : [...currentVehicle.result, ...vehicles.result]
            });
        } else {
            this.setState({
                vehicles: vehicles.result
            });
        }
    };

    fetchAggregates = async (clientId = this.props.selectedClient?.clientId) => {
        if (clientId) {
            const aggregates = await fetchAPI(
                'GET',
                'client/aggregates',
                {
                    clientId
                },
                null,
                { handleErrorInternally: true }
            );

            this.setState({
                aggregates
            });
        }
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'add_application' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    saveOrderFormRef = formRef => {
        this.orderFormRef = formRef;
    };

    _createOrder = (redirectStatus, redirectTo) => {
        const form = this.orderFormRef;
        const { allServices, allDetails, selectedClient, createStatus, user, stations } = this.props;
        const { recommendations, selectedRows } = this.state;

        const fm = form.props ? form.props.form : form;

        fm.validateFields()
            .then(async values => {
                const orderFormEntity = {
                    ...values,
                    selectedClient,
                    addServices: selectedRows.map(({ recommendationId }) => recommendationId)
                };
                const redirectToDashboard = false;

                const createOrder = () => {
                    this.props.createOrder({
                        order: convertFieldsValuesToDbEntity(
                            orderFormEntity,
                            allServices,
                            allDetails,
                            createStatus,
                            user,
                            stations,
                            { ignoreFieldsOnAdd: true }
                        ),
                        redirectStatus,
                        redirectToDashboard,
                        redirectTo
                    });
                };

                if (
                    !recommendations &&
                    (orderFormEntity.clientVehicle || _.get(selectedClient, 'clientAggregateId', undefined))
                ) {
                    const recommendations = await fetchAPI(
                        'GET',
                        'recommendations/labors',
                        {
                            vehicleId: orderFormEntity.clientVehicle || undefined,
                            aggregateId: _.get(selectedClient, 'clientAggregateId') || undefined,
                            onlyActive: true
                        },
                        null,
                        { handleErrorInternally: true }
                    );
                    if (recommendations && recommendations.list.length) {
                        this.setState({
                            recommendations: recommendations.list
                        });
                    } else {
                        createOrder();
                    }
                } else {
                    createOrder();
                }
            })
            .catch(errorInfo => {
                console.log(errorInfo);
            });
    };

    _redirect = () => {
        const { returnToOrdersPage, history, createStatus } = this.props;

        _.get(history, 'location.state.fromDashboard')
            ? history.push(`${book.dashboard}`)
            : returnToOrdersPage(createStatus);
    };

    _setAddClientModal = () => {
        this.props.fetchAddClientForm();
        this.props.setModal(MODALS.ADD_CLIENT);
    };

    _setCreateStatus = status => this.props.setCreateStatus(status);

    render() {
        const {
            modal,
            user,
            createStatus,
            spinner,
            setModal,
            resetModal,
            intl: { formatMessage },
            selectedClient
        } = this.props;
        const { errors, recommendations, selectedRowKeys, helperDrawerOpen, allLinks, vehicles, aggregates } =
            this.state;

        return spinner ? (
            <Spinner spin={spinner} />
        ) : (
            <Layout
                controls={
                    <React.Fragment>
                        <div>
                            {window.innerWidth >= 1200 ? (
                                <React.Fragment>
                                    <RadioGroup value={createStatus}>
                                        <RadioButton onClick={() => this._setCreateStatus('reserve')} value='reserve'>
                                            <FormattedMessage id='reserve' />
                                        </RadioButton>
                                        <RadioButton
                                            onClick={() => this._setCreateStatus('not_complete')}
                                            value='not_complete'
                                        >
                                            <FormattedMessage id='not_complete' />
                                        </RadioButton>
                                        <RadioButton onClick={() => this._setCreateStatus('required')} value='required'>
                                            <FormattedMessage id='required' />
                                        </RadioButton>
                                        <RadioButton onClick={() => this._setCreateStatus('approve')} value='approve'>
                                            <FormattedMessage id='approve' />
                                        </RadioButton>
                                    </RadioGroup>
                                    <Button
                                        className={Styles.submit}
                                        htmlType='submit'
                                        onClick={() => {
                                            this._createOrder(createStatus);
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='add' />
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <Button
                                    className={Styles.submit}
                                    htmlType='submit'
                                    onClick={() => {
                                        this._setCreateStatus('reserve');
                                        this._createOrder(createStatus);
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='add' />
                                </Button>
                            )}
                        </div>

                        <Button
                            icon={
                                <CloseOutlined
                                    style={{
                                        fontSize: 24
                                    }}
                                />
                            }
                            onClick={this._redirect}
                            type='text'
                        />
                        <Button
                            icon={<QuestionCircleOutlined />}
                            onClick={() => {
                                this.fetchHelperLinks();
                                this.setState({
                                    helperDrawerOpen: true
                                });
                            }}
                            style={{
                                fontSize: 22,
                                marginLeft: 8,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            type='text'
                        />
                    </React.Fragment>
                }
                title={<FormattedMessage id='orders-page.add_appointment' />}
            >
                <MobileView>
                    <MobileAddOrderForm
                        addOrderForm
                        allDetails={this.props.allDetails}
                        allService={this.props.allServices}
                        errors={this.state.errors}
                        location={this.props.history.location}
                        modal={modal}
                        orderHistory={this.props.orderHistory}
                        orderStationLoads={this.props.orderStationLoads}
                        requisites={[]}
                        selectedClient={{
                            requisites: [],
                            phones: [],
                            emails: [],
                            vehicles: []
                        }}
                        setAddClientModal={this._setAddClientModal}
                        user={user}
                        vehicles={vehicles}
                        wrappedComponentRef={this.saveOrderFormRef}
                    />
                </MobileView>
                <ResponsiveView view={{ min: BREAKPOINTS.sm.max, max: BREAKPOINTS.xxl.max }}>
                    <OrderForm
                        aggregates={aggregates}
                        allDetails={this.props.allDetails}
                        allService={this.props.allServices}
                        createOrder={this._createOrder}
                        createStatus={createStatus}
                        errors={this.state.errors}
                        fetchVehicles={this.fetchVehicles}
                        handleSearchClientVehicles={this.handleSearchClientVehicles}
                        location={this.props.history.location}
                        modal={modal}
                        modals={MODALS}
                        orderHistory={this.props.orderHistory}
                        orderStationLoads={this.props.orderStationLoads}
                        requisites={[]}
                        selectedClient={{
                            requisites: [],
                            phones: [],
                            emails: []
                        }}
                        setAddClientModal={this._setAddClientModal}
                        setModal={setModal}
                        user={user}
                        vehicles={vehicles}
                        wrappedComponentRef={this.saveOrderFormRef}
                    />
                    <DashboardModal
                        onSelect={(stationNum, time) => {
                            const form = this.orderFormRef;
                            const { stationLoads } = form.getFieldsValue();
                            form.setFieldsValue({
                                stationLoads: [
                                    {
                                        ...stationLoads[0],
                                        station: stationNum,
                                        beginDate: time,
                                        beginTime: time
                                    },
                                    stationLoads[1]
                                ]
                                // 'stationLoads[0].station': stationNum,
                                // 'stationLoads[0].beginDate': time,
                                // 'stationLoads[0].beginTime': time
                            });
                            resetModal();
                        }}
                        resetModal={() => resetModal()}
                        visible={modal}
                    />
                    <Modal
                        maskClosable={false}
                        onCancel={async () => {
                            await this.setState({ selectedRows: [] });

                            this._createOrder(createStatus);
                        }}
                        onOk={() => {
                            this._createOrder(createStatus);
                        }}
                        open={recommendations && recommendations.length}
                        title={<FormattedMessage id='add_labor.from_recom' />}
                        width='fit-content'
                    >
                        <Table
                            bordered
                            columns={recommendationsColumns({}).slice(0, -1)}
                            dataSource={recommendations || []}
                            pagination={false}
                            rowKey='recommendationId'
                            rowSelection={{
                                selectedRowKeys,
                                onChange: (selectedRowKeys, selectedRows) => {
                                    this.setState({
                                        selectedRowKeys,
                                        selectedRows
                                    });
                                }
                            }}
                            size='small'
                        />
                    </Modal>
                </ResponsiveView>
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </Layout>
        );
    }
}

export default AddOrderPage;

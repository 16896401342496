/**
 * This tab combines existing functionality for calls(duck, saga) but provides client oriented result.
 * For example we search calls for specific client only ond show only them without charts.
 */

import { Select } from 'antd';
import { Spinner } from 'commons';
import { DateRangePicker } from 'components';
import { fetchCalls, setCallsChannelId, setCallsDaterange, setCallsTab, setClientFilter, tabs } from 'core/calls/duck';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import CallsTable from './CallsTable';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    tab: state.calls.tab,
    calls: state.calls.calls,
    channels: state.calls.channels,
    filter: state.calls.filter,
    callsInitializing: state.ui.callsInitializing,
    callsFetching: state.ui.callsFetching,
    clientEntity: state.client.clientEntity
});

const mapDispatchToProps = {
    fetchCalls,
    setCallsDaterange,
    setCallsChannelId,
    setCallsTab,
    setClientFilter
};

@connect(mapStateToProps, mapDispatchToProps)
export default class ClientCallsTab extends Component {
    constructor(props) {
        super(props);

        this.state = { channels: [] };
    }

    componentDidMount() {
        const { setCallsTab } = this.props;

        setCallsTab(tabs.callsTable); // Set this tab as we use this one only here
        this.getChannels();
        this._setClientFilter();
    }

    getChannels = async (sDate, eDate) => {
        const channels = await fetchAPI(
            'GET',
            '/calls/chart',
            {
                type: this.props.filter.type === 'all' ? undefined : this.props.filter.filter.type || 'in',

                startDate: this.props.filter?.startDate,
                endDate: this.props.filter?.endDate
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            channels: channels.chart
        });
    };

    /**
     * Set datarange filter and fetch filtered calls
     * @param {*} daterange datarange of a filter
     */
    _setCallsDaterange = daterange => {
        const { setCallsDaterange, fetchCalls } = this.props;

        setCallsDaterange(daterange);
        fetchCalls();
    };

    /**
     * Calls can be received with different channels. We can define a filter for those channels.
     * @param {*} channelId
     */
    _setCallsChannelId = channelId => {
        const { setCallsChannelId, fetchCalls } = this.props;

        setCallsChannelId(channelId === 'ALL' ? null : channelId);

        fetchCalls();
    };

    /**
     * Set client filter, is is needed to fetch data for a specific client
     */
    _setClientFilter = () => {
        const { setClientFilter, clientEntity } = this.props;
        const { clientId } = clientEntity || {};

        clientId && setClientFilter({ clientId }); // We search all calls for specific client only on this page
    };

    render() {
        const {
            callsInitializing,
            callsFetching,
            filter: { startDate, endDate }
        } = this.props;
        const { channels } = this.state;

        return callsInitializing ? (
            <Spinner spin={callsInitializing} />
        ) : (
            <div>
                <div className={Styles.filters}>
                    {callsFetching ? (
                        ''
                    ) : (
                        <DateRangePicker
                            dateRange={[dayjs(startDate), dayjs(endDate)]}
                            minimize // prevent default space
                            onDateChange={this._setCallsDaterange}
                            style={{ margin: '0 8px 0 0' }}
                        />
                    )}
                    {channels && (
                        <Select className={Styles.channels} defaultValue='ALL' onChange={this._setCallsChannelId}>
                            <Option value='ALL'>
                                <FormattedMessage id='all' />
                            </Option>
                            {channels.map(({ id, name }) => (
                                <Option key={id} value={id}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    )}
                </div>

                <CallsTable />
            </div>
        );
    }
}

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
// import { hot } from 'react-hot-loader/root';
import { notification } from 'antd';
import ConfigProviderExtended from 'components/ConfigProviderExtended';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import book from 'routes/book';
import { ConnectedIntlProvider as IntlProvider, getLocale, goTo } from 'utils';
import { useAutoLogout } from './hooks/useAutoLogout';
import Routes from './routes/Main';
import history from './store/history';
import store, { persistor } from './store/store';

const loadScript = src => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(script);
    script.src = src;
};

const lang = getLocale();
loadScript(
    `https://maps.googleapis.com/maps/api/js?key=AIzaSyB44HA5xmBlrPN6PwKGxEZoU95aZAy-UXc&v=3.exp&language=${lang}&libraries=drawing,places`
);

const App = () => {
    const handleLogout = () => {
        const token = localStorage.getItem('_my.carbook.pro_token');
        if (token) {
            console.info('Disconnecting');
            notification.error({ message: 'Сеанс завершено (неактивність)', description: 'Session ended (inactivity)', duration: 0 });
            localStorage.removeItem('_my.carbook.pro_token');
            goTo(book.login);
            // window.location.href = '/login'; // Or use react-router navigate
        }
    };

    useAutoLogout(handleLogout);

    return (
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <IntlProvider>
                        <ConfigProviderExtended>
                            <ConnectedRouter history={history}>
                                <Routes />
                            </ConnectedRouter>
                        </ConfigProviderExtended>
                    </IntlProvider>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    );
};

export default App;

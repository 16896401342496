import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Menu, Modal, Switch, Table } from 'antd';
import { HamburgerMenu } from 'components';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { WorkshopCheckAddModal } from './components';
import Styles from './styles.m.css';

const WorkshopCheckTab = ({ intl, disabled }) => {
    const [workshopChecks, setWorkshopChecks] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [editMode, setEdit] = useState(false);
    const [open, openAddModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const fetchWorkshopCheck = useCallback(
        async (querySearch, accountId) => {
            setLoading(true);
            const response = await fetchAPI('GET', '/business/workshop/check/settings', { page, pageSize }, null, {
                handleErrorInternally: true
            });

            setWorkshopChecks(response.checkSettings);
            setCount(response.count);
            setLoading(false);
        },
        [page, pageSize]
    );

    const changeIsActive = useCallback(async (row, value) => {
        await fetchAPI(
            'PUT',
            '/business/workshop/check/settings',
            null,
            { id: row.id, isActive: value },
            {
                handleErrorInternally: true
            }
        );

        fetchWorkshopCheck();
    }, []);

    const deleteRow = useCallback(
        async row => {
            const selectedIds = selectedRows.map(({ id }) => id);
            await fetchAPI(
                'DELETE',
                '/business/workshop/check/settings',
                null,
                { ids: selectedRows?.length ? selectedIds : [row.id] },
                {
                    handleErrorInternally: true
                }
            );

            fetchWorkshopCheck();
        },
        [selectedRows]
    );

    const handleFieldChange = async (record, propName, newValue) => {
        const newObj = _.pick(record, ['id', 'name', 'enable', 'block', 'value']);
        const updateBody = {
            ...newObj,
            [propName]: newValue
        };
        if (updateBody.block && propName !== 'enable') updateBody.enable = true;
        if (!updateBody.enable) updateBody.block = false;
        setLoading(true);
        await fetchAPI('POST', 'order_checks', null, updateBody, { handleErrorInternally: true });
        await fetchWorkshopCheck();
    };

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='№' />,
                key: 'num',
                width: '1%',
                render: (row, number, index) => {
                    return `${index + 1}.`;
                }
            },
            {
                title: () => {
                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.Item
                                key='deleteCode'
                                disabled={!selectedRows.length}
                                onClick={() => {
                                    Modal.confirm({
                                        title: intl.formatMessage({
                                            id: 'add_order_form.delete_confirm'
                                        }),
                                        onOk: async () => {
                                            await deleteRow();
                                        }
                                    });
                                }}
                            >
                                <div>
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} disabled={!selectedRows.length} />;
                },
                key: 'actions',
                align: 'center',
                width: '10%',
                render: row => {
                    const actionsMenu = (
                        <Menu className={Styles.actionMenuDropdown}>
                            <Menu.Item key='editCode'>
                                <div
                                    onClick={() => {
                                        openAddModal(true);
                                        setEdit(row);
                                    }}
                                >
                                    <EditOutlined className={Styles.actionMenuIcon} />
                                    {intl.formatMessage({ id: 'edit' })}
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                key='deleteCode'
                                onClick={() => {
                                    Modal.confirm({
                                        title: intl.formatMessage({
                                            id: 'add_order_form.delete_confirm'
                                        }),
                                        onOk: async () => {
                                            await deleteRow(row);
                                        }
                                    });
                                }}
                            >
                                <div>
                                    <DeleteOutlined className={Styles.actionMenuIcon} />
                                    {intl.formatMessage({ id: 'delete' })}
                                </div>
                            </Menu.Item>
                        </Menu>
                    );

                    return <HamburgerMenu actionsMenu={actionsMenu} />;
                }
            },
            // {
            //     title: () => {
            //         return (
            //             <div>
            //                 <Tooltip title={<FormattedMessage id='add' />}>
            //                     <Button
            //                         icon={<PlusOutlined />}
            //                         onClick={async () => {
            //                             await openAddModal(true);
            //                         }}
            //                     />
            //                 </Tooltip>
            //             </div>
            //         );
            //     },
            //     key: 'actions',
            //     width: '5%'
            //     // render: row => {
            //     //     return (
            //     //         <div
            //     //             style={{
            //     //                 display: 'flex',
            //     //                 justifyContent: 'space-around'
            //     //             }}
            //     //         >
            //     //             <Popconfirm
            //     //                 onConfirm={async () => {
            //     //                     await deleteRow(row);
            //     //                 }}
            //     //                 title={`${intl.formatMessage({ id: 'delete' })}?`}
            //     //             >
            //     //                 <Button icon={<DeleteOutlined />} />
            //     //             </Popconfirm>
            //     //             <Button
            //     //                 icon={<EditOutlined />}
            //     //                 onClick={() => {
            //     //                     openAddModal(true);
            //     //                     setEdit(row);
            //     //                 }}
            //     //             />
            //     //         </div>
            //     //     );
            //     // }
            // },
            {
                title: <FormattedMessage id='description' />,
                key: 'name',
                width: '30%',
                render: row => {
                    return row.check ? row.check : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='info_modal.value' />,
                key: 'value',
                width: '30%',
                render: row => {
                    return row.value ? row.value : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='services_table.check_code' />,
                key: 'name',
                width: '15%',
                render: row => {
                    return row.code === 'DONE' ? <FormattedMessage id='workshop_table.button.finish' /> : row.code;
                }
            },
            {
                key: 'isActive',
                width: '10%',
                align: 'center',
                render: row => {
                    return <Switch onChange={() => changeIsActive(row, !row.isActive)} value={row.isActive} />;
                }
            }
        ];
    }, [selectedRows.length, intl, deleteRow, changeIsActive]);

    useEffect(() => {
        fetchWorkshopCheck();
    }, [fetchWorkshopCheck]);

    const rowSelection = useMemo(() => {
        return {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRows);
                setSelectedRowKeys(selectedRowKeys);
            }
        };
    }, [selectedRowKeys, selectedRows]);

    const pagination = {
        pageSize,
        size: 'small',
        hideOnSinglePage: false,
        showSizeChanger: true,
        total: Math.ceil(count / pageSize) * pageSize,
        current: page,
        onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
        }
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginBottom: '10px'
                }}
            >
                <Button
                    onClick={async () => {
                        await openAddModal(true);
                    }}
                    type='primary'
                >
                    <FormattedMessage id='add' />
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={workshopChecks}
                loading={loading}
                pagination={pagination}
                rowKey={record => record.id}
                rowSelection={rowSelection}
            />
            <WorkshopCheckAddModal
                editMode={editMode}
                fetchWorkshopCheck={fetchWorkshopCheck}
                hideModal={() => {
                    openAddModal(false);
                    setEdit(undefined);
                    setTimeout(() => fetchWorkshopCheck(), 200);
                }}
                open={open}
            />
        </div>
    );
};

export default injectIntl(WorkshopCheckTab);

/**
 * This table component is used to show client calls, it takes data from existing redux-saga core(calls).
 */

import { Radio, Table } from 'antd';
import { Catcher, Loader } from 'commons';
import {
    fetchCalls,
    fetchRecordingLink,
    selectCallsData,
    selectCallsFilter,
    selectCallsLinksCache,
    selectCallsStats,
    setCallsPageFilter,
    setCallsType
} from 'core/calls/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { columnsConfig } from './config.js';
import Styles from './styles.m.css';

const mapStateToProps = state => {
    return {
        user: state.auth,
        calls: selectCallsData(state),
        stats: selectCallsStats(state),
        filter: { ...selectCallsFilter(state) },
        callsLinksCache: selectCallsLinksCache(state),
        callsFetching: state.ui.callsFetching
    };
};

const mapDispatchToProps = {
    fetchCalls,
    setCallsPageFilter,
    setCallsType,
    fetchRecordingLink
};

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CallsTable extends Component {
    formatTime = seconds => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;

        return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    render() {
        const {
            calls,
            stats,
            filter,
            intl: { formatMessage },
            callsFetching,
            fetchRecordingLink,
            callsLinksCache,
            user,
            setCallsType
        } = this.props;

        const columns = columnsConfig({
            formatMessage,
            fetchRecordingLink,
            callsLinksCache,
            user,
            formatTime: this.formatTime
        });

        const pagination = {
            pageSize: 25,
            size: 'small',
            total: Math.ceil(_.get(stats, 'total') / 25) * 25,
            current: filter.page,
            onChange: page => {
                this.props.setCallsPageFilter(page);
                this.props.fetchCalls();
            }
        };

        return callsFetching ? (
            <Loader loading={callsFetching} />
        ) : (
            <Catcher>
                <RadioGroup
                    defaultValue='all'
                    onChange={async e => {
                        await setCallsType(e.target.value);
                        this.props.fetchCalls();
                    }}
                    style={{ marginBottom: 8, marginLeft: 8 }}
                    value={filter?.type}
                >
                    <RadioButton value='all'>
                        <FormattedMessage id='calls-table.all' />
                    </RadioButton>
                    <RadioButton value='in'>
                        <FormattedMessage id='calls-table.in' />
                    </RadioButton>

                    <RadioButton value='out'>
                        <FormattedMessage id='calls-table.out' />
                    </RadioButton>
                </RadioGroup>
                <Table
                    bordered
                    className={Styles.table}
                    columns={columns}
                    dataSource={calls}
                    loading={callsFetching}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={pagination}
                    rowKey={() => v4()}
                    scroll={{ x: 1080 }}
                    size='small'
                />
            </Catcher>
        );
    }
}

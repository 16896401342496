/* eslint-disable no-underscore-dangle */
import {
    CloseOutlined,
    CopyOutlined,
    DeleteOutlined,
    DollarOutlined,
    DragOutlined,
    LinkOutlined,
    SaveOutlined
} from '@ant-design/icons';
import { Button, Modal, Space, Tooltip, notification } from 'antd';
import { Layout, MobileView, ResponsiveView, Spinner } from 'commons';
import { ReportsDropdown } from 'components';
import ChangeRtlStatusDropdown from 'components/ChangeRtlStatusDropdown';
import { fetchAddClientForm } from 'core/forms/addClientForm/duck';
import {
    createInviteOrder,
    createOrderCopy,
    fetchOrderForm,
    fetchOrderTasks,
    returnToOrdersPage,
    selectInviteData,
    setOrderTasksFilters,
    updateOrder
} from 'core/forms/orderForm/duck';
import { changeModalStatus, resetOrderTasksForm, saveOrderTask } from 'core/forms/orderTaskForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { fetchReport, getReport } from 'core/order/duck';
import { setCollapsedState } from 'core/ui/duck';
import dayjs from 'dayjs';
import { MobileRecordForm, OrderForm } from 'forms';
import { convertFieldsValuesToDbEntity } from 'forms/OrderForm/extractOrderEntity';
import _, { get } from 'lodash';
import {
    CancelReasonModal,
    ConfirmOrderExitModal,
    DashboardModal,
    EmployeeTaskModal,
    OrderTaskModal,
    RefactoredCashOrderModal,
    ReferenceBookAddModal,
    StoreProductModal
} from 'modals';
import NetworkModal from 'pages/DashboardPage/components/NetworkModal';
import { RTL, RTN } from 'pages/RetailsPage/constants';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { BREAKPOINTS, difference, fetchAPI, orderTypeMapper, roundCurrentTime, withErrorMessage } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import { CheckIsOrderSavedHelper } from './checkIsOrderSavedHelper';
import OrderCheckModal from './components/OrderCheckModal';
import Styles from './styles.m.css';

const { confirm } = Modal;

const compareOrderTasks = (initialOrderTask, orderTask) => {
    if (!initialOrderTask) {
        return true;
    }

    const initialOrderTaskEntity = {
        responsibleId: initialOrderTask.responsibleId,
        priority: initialOrderTask.priority,
        comment: initialOrderTask.comment,
        time: initialOrderTask.deadlineDate ? dayjs(initialOrderTask.deadlineDate).format('HH:mm') : void 0,
        date: initialOrderTask.deadlineDate ? dayjs(initialOrderTask.deadlineDate).format('YYYY-MM-DD') : void 0,
        stationNum: initialOrderTask.stationNum
    };

    const orderTaskEntity = {
        responsibleId: orderTask.responsible,
        priority: orderTask.priority,
        comment: orderTask.comment,
        time: orderTask.deadlineTime ? dayjs(orderTask.deadlineTime).format('HH:mm') : void 0,
        date: orderTask.deadlineDate ? dayjs(orderTask.deadlineDate).format('YYYY-MM-DD') : void 0,
        stationNum: orderTask.stationName
    };

    return !_.isEqual(_.omitBy(orderTaskEntity, _.isNil), _.omitBy(initialOrderTaskEntity, _.isNil));
};

const mapStateToProps = state => {
    return {
        // orderTaskEntity:       state.forms.orderTaskForm.fields,
        // addClientFormData:     state.forms.addClientForm.data,
        clients: state.forms.orderForm.clients,
        employees: state.forms.orderForm.employees,
        fetchedOrder: state.forms.orderForm.fetchedOrder,
        fields: state.forms.orderForm.fields,
        initialOrderTask: state.forms.orderTaskForm.initialOrderTask,
        initOrderEntity: state.forms.orderForm.initOrderEntity,
        invited: state.forms.orderForm.invited,
        inviteOrderId: state.forms.orderForm.inviteOrderId,
        isMobile: state.ui.views.isMobile,
        modal: state.modals.modal,
        modalProps: state.modals.modalProps,
        order: state.forms.orderForm.order,
        orderCalls: state.forms.orderForm.calls,
        orderComments: state.forms.orderForm.orderComments,
        orderTaskId: state.forms.orderTaskForm.taskId,
        orderTasks: state.forms.orderForm.orderTasks,
        priorityOptions: state.forms.orderTaskForm.priorityOptions,
        progressStatusOptions: state.forms.orderTaskForm.progressStatusOptions,
        stations: state.forms.orderForm.stations,
        businessLocations: state.forms.orderForm.businessLocations,
        user: state.auth,
        vehicles: state.forms.orderForm.vehicles,
        selectedClient: state.forms.orderForm.selectedClient,
        ...selectInviteData(state)
    };
};

const mapDispatchToProps = {
    fetchOrderForm,
    fetchOrderTasks,
    setOrderTasksFilters,
    getReport,
    fetchReport,
    updateOrder,
    setModal,
    resetModal,
    returnToOrdersPage,
    createInviteOrder,
    createOrderCopy,
    fetchAddClientForm,
    resetOrderTasksForm,
    saveOrderTask,
    changeModalStatus,
    setCollapsedState
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@withErrorMessage()
@injectIntl
class RetailCheckPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: void 0,
            showOilModal: false,
            networkModalOpen: false,
            scrollToMapId: undefined,
            focusedRef: undefined,
            allServices: [],
            allDetails: { brands: [], details: [] },
            selectedClient: {
                requisites: [],
                phones: [],
                emails: [],
                vehicles: [],
                aggregates: []
            },
            requisites: [],
            showRefBookModal: false,
            showPurchasePrices: true,
            allLinks: [],
            helperDrawerOpen: false,
            visibleReasonsModal: false,
            visibleReasonsNetworkModal: false,
            orderChecks: [],
            vehicles: [],
            orderComments: [],
            retailOrder: [],
            fetchedRetailCheck: [],
            checkId: undefined,
            isRetailOrder: true,
            fetched: false
        };
        this._isMounted = false;
    }

    componentDidMount = async () => {
        const {
            match: {
                params: { id }
            }
        } = this.props;
        await this.fetchRetailCheck(id);
        this._isMounted = true;
    };

    shouldComponentUpdate(nextProps, nextState) {
        const propsDiff = difference(this.props, nextProps);
        const stateDiff = difference(this.state, nextState);
        if (_.isEmpty(propsDiff) && _.isEmpty(stateDiff)) {
            return false;
        }

        return true;
    }

    componentDidUpdate = async (prevProps, prevState) => {
        const { selectedClient, retailOrder } = this.state;
        const { clientVehicleId } = retailOrder;

        if (prevState.fetchedRetailCheck !== this.state.fetchedRetailCheck) {
            this.retailCashSum();
        }

        if (retailOrder.clientId !== prevState.retailOrder?.clientId) {
            if (retailOrder && retailOrder.clientId) {
                const client = await fetchAPI(
                    'GET',
                    `clients/${retailOrder.clientId}`,
                    {
                        cut: true,
                        skipNotifications: true,
                        skipReviews: true
                    },
                    null,
                    {
                        handleErrorInternally: true
                    }
                );

                this.fetchVehicles(clientVehicleId, client);

                this.setState({
                    client,
                    selectedClient: { ...client }
                });

                this.fetchBusinesses();
            }
        }
        if (!this.state.loading && !this.state.fetched) {
            this.setState({
                fetched: true
            });
            const brands = await fetchAPI('GET', 'brands');
            const details = await fetchAPI('GET', 'store_groups', { keepFlat: true });
            const requisites = await fetchAPI('GET', 'businesses/requisites');

            await this.setState({
                allServices: [],
                allDetails: {
                    brands,
                    details
                },
                requisites
            });
        }
    };

    handleSearchClientVehicles = _.debounce(async query => {
        const vehicles = await fetchAPI(
            'GET',
            `/client/vehicles/${this.state.retailOrder.clientId}`,
            { page: 1, pageSize: 50, query },
            undefined,
            {
                handleErrorInternally: true
            }
        );

        this.setState({
            vehicles: vehicles.result
        });
    }, 500);

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    fetchVehicles = async (selectedVehicleId, client) => {
        const { retailOrder } = this.state;
        const vehicles = await fetchAPI(
            'GET',
            `/client/vehicles/${retailOrder.clientId}`,
            { page: 1, pageSize: 10 },
            undefined,
            {
                handleErrorInternally: true
            }
        );

        if (selectedVehicleId) {
            const isVehicleInclude = vehicles
                ? vehicles.result.findIndex(({ id }) => id === selectedVehicleId)
                : undefined;

            if (isVehicleInclude === -1) {
                const currentVehicle = await fetchAPI(
                    'GET',
                    `/client/vehicles/${retailOrder.clientId}`,
                    { page: 1, pageSize: 10, vehicleId: selectedVehicleId },
                    undefined,
                    {
                        handleErrorInternally: true
                    }
                );

                this.setState({
                    vehicles: isVehicleInclude > -1 ? vehicles.result : [...currentVehicle.result, ...vehicles.result]
                });
                if (client) {
                    this.setState({
                        selectedClient: {
                            ...client,
                            vehicles:
                                isVehicleInclude > -1 ? vehicles.result : [...currentVehicle.result, ...vehicles.result]
                        }
                    });
                }
            } else {
                this.setState({
                    vehicles: vehicles.result
                });
                if (client) {
                    this.setState({
                        selectedClient: {
                            ...client,
                            vehicles: vehicles.result
                        }
                    });
                }
            }
        } else {
            this.setState({
                vehicles: vehicles.result
            });
            if (client) {
                this.setState({
                    selectedClient: {
                        ...client,
                        vehicles: vehicles.result
                    }
                });
            }
        }
    };

    saveOrderFormRef = formRef => {
        this.orderFormRef = formRef;
    };

    saveOrderTaskFormRef = formRef => {
        this.orderTaskFormRef = formRef;
    };

    setAddClientModal = () => {
        this.props.fetchAddClientForm();
        this.props.setModal(MODALS.ADD_CLIENT);
    };

    _focusOnRef = focusedRef => {
        this.setState({ focusedRef });
    };

    _scrollToMap = id => {
        this.setState({
            scrollToMapId: id
        });
    };

    fetchComments = async () => {
        const response = await fetchAPI('GET', '/orders/comments', undefined, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            orderComments: response
        });
    };

    _showOilModal = (oem, oeCode, acea, api, sae) => {
        this.setState({
            showOilModal: true,
            oilModalData: {
                oem,
                oeCode,
                acea,
                api,
                sae
            }
        });
    };

    _clearOilData = () => {
        this.setState({
            showOilModal: false
        });
    };

    _onStatusChange = async (status, redirectStatus, options, redirectTo, subStatus) => {
        const { statusRtl, retailOrder, isRetailOrder, fetchedRetailCheck } = this.state;
        const isReservedDetail =
            fetchedRetailCheck &&
            get(fetchedRetailCheck.orderDetails, 'length') &&
            fetchedRetailCheck.orderDetails.find(({ reservedCount }) => reservedCount > 0);

        if (status === 'success' && statusRtl !== 'success') {
            const { id } = this.props.match.params;
            const { status: st } = await fetchAPI('GET', 'orders/status', { orderId: id }, null);
            if (st === 'success') {
                notification.error({
                    message: this.props.intl.formatMessage({ id: 'order_form_table.closed_order_notific' })
                });

                return;
            }
        }
        if (isReservedDetail && status === 'cancel') {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'rtl.reserve_error' })
            });

            return;
        }
        const { history } = this.props;
        const { allDetails } = this.state;
        const selectedClient = retailOrder.clientId ? this.state.selectedClient : this.props.selectedClient;
        const form = this.orderFormRef;

        const { id } = this.props.match.params;
        const redirectToDashboard = _.get(history, 'location.state.fromDashboard');

        await form.validateFields().then(values => {
            this.updateCheckOrder({
                order: convertFieldsValuesToDbEntity(
                    { ...values, selectedClient, subStatus },
                    [],
                    allDetails,
                    status,
                    this.props.user,
                    isRetailOrder
                ),
                redirectStatus,
                redirectToDashboard,
                options,
                redirectTo
            });
        });

        await this.fetchRetailCheck(id);
    };

    updateCheckOrder = async payload => {
        const {
            match: {
                params: { id }
            },
            type
        } = this.props;

        // this.setState({
        //     loading: true
        // });
        await fetchAPI(
            'PUT',
            type === RTL ? `/retail/${id}` : `/return/${id}`,
            undefined,
            { ...payload.order },
            {
                handleErrorInternally: true
            }
        );
        notification.success({
            message: this.props.intl.formatMessage({
                id: 'message.success'
            })
        });

        await this.fetchRetailCheck(id);
    };

    onSubStatusChange = async ({ status, subStatus }) => {
        const { statusRtl, retailOrder } = this.state;
        if (status === 'success' && statusRtl !== 'success') {
            if (statusRtl === 'success') {
                notification.error({ message: <FormattedMessage id='order_form_table.closed_order_notific' /> });

                return;
            }
        }
        const { allDetails } = this.state;
        const selectedClient = retailOrder.clientId ? this.state.selectedClient : this.props.selectedClient;
        const form = this.orderFormRef;

        const { id } = this.props.match.params;

        await form.validateFields().then(values => {
            try {
                this.updateCheckOrder({
                    order: convertFieldsValuesToDbEntity(
                        { ...values, selectedClient, subStatus },
                        [],
                        allDetails,
                        status,
                        this.props.user
                    )
                });
            } catch (e) {
                notification.error({ message: <FormattedMessage id='order_form_table.closed_order_notific' /> });
            }
        });
    };

    _saveNewOrderTask = () => {
        const {
            saveOrderTask,
            resetModal,
            resetOrderTasksForm,
            // orderTaskEntity,
            orderTaskId,
            orderTasks,
            initialOrderTask,
            match: { params }
        } = this.props;

        const { form } = this.orderTaskFormRef.props;

        form.validateFields((errors, values) => {
            if (!errors) {
                if (orderTasks.orderTasks.length >= 1) {
                    if (compareOrderTasks(initialOrderTask, values)) {
                        saveOrderTask(values, params.id, orderTaskId);
                    }
                    resetModal();
                    resetOrderTasksForm();
                } else {
                    saveOrderTask(values, params.id, orderTaskId);
                    resetModal();
                    resetOrderTasksForm();
                }
            }
        });
    };

    /* eslint-disable complexity */
    _close = (onPassCheck, onRefuse, onConfirm) => {
        const { setModal } = this.props;

        const form = this.orderFormRef;

        const ordersAreSame = !form.isFieldsTouched();

        const { canEdit, hideEditButton } = this.getSecurityConfig();

        if (!canEdit || hideEditButton || ordersAreSame) {
            if (onPassCheck) {
                onPassCheck();
            } else {
                this._redirect();
            }
        } else {
            setModal(MODALS.CONFIRM_EXIT, {
                onRefuse,
                onConfirm
            });
        }
    };

    _redirect = () => {
        const {
            resetModal,
            returnToOrdersPage,
            history,
            order: { status },
            type
        } = this.props;

        const { statusRtl } = this.state;

        resetModal();
        _.get(history, 'location.state.fromDashboard')
            ? history.push(`${book.dashboard}`)
            : type === RTN
            ? history.push(`${book.rtn}`)
            : history.push(`${book.rtl}`);
    };

    fetchBusinesses = async (pageSizeFetch, debouncedSearch) => {
        const result = await fetchAPI(
            'GET',
            'managers/businesses/get',
            { pageSize: pageSizeFetch || 25, search: debouncedSearch || '' },
            undefined,
            {
                handleErrorInternally: true
            }
        );
        this.setState({
            bisList: result
        });
    };

    _getOrderRemainSum = async callback => {
        const { fetchedRetailCheck } = this.state;
        const orderId = this.props.match.params.id;
        const { type } = this.props;
        const { order } = await fetchAPI(
            'GET',
            type === RTL ? `/retail/${orderId}` : `/return/${orderId}`,
            {
                onlyDetails: false
            },
            undefined,
            { handleErrorInternally: true }
        );

        let remainSum = order.totalSumWithTax;

        if (fetchedRetailCheck) {
            fetchedRetailCheck.cashOrders.map(elem => {
                remainSum += elem.decrease || 0;
                remainSum -= elem.increase || 0;
            });
        }
        if (callback) {
            return callback(remainSum, order.paymentMethod === 'cash' ? 1 : 2);
        }
    };

    _checkIsAllReserved = async callback => {
        const { retailOrder, fetchedOrder } = this.state;
        const { statusRtl } = this.state;

        const orderId = this.props.match.params.id;
        const { type } = this.props;

        // this.setState({
        //     loading: true
        // });

        if (!retailOrder.clientId) {
            notification.error({
                message: this.props.intl.formatMessage({
                    id: 'order-page.status_confirmed_no_client_error'
                })
            });
            // confirm({
            //     title: this.props.intl.formatMessage({
            //         id: 'order-page.status_confirmed_no_client_error'
            //     }),
            //     onOk() {
            //         callback();
            //     },
            //     okButtonProps: { disabled: !order.clientId }
            // });
        }
        let blocked = false;
        await fetchAPI('PUT', type === RTL ? `/retail/${orderId}` : `/return/${orderId}`, null, {
            orderCheckUpdateFIFO: true,
            status: statusRtl
        });

        const orderChecks = type !== RTN ? await fetchAPI('GET', `retails/${orderId}/retail_checks`) : [];

        if (type !== RTN) {
            (orderChecks || []).forEach(({ childs }) => {
                (childs || []).forEach(({ isBlocked }) => {
                    blocked = blocked || isBlocked;
                });
            });
        }

        const ord = orderChecks.filter(({ childs }) => (childs || []).length);

        if (!ord.length) {
            callback();

            return;
        }

        this.setState({
            orderChecks: ord,
            orderChecksBlocked: blocked,
            orderChecksCallback: callback
        });
    };

    _getCurrentOrder = async (isReservedCheck = false, callback) => {
        const order = this.state.fetchedRetailCheck;
        let isReserved = false;
        if (!isReservedCheck) {
            this._createCopy([], order.orderDetails);
        } else {
            order.orderDetails.map(elem => {
                if (Number(elem.reservedCount) > 0) {
                    isReserved = true;
                }
            });
            if (isReserved) {
                notification.error({
                    message: this.props.intl.formatMessage({
                        id: 'order-page.reserved_error'
                    })
                });
            } else {
                return callback();
            }
        }
    };

    _createCopy = (services, details) => {
        const { allDetails, selectedClient } = this.state;
        const form = this.orderFormRef;

        const orderId = this.props.match.params.id;

        const genData = orderFormValues => {
            const entryStationLoad = _.get(orderFormValues, 'stationLoads[0]');
            const normalizedBeginDateTime = roundCurrentTime(this.props.order.beginDatetime);
            entryStationLoad.beginDate = normalizedBeginDateTime;
            entryStationLoad.beginTime = normalizedBeginDateTime;

            const normalizedValues = _.set(orderFormValues, 'stationLoads', [entryStationLoad]);
            const orderFormEntity = { ...normalizedValues, selectedClient };
            const copyData = {
                ...convertFieldsValuesToDbEntity(orderFormEntity, [], allDetails, 'not_complete', this.props.user, {
                    ignoreFieldsOnAdd: true
                })
            };

            copyData.deliveryDatetime = copyData.beginDatetime;
            copyData.businessLocationId = undefined;
            copyData.details = details.map(detail => {
                return detail.productId
                    ? {
                          id: detail.id,
                          storeGroupId: detail.storeGroupId,
                          name: detail.detailName,
                          productId: detail.storeId || detail.productId,
                          productCode: detail.detailCode,
                          purchasePrice: detail.purchasePrice || 0,
                          count: detail.count ? detail.count : 1,
                          price: detail.price ? detail.price : 1,
                          supplierBrandId: detail.supplierBrandId || detail.brandId,
                          supplierId: detail.supplierId,
                          supplierOriginalCode: detail.supplierOriginalCode,
                          supplierProductNumber: detail.supplierProductNumber,
                          supplierPartNumber: detail.supplierPartNumber,
                          partRowDiscount: detail.partRowDiscount || 0,
                          oeCode: detail.oeCode || null,
                          comment: detail.comment || {
                              comment: undefined,
                              positions: []
                          }
                      }
                    : {
                          id: detail.id,
                          storeGroupId: detail.storeGroupId,
                          name: detail.detailName,
                          productCode: detail.detailCode ? detail.detailCode : null,
                          supplierId: detail.supplierId,
                          supplierBrandId: detail.supplierBrandId || detail.brandId,
                          supplierOriginalCode: detail.supplierOriginalCode,
                          supplierProductNumber: detail.supplierProductNumber,
                          supplierPartNumber: detail.supplierPartNumber,
                          purchasePrice: detail.purchasePrice || 0,
                          count: detail.count,
                          price: detail.price ? detail.price : 1,
                          partRowDiscount: detail.partRowDiscount || 0,
                          oeCode: detail.oeCode || null,
                          comment: detail.comment || {
                              comment: undefined,
                              positions: []
                          }
                      };
            });

            return copyData;
        };
        form.validateFields().then(async values => {
            const res = await fetchAPI(
                'PUT',
                `/copy_retail/${orderId}`,
                undefined,
                { ...genData(values) },
                {
                    handleErrorInternally: true
                }
            );

            if (res && res.id) {
                window.open(`${book.rtl}/${res.id}`, '_blank');
            }
            // this.props.createOrderCopy(genData(values));
        });
    };

    _invite = () => {
        const { clientVehicleId, clientId, clientPhone } = this.props.order;
        const { user, createInviteOrder } = this.props;
        const { statusRtl } = this.state;

        if ((statusRtl === 'success' || statusRtl === 'cancel') && clientVehicleId && clientId && clientPhone) {
            createInviteOrder({
                status: 'invite',
                clientVehicleId,
                clientId,
                clientPhone,
                managerId: user.id
            });
        }
    };

    getSecurityConfig() {
        const { user } = this.props;

        const { statusRtl } = this.state;

        const isClosedStatus = ['success', 'cancel', 'redundant'].includes(statusRtl);
        const canEditClosedStatus = isGrantAccessed(
            user,
            grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_UNPOSTING
        );
        const canEdit = isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER, accesses.ROWO);

        const hideEditButton = isClosedStatus && !canEditClosedStatus;
        const disabledEditButton = hideEditButton || !canEdit;

        const forbiddenUpdate = !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER, accesses.ROWO);

        return {
            isClosedStatus,
            canEditClosedStatus,
            canEdit,
            hideEditButton,
            disabledEditButton,
            forbiddenUpdate
        };
    }

    fetchRetailCheck = async id => {
        const { type } = this.props;
        this.setState({
            loading: true
        });
        const res = await fetchAPI('GET', type === RTL ? `/retail/${id}` : `/return/${id}`, undefined, undefined, {
            handleErrorInternally: true
        });

        this.setState({
            fetchedRetailCheck: res,
            retailOrder: get(res, 'order', []),
            checkId: get(res, 'order.id', ''),
            statusRtl: get(res, 'order.status', ''),
            managers: get(res, 'managers'),
            employees: get(res, 'employees'),
            loading: false
        });
    };

    reloadOrderPageComponents = () => {
        this.componentDidMount();
    };

    retailCashSum = () => {
        const { fetchedRetailCheck } = this.state;
        if (fetchedRetailCheck.cashOrders && fetchedRetailCheck.cashOrders.length) {
            const increase = fetchedRetailCheck.cashOrders.reduce(
                (accumulator, { increase }) => accumulator + increase,
                0
            );

            const decrease = fetchedRetailCheck.cashOrders.reduce(
                (accumulator, { decrease }) => accumulator + decrease,
                0
            );

            this.setState({
                retailCashSum: Math.round(increase * 100) / 100 - Math.round(decrease * 100) / 100
            });

            return Math.round(increase * 100) / 100 - Math.round(decrease * 100) / 100;
        }
    };

    checkIfInvoiceCreated = async () => {
        const { order } = await fetchAPI('GET', `/retail/${this.props.match.params.id}`);

        const isInvoice = order?.invoiceCreated;

        return isInvoice;
    };

    /* eslint-disable complexity */
    render() {
        const {
            showOilModal,
            oilModalData,
            focusedRef,
            client,
            allDetails,
            selectedClient,
            clientsAggregates,
            requisites,
            showRefBookModal,
            networkModalOpen,
            bisList,
            visibleReasonsModal,
            visibleReasonsNetworkModal,
            loading,
            managers,
            employees
        } = this.state;
        const {
            fetchOrderForm,
            fetchOrderTask,
            setModal,
            resetModal,

            hasInviteStatus,
            isInviteVisible,
            isInviteEnabled,
            inviteOrderId,
            modal,
            isMobile,
            stations,
            businessLocations,
            user,
            initialOrderTask,
            fetchedOrder,
            order,
            setCollapsedState,
            type
        } = this.props;
        const {
            allLinks,
            helperDrawerOpen,
            orderChecksCallback,
            orderChecks,
            orderChecksBlocked,
            vehicles,
            orderComments,
            fetchedRetailCheck,
            statusRtl,
            retailOrder,
            isRetailOrder,
            checkId,
            retailCashSum
        } = this.state;
        const {
            num,
            status,
            subStatus,
            subStatusName,
            datetime,
            diagnosis,
            clientVehicleId,
            totalSumWithTax,
            successDatetime,
            printNumber,
            invoiceCreated
        } = retailOrder;
        const { clientId } = selectedClient;
        const { id } = this.props.match.params;

        const isRTN = type === RTN;

        const selectedVehicle = vehicles && (vehicles || []).find(({ id }) => id == clientVehicleId);

        const { isClosedStatus, hideEditButton, disabledEditButton, forbiddenUpdate } = this.getSecurityConfig();
        const copyDisabled = !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT, accesses.ROWO);

        let remainSum = totalSumWithTax;

        if (fetchedOrder) {
            fetchedOrder.client = client;
            fetchedOrder.cashOrders.map(elem => {
                remainSum += elem.decrease || 0;
                remainSum -= elem.increase || 0;
            });
        }

        const showCahOrderModal = () => {
            this._getOrderRemainSum((remainSum, precision) => {
                setModal(MODALS.REFACTORED_CASH_ORDER, {
                    initValues: {
                        orderId: id,
                        clientId,
                        sum: remainSum,
                        precision
                    },
                    onCashOrderCreatedCallback: () => this.fetchRetailCheck(id)
                });
            });
        };

        return loading || !this._isMounted ? (
            <Spinner spin={loading} />
        ) : (
            <Layout
                className='orderPageLayout'
                controls={
                    <Space size='small'>
                        {isRTN && (
                            <Link style={{ fontSize: 18 }} to={`${book.order}/${_.get(retailOrder, 'parentOrderId')}`}>
                                {_.get(retailOrder, 'parentOrderId')}
                            </Link>
                        )}
                        <ChangeRtlStatusDropdown
                            checkIfInvoiceCreated={this.checkIfInvoiceCreated}
                            checkReserved={this._checkIsAllReserved}
                            invoiceCreated={invoiceCreated}
                            isMobile={isMobile}
                            modals={MODALS}
                            onStatusChange={this._onStatusChange}
                            orderStatus={statusRtl}
                            setModal={setModal}
                            showToNotComplete={
                                statusRtl === 'success' &&
                                !isRTN &&
                                isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_UNPOSTING)
                            }
                            successDatetime={successDatetime}
                            user={user}
                        />

                        {/* {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CAR_INFO) && !isMobile && !isRetailOrder && (
                            <Tooltip title={<FormattedMessage id='order-page.tech_info' />}>
                                <span>
                                    <TecDocInfoModal
                                        isMobile={isMobile}
                                        modificationId={selectedVehicle && selectedVehicle.modificationId}
                                        orderId={id}
                                        showOilModal={this._showOilModal}
                                    />
                                </span>
                            </Tooltip>
                        )} */}
                        {/* {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_COORDINATION) &&
                            !isMobile &&
                            !isClosedStatus && (
                                <Tooltip title={<FormattedMessage id='order-page.send_agreement' />}>
                                    <Popconfirm
                                        onConfirm={async () => {
                                            const data = {
                                                services: [],
                                                details: []
                                            };
                                            fetchedRetailCheck.orderServices.map(element => {
                                                data.services.push({
                                                    serviceId: element.laborId,
                                                    serviceHours: element.hours,
                                                    servicePrice: element.price,
                                                    comment: element.comment
                                                });
                                            });
                                            fetchedRetailCheck.orderDetails.map(element => {
                                                data.details.push({
                                                    storeGroupId: element.storeGroupId,
                                                    count: element.count
                                                });
                                            });
                                            const confirmFunc = () => {
                                                notification.success({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'message_sent'
                                                    })
                                                });
                                            };
                                            const errorFunc = () => {
                                                notification.error({
                                                    message: this.props.intl.formatMessage({
                                                        id: 'order-page.no_positions'
                                                    })
                                                });
                                            };
                                            await createAgreement(
                                                this.props.order.id,
                                                this.props.user.language,
                                                confirmFunc,
                                                errorFunc
                                            );
                                        }}
                                        title={`${this.props.intl.formatMessage({
                                            id: 'send_message'
                                        })}?`}
                                    >
                                        <Button
                                            icon={<FileProtectOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                            size='large'
                                            type='text'
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            )} */}
                        {hasInviteStatus && inviteOrderId && (
                            <Link
                                className={Styles.inviteButton}
                                // onClick={ () => {
                                //     fetchOrderForm(inviteOrderId);
                                //     // if (viewTasks) {
                                //     //     fetchOrderTask(inviteOrderId);
                                //     // }
                                // } }
                                to={`${book.order}/${inviteOrderId}`}
                            >
                                {inviteOrderId}
                            </Link>
                        )}
                        {isInviteVisible &&
                        !inviteOrderId &&
                        isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER) ? (
                            <Tooltip title={<FormattedMessage id='order-page.create_invite_order' />}>
                                {/* <Button
                                    icon={<SendOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                    onClick={this._invite}
                                    size='large'
                                    type='text'
                                /> */}
                            </Tooltip>
                        ) : null}
                        {!isMobile && isGrantAccessed(user, grants.SETTINGS_NUMBERING, accesses.ROWO) && (
                            <Tooltip title={<FormattedMessage id='directory_page.document_number' />}>
                                <Button
                                    icon={<LinkOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                    onClick={() => setModal(MODALS.REFERENCE_BOOK_ADD_MODAL)}
                                    size='large'
                                    type='text'
                                />
                            </Tooltip>
                        )}
                        {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_DOCUMENT_PRINTING) && (
                            <ReportsDropdown
                                download={report => this.props.getReport({ report, footerData: { printNumber, num } })}
                                isMobile={isMobile}
                                isRetailOrder={isRetailOrder}
                                isRTN={isRTN}
                                orderId={id}
                                orderStatus={statusRtl}
                                reportBlock={
                                    orderTypeMapper[_.get(fetchedOrder, 'order.orderBloke') || 'VEHICLE'].reports
                                }
                                user={this.props.user}
                            />
                        )}
                        {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_PAYMENT) && (
                            <Tooltip title={<FormattedMessage id='order-page.pay' />}>
                                <Button
                                    icon={<DollarOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                    onClick={() => {
                                        if (clientId) {
                                            showCahOrderModal();
                                        }
                                    }}
                                    size='large'
                                    type='text'
                                />
                            </Tooltip>
                        )}
                        {!copyDisabled && !isRTN && (
                            <Tooltip title={<FormattedMessage id='order-page.create_copy' />}>
                                <Button
                                    icon={<CopyOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                    onClick={() => {
                                        const getCurrentOrder = () => this._getCurrentOrder();
                                        confirm({
                                            title: `${this.props.intl.formatMessage({
                                                id: 'order-page.create_copy'
                                            })}?`,
                                            onOk() {
                                                getCurrentOrder();
                                            }
                                        });
                                    }}
                                    size='large'
                                    type='text'
                                />
                            </Tooltip>
                        )}
                        <Tooltip title={<FormattedMessage id='order-page.save' />}>
                            <Button
                                disabled={disabledEditButton}
                                icon={<SaveOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                onClick={() => !disabledEditButton && this.onSubStatusChange({ status, subStatus })}
                                size='large'
                                type='text'
                            />
                        </Tooltip>
                        {_.get(bisList, 'length') > 1 && (
                            <Tooltip title={<FormattedMessage id='order-page.transfer_order' />}>
                                <Button
                                    disabled={isClosedStatus}
                                    icon={<DragOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                    onClick={() => {
                                        this.setState({
                                            networkModalOpen: true
                                        });
                                    }}
                                    size='large'
                                    type='text'
                                />
                            </Tooltip>
                        )}

                        {!isClosedStatus && !forbiddenUpdate && (
                            <Tooltip title={<FormattedMessage id='order-page.delete' />}>
                                <Button
                                    icon={<DeleteOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                    onClick={() => {
                                        this.setState({
                                            visibleReasonsModal: true
                                        });
                                    }}
                                    size='large'
                                    type='text'
                                />
                            </Tooltip>
                        )}
                        <Tooltip title={<FormattedMessage id='order-page.close' />}>
                            <Button
                                icon={<CloseOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                onClick={() => this._close()}
                                size='large'
                                type='text'
                            />
                        </Tooltip>
                        {/* <Tooltip title={<FormattedMessage id='navigation.helper' />}>
                            <Button
                                icon={<QuestionCircleOutlined style={{ fontSize: isMobile ? 14 : 24 }} />}
                                onClick={async () => {
                                    const links = await fetchAPI(
                                        'GET',
                                        '/helps',
                                        { helpId: 'order_mrd_header' },
                                        undefined,
                                        {
                                            handleErrorInternally: true
                                        }
                                    );
                                    this.setState({
                                        allLinks: links,
                                        helperDrawerOpen: true
                                    });
                                }}
                                size='large'
                                type='text'
                            />
                        </Tooltip> */}
                    </Space>
                }
                description={
                    <React.Fragment>
                        <FormattedMessage id='order-page.creation_date' />
                        {`: ${dayjs(datetime).format('DD MMMM YYYY, HH:mm')}`}
                    </React.Fragment>
                }
                menuControls
                title={
                    !statusRtl || !get(retailOrder, 'num') ? (
                        ''
                    ) : (
                        <div style={{ position: 'relative' }}>
                            {user.orderStatusNewVersion ? (
                                subStatusName || <FormattedMessage id={`status_directory.${subStatus}`} />
                            ) : (
                                <FormattedMessage id={`order-status.${statusRtl || 'order'}`} />
                            )}
                            {` ${get(retailOrder, 'num')}`}
                        </div>
                    )
                }
            >
                <MobileView>
                    <CheckIsOrderSavedHelper
                        close={() => this._close()}
                        onStatusChange={this._onStatusChange}
                        resetModal={resetModal}
                        setCollapsedState={setCollapsedState}
                        status={statusRtl}
                    />
                    <MobileRecordForm
                        allDetails={allDetails}
                        changeModalStatus={this.props.changeModalStatus}
                        checkId={checkId}
                        client={client}
                        employees={this.props.employees}
                        errors={this.state.errors}
                        fetchedRetailCheck={fetchedRetailCheck}
                        fetchOrderForm={fetchOrderForm}
                        handleSearchClientVehicles={this.handleSearchClientVehicles}
                        isClosedStatus={isClosedStatus}
                        isMobile={isMobile}
                        isRetailOrder={isRetailOrder}
                        location={false}
                        modal={modal}
                        modificationId={selectedVehicle && selectedVehicle.tecdocId}
                        onClose={() => this._close()}
                        onStatusChange={this._onStatusChange}
                        order={order}
                        orderDiagnostic={diagnosis}
                        orderFormRef={this.saveOrderFormRef}
                        orderId={id}
                        orderNum={num}
                        orderStatus={statusRtl}
                        orderTasks={this.props.orderTasks}
                        reloadOrderPageComponents={this.reloadOrderPageComponents}
                        requisites={requisites}
                        selectedClient={selectedClient}
                        setAddClientModal={this.setAddClientModal}
                        setModal={setModal}
                        user={this.props.user}
                        vehicles={vehicles}
                    />
                </MobileView>
                <ResponsiveView view={{ min: BREAKPOINTS.sm.max, max: BREAKPOINTS.xxl.max }}>
                    <OrderForm
                        allDetails={allDetails}
                        businessLocations={businessLocations}
                        changeModalStatus={this.props.changeModalStatus}
                        checkId={checkId}
                        clearOilData={this._clearOilData}
                        client={client}
                        clientsAggregates={clientsAggregates}
                        employees={this.props.employees}
                        errors={this.state.errors}
                        fetchedRetailCheck={fetchedRetailCheck}
                        fetchOrderForm={fetchOrderForm}
                        fetchRetailCheck={this.fetchRetailCheck}
                        fetchVehicles={this.fetchVehicles}
                        filteredDetails={this.props.filteredDetails}
                        focusedRef={focusedRef}
                        focusOnRef={this._focusOnRef}
                        handleSearchClientVehicles={this.handleSearchClientVehicles}
                        inviteOrderId={_.get(retailOrder, 'parentOrderId')}
                        isClosedStatus={isClosedStatus}
                        isRetailOrder={isRetailOrder}
                        isRTN={isRTN}
                        location={false}
                        modal={modal}
                        onChangeSelectedClient={value =>
                            this.setState({
                                selectedClient: value
                            })
                        }
                        orderDiagnostic={diagnosis}
                        orderId={id}
                        orderStatus={statusRtl}
                        orderTasks={this.props.orderTasks}
                        reloadOrderPageComponents={this.reloadOrderPageComponents}
                        requisites={requisites}
                        retailCashSum={retailCashSum}
                        retailEmployees={employees}
                        retailManagers={managers}
                        scrollToMap={this._scrollToMap}
                        scrollToMapId={this.state.scrollToMapId}
                        selectedClient={selectedClient}
                        selectedVehicle={selectedVehicle}
                        setAddClientModal={this.setAddClientModal}
                        setModal={setModal}
                        showCahOrderModal={showCahOrderModal}
                        showOilModal={showOilModal}
                        showRefBookModal={showRefBookModal}
                        user={this.props.user}
                        vehicles={vehicles}
                        wrappedComponentRef={this.saveOrderFormRef}
                        modals={MODALS}
                        // location={ this.props.history.location }
                        oilModalData={oilModalData}
                        onStatusChange={this._onStatusChange}
                        // fetchOrderTask={ fetchOrderTask }
                        orderCalls={this.props.orderCalls}
                    />
                </ResponsiveView>
                <CancelReasonModal
                    fetchComments={this.fetchComments}
                    handleCancel={() =>
                        this.setState({
                            visibleReasonsModal: false
                        })
                    }
                    handleCancelReasonModalSubmit={this._onStatusChange}
                    orderComments={orderComments}
                    orderId={id}
                    visible={visibleReasonsModal}
                    wrappedComponentRef={this.saveFormRef}
                />
                <ConfirmOrderExitModal
                    closeModal={() => this._close()}
                    redirect={() => this._redirect()}
                    resetModal={() => resetModal()}
                    returnToOrdersPage={() => this.props.returnToOrdersPage(statusRtl)}
                    saveOrder={() => {
                        if (_.get(history, 'location.state.fromDashboard')) {
                            return this._onStatusChange(statusRtl, 'dashboard');
                        }

                        return this._onStatusChange(statusRtl, status);
                    }}
                    status={statusRtl}
                    visible={modal}
                    wrappedComponentRef={this.saveFormRef}
                />

                <OrderTaskModal
                    initialOrderTask={initialOrderTask}
                    managers={managers}
                    num={num}
                    orderId={id}
                    orderTaskEntity={this.props.orderTaskEntity}
                    orderTaskId={this.props.orderTaskId}
                    orderTasks={this.props.orderTasks}
                    priorityOptions={this.props.priorityOptions}
                    progressStatusOptions={this.props.progressStatusOptions}
                    resetModal={() => resetModal()}
                    resetOrderTasksForm={this.props.resetOrderTasksForm}
                    saveNewOrderTask={this._saveNewOrderTask}
                    stations={stations}
                    visible={modal}
                    wrappedComponentRef={this.saveOrderTaskFormRef}
                />

                <NetworkModal
                    fetchComments={this.fetchComments}
                    handleCancel={() => {
                        this.setState({
                            visibleReasonsNetworkModal: false
                        });
                    }}
                    onClose={() =>
                        this.setState({
                            networkModalOpen: false
                        })
                    }
                    onOpenReasonsModal={() => {
                        this.setState({
                            visibleReasonsNetworkModal: true
                        });
                    }}
                    onStatusChange={this._onStatusChange}
                    open={networkModalOpen}
                    orderComments={this.props.orderComments}
                    orderId={id}
                    resetModal={() => resetModal()}
                    visibleReasonsModal={visibleReasonsNetworkModal}
                    wrappedComponentRef={this.saveFormRef}
                />
                <StoreProductModal />

                <RefactoredCashOrderModal />

                <DashboardModal
                    onSelect={(stationNum, time) => {
                        const form = this.orderFormRef;
                        const { stationLoads } = form.getFieldsValue();
                        form.setFieldsValue({
                            stationLoads: [
                                {
                                    ...stationLoads[0],
                                    station: stationNum,
                                    beginDate: time,
                                    beginTime: time
                                },
                                stationLoads[1]
                            ]
                            // 'stationLoads[0].station': stationNum,
                            // 'stationLoads[0].beginDate': time,
                            // 'stationLoads[0].beginTime': time
                        });
                        resetModal();
                    }}
                    orderDatetime={
                        _.get(fetchedOrder, 'stationLoads[0].beginDatetime')
                            ? dayjs(fetchedOrder.stationLoads[0].beginDatetime)
                            : dayjs()
                    }
                    resetModal={() => resetModal()}
                    selectedOrderId={id}
                    visible={modal}
                />
                <ReferenceBookAddModal orderId={id} resetModal={() => resetModal()} user={user} visible={modal} />
                <EmployeeTaskModal />
                <OrderCheckModal
                    blocked={orderChecksBlocked}
                    isMobile={isMobile}
                    onClose={() =>
                        this.setState({ orderChecks: [], orderChecksCallback: undefined, orderChecksBlocked: false })
                    }
                    onOk={orderChecksCallback}
                    open={orderChecks.length}
                    orderChecks={orderChecks}
                />
            </Layout>
        );
    }
}

export default RetailCheckPage;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space } from 'antd';
import PhoneInput from 'components/PhoneInput';
import { DecoratedInput, DecoratedInputNumber } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, phoneNumberFormatter, phoneNumberParser } from 'utils';
import Styles from './styles.m.css';

const { warning } = Modal;

const mapStateToProps = state => ({
    user: state.auth
});

@injectIntl
@connect(mapStateToProps)
class ArrayInput extends Component {
    constructor(props) {
        super(props);

        this.handleChange = _.debounce((fieldName, key, value) => {
            this.props.form.setFieldsValue({
                [`${fieldName}[${key}][number]`]: Number(value)
            });
        }, 1000);

        // Create unique ID for each field which will be created for each array entry.
        this.uuid = props.initialValue ? props.initialValue.length : 0;

        // Get keys for each field by extracing keys from array or by creating new
        const keys = props.initialValue ? _.keys(props.initialValue) : props.optional ? ['0'] : [this.uuid++];

        this.state = { keys };
    }

    /**
     * Get default value for a specific field
     * @param {*} key Unique field key
     * @returns Default field value or formatted default phone number
     */
    _getDefaultValue = key => {
        const value = (this.props.initialValue || [])[key];
        if (!value) {
            return void 0;
        }

        return this.props.phone ? Number(phoneNumberParser(value)) : value;
    };

    /**
     * Remove key from global array of keys, so its field will be removed too
     * @param {*} key Key to remove
     */
    remove = key => {
        const { optional } = this.props;

        const { keys } = this.state;
        if (keys.length === 1 && !optional) {
            return;
        }

        this.setState({ keys: keys.filter(value => value !== key) });
    };

    /**
     * Add new unique key to array of kes, so new field will be created.
     */
    add = () => {
        const { keys } = this.state;
        this.setState({ keys: [...keys, this.uuid++] });
    };

    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        const {
            fieldName,
            fieldTitle,
            rules,
            optional,
            user,
            intl: { formatMessage }
        } = this.props;

        const { keys } = this.state;

        const formatter = value => phoneNumberFormatter(value, user.country);

        const parser = value => `${value}`.replace(/\D/g, '');

        const options = this.props.phone ? { formatter, parser, step: 1, style: { width: '100%' } } : {};

        if (keys.length === 0) {
            this.add();
        }

        const formItems = keys.map(key => {
            if (this.props.phone) {
                getFieldDecorator(`${fieldName}[${key}][country]`);
            }

            return (
                <React.Fragment key={key}>
                    <React.Fragment>
                        {this.props.phone ? (
                            // This is used for phone numbers
                            <React.Fragment>
                                <div
                                    style={{
                                        marginBottom: 8
                                    }}
                                >
                                    {_.isFunction(fieldTitle) ? fieldTitle(this._getDefaultValue(key)) : fieldTitle}
                                </div>
                                <div style={{ display: 'none' }}>
                                    <DecoratedInputNumber
                                        phoneNumber
                                        {...options}
                                        key={`${fieldName}[${key}][number]`}
                                        className={Styles.arrayInput}
                                        field={`${fieldName}[${key}][number]`}
                                        fields={{}}
                                        formItem
                                        getFieldDecorator={getFieldDecorator}
                                        hasFeedback
                                        initialValue={this._getDefaultValue(key)}
                                        label={
                                            _.isFunction(fieldTitle)
                                                ? fieldTitle(this._getDefaultValue(key))
                                                : fieldTitle
                                        }
                                        precision={0}
                                        rules={rules}
                                    />
                                </div>
                                <Space>
                                    <PhoneInput
                                        label={
                                            _.isFunction(fieldTitle)
                                                ? fieldTitle(this._getDefaultValue(key))
                                                : fieldTitle
                                        }
                                        onChange={async value => {
                                            this.handleChange(fieldName, key, value);
                                            if (value && String(value).length >= 10) {
                                                const isPhoneExist = await fetchAPI(
                                                    'POST',
                                                    'clients/validate_phone',
                                                    undefined,
                                                    { phone: String(value) }
                                                );
                                                if (isPhoneExist.count > 0) {
                                                    warning({
                                                        title: formatMessage({
                                                            id: 'phone_already_exist'
                                                        })
                                                    });
                                                }
                                            }

                                            // this.props.form.setFieldsValue({
                                            //     [`${fieldName}[${key}][number]`]: Number(value)
                                            // });
                                        }}
                                        rules={rules}
                                        value={
                                            this._getDefaultValue(key) ||
                                            this.props.form.getFieldValue(`${fieldName}[${key}][number]`) ||
                                            null
                                        }
                                    />
                                    {keys.length > 1 || optional ? (
                                        <Button
                                            key={key}
                                            className='dynamic-delete-button'
                                            disabled={keys.length === 1}
                                            icon={<MinusCircleOutlined style={{ fontSize: 18, color: '#cc1300' }} />}
                                            onClick={() => this.remove(key)}
                                        />
                                    ) : null}
                                </Space>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <DecoratedInput
                                    {...options}
                                    key={`${fieldName}[${key}]`}
                                    className={Styles.arrayInput}
                                    field={`${fieldName}[${key}]`}
                                    fields={{}}
                                    formItem
                                    getFieldDecorator={getFieldDecorator}
                                    hasFeedback
                                    initialValue={this._getDefaultValue(key)}
                                    label={
                                        _.isFunction(fieldTitle) ? fieldTitle(this._getDefaultValue(key)) : fieldTitle
                                    }
                                    rules={rules}
                                />
                                {keys.length > 1 || optional ? (
                                    <Row justify='center' type='flex'>
                                        <MinusCircleOutlined
                                            key={key}
                                            className='dynamic-delete-button'
                                            disabled={keys.length === 1}
                                            onClick={() => this.remove(key)}
                                            style={{ fontSize: 20, color: '#cc1300' }}
                                        />
                                    </Row>
                                ) : null}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </React.Fragment>
            );
        });

        return (
            <Col>
                {formItems}
                <div>
                    <Button onClick={this.add} type='dashed'>
                        <PlusOutlined /> {this.props.buttonText}
                    </Button>
                </div>
            </Col>
        );
    }
}

export default ArrayInput;

import { Button, Tabs } from 'antd';
import { Catcher } from 'commons';
import {
    ClientAggregateTab,
    ClientCallsTab,
    ClientDiscountsTab,
    ClientFeedbackTab,
    ClientMRDsTab,
    ClientOrdersTab,
    ClientVehiclesTab
} from 'components';
import { ClientRequisitesContainer } from 'containers';
import { fetchCalls, selectCallsStats, setClientFilter } from 'core/calls/duck';
import { createClientVehicle, deleteClientVehicle, updateClientVehicle } from 'core/client/duck';
import { fetchClientMRDs, selectClientMRDsStats } from 'core/clientMRDs/duck';
import { fetchClientOrders, selectClientOrders, selectClientOrdersFilter } from 'core/clientOrders/duck';
import { EditClientForm, EditClientVehicleForm } from 'forms';
import InteractionsTable from 'pages/InteractionsPage/components/InteractionsTable';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile,
    ordersData: selectClientOrders(state),
    filter: state.calls.filter,
    filterOrders: selectClientOrdersFilter(state),
    mrdsStats: selectClientMRDsStats(state),
    callStats: selectCallsStats(state)
});

const mapDispatchToProps = {
    createClientVehicle,
    updateClientVehicle,
    deleteClientVehicle,

    fetchClientOrders,
    fetchClientMRDs,
    fetchCalls,
    setClientFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ClientContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            source: [],
            markups: [],
            loading: false,
            clientVehicles: [],
            count: 0,
            clientsAggregates: []
        };
    }

    componentDidMount() {
        const { clientId, filterOrders } = this.props;
        this.props.fetchClientOrders({ clientId, filterOrders });
        this.props.fetchClientMRDs({ clientId });
        this.setClientFilter();

        this.fetchVehicles();
        this.setClientFilter();
        this.getClientsAggregates();
    }

    setClientFilter = async () => {
        const { setClientFilter, clientEntity } = this.props;
        const { clientId } = clientEntity || {};

        clientId && setClientFilter({ clientId }); // We search all calls for specific client only on this page

        await this.props.fetchCalls();
    };

    // fetchSource = async () => {
    //     const source = await fetchAPI('GET', 'business/acquiring/sources', undefined, undefined);
    //     this.setState({
    //         source: source.query || []
    //     });
    // };

    setClientFilter = () => {
        const { setClientFilter, clientEntity, clientId } = this.props;

        clientId && setClientFilter({ clientId }); // We search all calls for specific client only on this page
    };

    // fetchMarkups = async () => {
    //     const response = await fetchAPI('GET', 'markups', undefined, undefined);
    //     this.setState({
    //         markups: response || []
    //     });
    // };

    fetchVehicles = async (page = 1, pageSize = 10) => {
        const { clientId } = this.props;
        await this.setState({
            loading: true
        });

        const response = await fetchAPI('GET', `/client/vehicles/${clientId}`, { page, pageSize }, null, {
            handleErrorInternally: true
        });

        this.setState({
            clientVehicles: response && response.result,
            count: response && Number(response.count),
            loading: false
        });
    };

    getClientsAggregates = async () => {
        const { clientId } = this.props;

        const clientsAggregates = await fetchAPI('GET', '/client/aggregates', { clientId }, null, {
            handleErrorInternally: true
        });

        this.setState({
            clientsAggregates
        });
    };

    render() {
        const {
            clientEntity,
            clientId,
            user,
            specificTab,
            isMobile,
            fetchClient,
            ordersData,
            mrdsStats,
            onAddVehicle,
            callStats
        } = this.props;

        const { source, markups, clientVehicles, count, clientsAggregates } = this.state;

        return (
            <Catcher>
                <Tabs defaultActiveKey={specificTab || 'generalInfo'} tabPosition='top'>
                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD) && (
                        <TabPane key='generalInfo' tab={<FormattedMessage id='client_container.general_info' />}>
                            <EditClientForm
                                client={clientEntity}
                                clientId={clientId}
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD,
                                        accesses.ROWO
                                    )
                                }
                                markups={markups}
                                source={source}
                            />
                            <EditClientVehicleForm
                                clientEntity={clientEntity}
                                clientId={clientId}
                                clientVehicles={clientVehicles}
                                deleteClientVehicle={this.props.deleteClientVehicle}
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD,
                                        accesses.ROWO
                                    )
                                }
                                fetchClient={fetchClient}
                                updateClientVehicle={this.props.updateClientVehicle}
                            />
                            {isMobile && (
                                <Button
                                    disabled={
                                        !isGrantAccessed(
                                            user,
                                            grants.DIRECTORIES_CUSTOMERS_LIST_GENERAL_INFORMATION_CARD,
                                            accesses.ROWO
                                        )
                                    }
                                    onClick={onAddVehicle}
                                    style={{
                                        width: '100%',
                                        margin: '-8px 0 12px'
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='add_client_form.add_vehicle' />
                                </Button>
                            )}
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_CARS) && (
                        <TabPane
                            key='vehicles'
                            tab={
                                <div>
                                    <FormattedMessage id='client_container.vehicles' />({clientVehicles && count})
                                </div>
                            }
                        >
                            <ClientVehiclesTab
                                clientEntity={clientEntity}
                                clientId={clientId}
                                clientVehicles={clientVehicles}
                                count={count}
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_CARS, accesses.ROWO)}
                                fetchVehicles={this.fetchVehicles}
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_AGGREGATES) && (
                        <TabPane
                            key='aggregate'
                            tab={
                                <div>
                                    <FormattedMessage id='cleint_container_aggregate' />(
                                    {clientsAggregates && clientsAggregates.length})
                                </div>
                            }
                        >
                            <ClientAggregateTab
                                clientEntity={clientEntity}
                                clientId={clientId}
                                clientsAggregates={clientsAggregates}
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_AGGREGATES, accesses.ROWO)
                                }
                            />
                        </TabPane>
                    )}

                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_ORDERS) && (
                        <TabPane
                            key='orders'
                            tab={
                                <div>
                                    <FormattedMessage id='SALES' />({ordersData && ordersData.count})
                                </div>
                            }
                        >
                            <ClientOrdersTab
                                clientId={clientId}
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_ORDERS, accesses.ROWO)
                                }
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_REVIEWS) && (
                        <TabPane
                            key='feedback'
                            tab={
                                <div>
                                    <FormattedMessage id='client_container.feedback' />(
                                    {clientEntity.reviews && clientEntity.reviews.length})
                                </div>
                            }
                        >
                            <ClientFeedbackTab
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_REVIEWS, accesses.ROWO)
                                }
                                feedback={clientEntity.reviews}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_REQUISITES) && (
                        <TabPane
                            key='clientRequisites'
                            tab={
                                <div>
                                    <FormattedMessage id='client_container.requisites' />(
                                    {clientEntity.requisites && clientEntity.requisites.length})
                                </div>
                            }
                        >
                            <ClientRequisitesContainer
                                clientId={clientEntity.clientId}
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_REQUISITES, accesses.ROWO)
                                }
                                requisites={clientEntity.requisites}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS) && (
                        <TabPane
                            key='clientDebt'
                            tab={
                                <div>
                                    <FormattedMessage id='client_container.debt' />({mrdsStats && mrdsStats.count})
                                </div>
                            }
                        >
                            <ClientMRDsTab
                                client={clientEntity}
                                clientId={clientId}
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.DIRECTORIES_CUSTOMERS_LIST_OUTSTANDING_DEBTS,
                                        accesses.ROWO
                                    )
                                }
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_CALLS) && (
                        <TabPane
                            key='calls'
                            tab={
                                <div>
                                    <FormattedMessage id='client_container.calls' />({callStats && callStats.total})
                                </div>
                            }
                        >
                            <ClientCallsTab
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_CALLS, accesses.ROWO)
                                }
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_DISCOUNTS) && (
                        <TabPane key='discounts' tab={<FormattedMessage id='client_container.discounts' />}>
                            <ClientDiscountsTab
                                client={clientEntity}
                                clientId={clientId}
                                disabled={
                                    !isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_DISCOUNTS, accesses.ROWO)
                                }
                                fetchClient={fetchClient}
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.DIRECTORIES_CUSTOMERS_LIST_INTERACTIONS) && (
                        <TabPane key='interactions' tab={<FormattedMessage id='navigation.interactions' />}>
                            <InteractionsTable
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.DIRECTORIES_CUSTOMERS_LIST_INTERACTIONS,
                                        accesses.ROWO
                                    )
                                }
                                reqfilters={{
                                    counterpartyId: clientId
                                }}
                            />
                        </TabPane>
                    )}
                </Tabs>
            </Catcher>
        );
    }
}

import { Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { Layout } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import OrderCheckTab from './components/Tabs/OrderCheckTab';
import StatusRelationTab from './components/Tabs/StatusRelationTab';
import StatusTab from './components/Tabs/StatusTab';
import WorkshopCheckTab from './components/Tabs/WorkshopCheckTab';

const HEADER_CLIENT_SEARCH = 'HEADER_CLIENT_SEARCH';
const HEADER_STATION = 'HEADER_STATION';
const HEADER_EMPLOYEE = 'HEADER_EMPLOYEE';
const HEADER_REQUISITES = 'HEADER_REQUISITES';
const HEADER_CHANGE_STATUS = 'HEADER_CHANGE_STATUS';
const DIAGNOSTICS_ADD = 'DIAGNOSTICS_ADD';
const DIAGNOSTICS_ELEMENTS = 'DIAGNOSTICS_ELEMENTS';
const DIAGNOSTICS_COMPLETE = 'DIAGNOSTICS_COMPLETE';
const DIAGNOSTICS_CALCULATION = 'DIAGNOSTICS_CALCULATION';
const LABORS = 'LABORS';
const DETAILS = 'DETAILS';
const LABORS_DISCOUNTS = 'LABORS_DISCOUNTS';
const DETAILS_DISCOUNTS = 'DETAILS_DISCOUNTS';
const HEADER_SEND_AGREEMENT = 'HEADER_SEND_AGREEMENT';
const PRINT_INVOICE = 'PRINT_INVOICE';
const HEADER_PAY = 'HEADER_PAY';
const STOCK_BUTTON_ORDERED = 'STOCK_BUTTON_ORDERED';
const STOCK_BUTTON_ACCEPTED = 'STOCK_BUTTON_ACCEPTED';
const STOCK_BUTTON_RESERVED = 'STOCK_BUTTON_RESERVED';
const PRINT_ACT_OF_ACCEPTANCE = 'PRINT_ACT_OF_ACCEPTANCE';
const STOCK_BUTTON_GIVEN = 'STOCK_BUTTON_GIVEN';
const CREATE_DOC_TOL = 'CREATE_DOC_TOL';
const PRINT_BUSINESS_ORDER = 'PRINT_BUSINESS_ORDER';
const WORKSHOP = 'WORKSHOP';
const WORKSHOP_BUTTON_FINISH = 'WORKSHOP_BUTTON_FINISH';
const STOCK_BUTTON_RETURNED = 'STOCK_BUTTON_RETURNED';
const CREATE_DOC_TOR = 'CREATE_DOC_TOR';
const ORDER_CHECK = 'ORDER_CHECK';
const PRINT_COMPLETED_WORK = 'PRINT_COMPLETED_WORK';

const OPERATIONS = [
    HEADER_CLIENT_SEARCH,
    HEADER_STATION,
    HEADER_EMPLOYEE,
    HEADER_REQUISITES,
    HEADER_CHANGE_STATUS,
    DIAGNOSTICS_ADD,
    DIAGNOSTICS_ELEMENTS,
    DIAGNOSTICS_COMPLETE,
    DIAGNOSTICS_CALCULATION,
    LABORS,
    DETAILS,
    LABORS_DISCOUNTS,
    DETAILS_DISCOUNTS,
    HEADER_SEND_AGREEMENT,
    PRINT_INVOICE,
    HEADER_PAY,
    STOCK_BUTTON_ORDERED,
    STOCK_BUTTON_ACCEPTED,
    STOCK_BUTTON_RESERVED,
    PRINT_ACT_OF_ACCEPTANCE,
    STOCK_BUTTON_GIVEN,
    CREATE_DOC_TOL,
    PRINT_BUSINESS_ORDER,
    WORKSHOP,
    WORKSHOP_BUTTON_FINISH,
    STOCK_BUTTON_RETURNED,
    CREATE_DOC_TOR,
    ORDER_CHECK,
    PRINT_COMPLETED_WORK
];

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class RepairMapSettingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { user } = this.props;

        return (
            <Layout title={<FormattedMessage id='navigation.statuses_directory' />}>
                <Tabs destroyInactiveTabPane>
                    {isGrantAccessed(user, grants.SETTINGS_ORDER_STATUSES_ORDER_STATUSES) && (
                        <TabPane key='statuses' tab={<FormattedMessage id='status_directory.statuses_tab' />}>
                            <StatusTab
                                disabled={
                                    !isGrantAccessed(user, grants.SETTINGS_ORDER_STATUSES_ORDER_STATUSES, accesses.ROWO)
                                }
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.SETTINGS_ORDER_STATUSES_STATUS_TRANSITIONS) && (
                        <TabPane
                            key='status-transitions'
                            tab={<FormattedMessage id='status_directory.status_transitions' />}
                        >
                            <StatusRelationTab
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.SETTINGS_ORDER_STATUSES_STATUS_TRANSITIONS,
                                        accesses.ROWO
                                    )
                                }
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.SETTINGS_ORDER_STATUSES_ORDER_STATUS_CHECK) && (
                        <TabPane key='order-check' tab={<FormattedMessage id='status_directory.order_check' />}>
                            <OrderCheckTab
                                disabled={
                                    !isGrantAccessed(
                                        user,
                                        grants.SETTINGS_ORDER_STATUSES_ORDER_STATUS_CHECK,
                                        accesses.ROWO
                                    )
                                }
                            />
                        </TabPane>
                    )}
                    {isGrantAccessed(user, grants.SETTINGS_ORDER_WORKSHOP_CHECK) && (
                        <TabPane key='workshop-check' tab={<FormattedMessage id='repair_map_setting.workshop_check' />}>
                            <WorkshopCheckTab
                                disabled={!isGrantAccessed(user, grants.SETTINGS_ORDER_WORKSHOP_CHECK, accesses.ROWO)}
                            />
                        </TabPane>
                    )}
                    {/* <TabPane key='repair-map' tab={<FormattedMessage id='navigation.repair_map' />}>
                        <RepairTab />
                    </TabPane> */}
                </Tabs>
            </Layout>
        );
    }
}

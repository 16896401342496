import { Input, Modal, Select } from 'antd';
import { selectModalProps } from 'core/modals/duck';
import { fetchPriceGroups, selectPriceGroups } from 'core/storage/priceGroups';
import { createStoreGroup, deleteStoreGroup, updateStoreGroup } from 'core/storage/storeGroups';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { div: 8 },
    wrapperCol: { div: 16 }
};

const mapDispatchToProps = {
    createStoreGroup,
    updateStoreGroup,
    deleteStoreGroup,
    fetchPriceGroups
};

const WorkshopCheckAddModal = props => {
    const {
        open,
        hideModal,

        intl: { formatMessage },
        editMode,
        fetchEntries,
        requisites
    } = props;

    // eslint-disable-next-line react-redux/useSelector-prefer-selectors
    const user = useSelector(state => state.auth);
    // eslint-disable-next-line react-redux/useSelector-prefer-selectors
    const isMobile = useSelector(state => state.ui.views.isMobile);
    const modalProps = useSelector(selectModalProps);
    const priceGroups = useSelector(selectPriceGroups);

    const [check, setName] = useState(undefined);
    const [docType, setDocType] = useState(undefined);
    const [comment, setComment] = useState(' ');
    const [operationType, setOperationType] = useState(undefined);
    const [counterpartType, setCounterpartyType] = useState(undefined);
    const [codes, setWorkchopCodes] = useState([]);
    const [codeId, setCodeId] = useState();
    const [value, setValue] = useState();

    const addCheck = async () => {
        await fetchAPI(
            'POST',
            '/business/workshop/check/settings',
            undefined,
            {
                codeId,
                check,
                value,
                isActive: true
            },
            { handleErrorInternally: true }
        );
    };

    const editCheck = async () => {
        await fetchAPI(
            'PUT',
            '/business/workshop/check/settings',
            undefined,
            {
                id: editMode.id,
                // codeId,
                check,
                value,
                isActive: editMode.isActive
            },
            { handleErrorInternally: true }
        );
    };

    const fetchWorkshopCodes = useCallback(async () => {
        const response = await fetchAPI('GET', '/business/workshop/status/codes', null, null, {
            handleErrorInternally: true
        });

        setWorkchopCodes(response);
    }, []);

    useEffect(() => {
        if (editMode) {
            setName(editMode.check);
            setValue(editMode.value);
            setCodeId(editMode.codeId);
        }
    }, [editMode]);

    const onClose = async () => {
        setName(undefined);
        setValue(undefined);
        setCodeId(undefined);

        hideModal();
    };

    const onFinish = async () => {
        if (!editMode) {
            addCheck();
        } else {
            editCheck();
        }

        await onClose();
    };

    useEffect(() => {
        if (open && !codes?.lenght) {
            fetchWorkshopCodes();
        }
    }, [codes?.lenght, fetchWorkshopCodes, open]);

    return (
        <Modal
            cancelText={<FormattedMessage id='cancel' />}
            maskClosable={false}
            okButtonProps={{
                disabled: !value || !check || !codeId
            }}
            onCancel={() => onClose()}
            onOk={() => onFinish()}
            open={open}
            title={
                !editMode ? (
                    <FormattedMessage id='Створити перевірку' />
                ) : (
                    <FormattedMessage id='Редагувати перевірку' />
                )
            }
            width={isMobile ? '95%' : '30%'}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 6
                }}
            >
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='description' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Input
                        allowClear
                        onChange={event => {
                            setName(event.target.value);
                        }}
                        placeholder={formatMessage({
                            id: 'description'
                        })}
                        value={check}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='info_modal.value' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Input
                        allowClear
                        onChange={event => {
                            setValue(event.target.value);
                        }}
                        placeholder={formatMessage({
                            id: 'description'
                        })}
                        value={value}
                    />
                </div>
                <div className={Styles.divGroup}>
                    <div>
                        <FormattedMessage id='services_table.check_code' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <Select
                        allowClear
                        disabled={editMode}
                        onChange={value => setCodeId(value)}
                        optionFilterProp='children'
                        showSearch
                        value={codeId}
                        style={{ color: 'var(--text)', width: '100%' }}
                        // mode='multiple'
                        placeholder={formatMessage({
                            id: 'services_table.check_code'
                        })}
                    >
                        {codes.map((elem, i) => {
                            return (
                                <Option key={i} value={elem.id}>
                                    {elem.code === 'DONE'
                                        ? formatMessage({
                                              id: 'workshop_table.button.finish'
                                          })
                                        : elem.code}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
            </div>
        </Modal>
    );
};

export default injectIntl(WorkshopCheckAddModal);

/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import {
    CopyOutlined,
    DeleteOutlined,
    FormOutlined,
    InfoCircleOutlined,
    MessageOutlined,
    QuestionCircleOutlined,
    VideoCameraOutlined
} from '@ant-design/icons';
import {
    Button,
    Drawer,
    Image,
    Input,
    InputNumber,
    Modal,
    Select,
    Table,
    Tooltip,
    TreeSelect,
    Upload,
    notification
} from 'antd';
import { AvailabilityIndicator } from 'components';
import { MODALS, saveModal, setModal } from 'core/modals/duck';
import _, { get } from 'lodash';
import { DetailCatalogueModal, DetailSupplierModal, DetailWarehousesCountModal, OilModal } from 'modals';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { StockIcon } from 'theme';
import {
    buildSupplierOptions,
    fetchAPI,
    filterTreeNodeByPart,
    images,
    isForbidden,
    numeralFormatter,
    numeralParser,
    permissions
} from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import './styles.less';
import Styles from './styles.m.css';

const { Option } = Select;
const { confirm } = Modal;

const NUMBER_CLMN_WIDTH = 110;
const SMALL_BTN_WIDTH = 40;
const BIG_BTN_WIDTH = 60;

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    isMobile: state.ui.views.isMobile,
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {
    saveModal,
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class OrderDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            helperDrawerOpen: false,
            allLinks: [],
            existingOECode: undefined
        };

        this.handleFetchSellingPrice = _.debounce(
            async (purchasePrice, storeGroupId, detailCode, supplierId, price, count, productId) => {
                await this.getMarkupsSellingPrice(
                    purchasePrice,
                    storeGroupId,
                    detailCode,
                    supplierId,
                    price,
                    count,
                    productId
                );
            },
            2100
        ).bind(this);

        this.debounceUpdateDataSource = _.debounce(value => this.changeDataSourceField(value), 2000);

        this.columns = () => {
            const storeGroupCol = {
                title: <FormattedMessage id='order_form_table.store_group' />,
                key: 'storeGroupId',
                dataIndex: 'storeGroupId',
                width: 217,
                render: (data, elem) => {
                    return (
                        <Tooltip position='top' title={<FormattedMessage id='order_form_table.store_group' />}>
                            <TreeSelect
                                data-qa='tree_select_storeGroupId_detail_product_modal'
                                disabled={elem.agreement === 'AGREED' || this.props.isRTN}
                                filterTreeNode={filterTreeNodeByPart}
                                getPopupContainer={() => document.querySelector('.addDetailModal')}
                                onSelect={async (value, option) => {
                                    console.log(option, 'option,');
                                    this.changeDataSourceField({
                                        storeGroupId: value,
                                        detailName: option.singleName,
                                        multiplier: option.multiplier
                                    });

                                    if (elem.purchasePrice) {
                                        const newSellingPrice = await fetchAPI(
                                            'GET',
                                            '/selling_price',
                                            {
                                                purchasePrice: elem.purchasePrice,
                                                storeGroupId: elem.storeGroupId || null,
                                                clientId: this.props.clientId || null,
                                                code: elem.detailCode || null,
                                                supplierId: elem.supplierId || null,
                                                productId: elem.productId || null
                                            },
                                            {
                                                handleErrorInternally: true
                                            }
                                        );
                                        this.setState({
                                            newSellingPrice
                                        });
                                    }
                                    if (this.state.newSellingPrice) {
                                        this.changeDataSourceField({
                                            price: this.state.newSellingPrice.sellingPrice,
                                            sum: elem.price * elem.count
                                        });
                                    }
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.store_group'
                                })}
                                popupMatchSelectWidth={320}
                                showSearch
                                style={{
                                    maxWidth: 217,
                                    minWidth: 180
                                }}
                                treeData={this.props.treeData}
                                value={data}
                            />
                        </Tooltip>
                    );
                }
            };

            const vinCol = {
                key: 'vin',
                width: BIG_BTN_WIDTH,
                render: elem => {
                    const { updateDataSource } = this.props;

                    return (
                        <Button
                            data-qa='button_setModal_vin_detail_product_modal'
                            disabled={
                                !isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_VIN) ||
                                !this.props.clientVehicleVin ||
                                elem.agreement === 'AGREED' ||
                                this.props.isRTN
                            }
                            onClick={() => {
                                this.props.saveModal();
                                this.props.setModal(MODALS.VIN_MODAL, {
                                    existingOECode: _.get(this.state.dataSource, '[0].oeCode'),
                                    storeGroupId: elem.storeGroupId,
                                    setVinDetail: product => this.vinModalAction(product),
                                    setVinDetails: async codes => {
                                        const data = {
                                            insertMode: true,
                                            details: codes.map(({ oem, name, storeGroupId }) => ({
                                                productCode: oem,
                                                name,
                                                groupId: storeGroupId,
                                                oeCode: _.get(this.state.dataSource, '[0].oeCode')
                                                    ? _.get(this.state.dataSource, '[0].oeCode')
                                                    : oem
                                            }))
                                        };
                                        await fetchAPI('PUT', `orders/${this.props.orderId}`, null, data, {
                                            handleErrorInternally: true
                                        });
                                        this.handleCancel();
                                        updateDataSource();
                                    },
                                    modalToLoad: MODALS.DETAIL_EDIT_MODAL
                                });
                            }}
                            style={{
                                fontWeight: 500,
                                padding: '4px 12px'
                            }}
                        >
                            VIN
                        </Button>
                    );
                }
            };

            const detailNameCol = {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'detailName',
                dataIndex: 'detailName',
                render: (data, elem) => {
                    return (
                        <Tooltip placement='bottom' title={<FormattedMessage id='order_form_table.detail_name' />}>
                            <Input
                                data-qa='input_detailName_detail_product_modal'
                                disabled={elem.agreement === 'AGREED' || this.props.isRTN}
                                maxLength={255}
                                onChange={event => {
                                    this.changeDataSourceField({
                                        detailName: event.target.value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_name'
                                })}
                                style={{ minWidth: 220 }}
                                value={data}
                            />
                        </Tooltip>
                    );
                }
            };

            const commentCol = {
                key: 'comment',
                width: SMALL_BTN_WIDTH,
                render: row => {
                    let detail = String(row.detailName);
                    if (detail && detail.indexOf(' - ') > -1) {
                        detail = detail.slice(0, detail.indexOf(' - '));
                    }

                    return (
                        <div>
                            <CommentaryButton
                                commentary={
                                    row.comment || {
                                        comment: undefined,
                                        positions: []
                                    }
                                }
                                data-qa='button_comment_order_detail_edit_modal'
                                detail={detail}
                                disabled={!row.detailName || row.agreement === 'AGREED'}
                                setComment={(comment, positions, problems) => {
                                    this.changeDataSourceField({
                                        comment: {
                                            comment,
                                            positions,
                                            problems
                                        },
                                        detailName: get(comment, 'length') ? comment : detail
                                    });
                                }}
                            />
                        </div>
                    );
                }
            };

            const brandCol = {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brandId',
                dataIndex: 'brandId',
                render: (data, elem) => {
                    if (elem.brandName && !elem.brandId) {
                        const defaultBrand = this.props.brands.find(brand => brand.brandName === elem.brandName);
                        if (defaultBrand) {
                            this.changeDataSourceField({
                                brandId: defaultBrand.brandId
                            });
                        }
                    }

                    return (
                        <Tooltip position='top' title={<FormattedMessage id='order_form_table.brand' />}>
                            <Select
                                allowClear
                                data-qa='select_brandId_order_detail_edit_modal'
                                disabled={
                                    !this.props.disableSparePartsWorkPlace
                                        ? elem.agreement === 'AGREED' || this.props.isRTN
                                        : null
                                }
                                filterOption={(input, option) => {
                                    return (
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.value).indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                getPopupContainer={() => document.querySelector('.addDetailModal')}
                                onChange={async (value, option) => {
                                    const brandName = option ? option.children : undefined;
                                    await this.changeDataSourceField({
                                        brandId: value,
                                        supplierBrandId: value,
                                        brandName
                                    });
                                    this.getStorageProduct(elem);
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.brand'
                                })}
                                showSearch
                                value={data}
                            >
                                {this.props.brands.map(elem => (
                                    <Option
                                        key={`${elem.brandId}-${elem.brandName}`}
                                        supplier_id={elem.supplierId}
                                        value={elem.brandId}
                                    >
                                        {elem.brandName}
                                    </Option>
                                ))}
                            </Select>
                        </Tooltip>
                    );
                }
            };

            const detailCodeCol = {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'detailCode',
                dataIndex: 'detailCode',
                render: (data, elem) => {
                    return (
                        <Tooltip position='top' title={<FormattedMessage id='order_form_table.detail_code' />}>
                            <Input
                                disabled={
                                    !this.props.disableSparePartsWorkPlace
                                        ? elem.agreement === 'AGREED' || this.props.isRTN
                                        : null
                                }
                                onChange={({ target }) => {
                                    const { value } = target;
                                    this.changeDataSourceField({ detailCode: value });

                                    if (
                                        elem.purchasePrice &&
                                        elem.agreement !== 'AGREED' &&
                                        elem.agreement !== 'REJECTED'
                                    ) {
                                        this.handleFetchSellingPrice(
                                            elem.purchasePrice,
                                            elem.storeGroupId,
                                            elem.detailCode,
                                            elem.supplierId,
                                            elem.price,
                                            elem.count,
                                            elem.productId
                                        );
                                    }
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_code'
                                })}
                                style={{ color: 'black', minWidth: 138 }}
                                suffix={
                                    (elem.oeCode || elem.supplierPartNumber) && (
                                        <Tooltip
                                            getPopupContainer={trigger => trigger.parentNode}
                                            placement='bottom'
                                            title={
                                                <React.Fragment>
                                                    <div>
                                                        <FormattedMessage id='order_form_table.OE_code' />:{' '}
                                                        {elem.oeCode || <FormattedMessage id='long_dash' />}
                                                    </div>
                                                    <div>
                                                        <FormattedMessage id='order_form_table.supplier_code' />:{' '}
                                                        {elem.supplierPartNumber || <FormattedMessage id='long_dash' />}
                                                    </div>
                                                </React.Fragment>
                                            }
                                            zIndex={9999}
                                        >
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                    )
                                }
                                value={data}
                                // onBlur={() => this.getStorageProduct(elem)}
                            />
                        </Tooltip>
                    );
                }
            };

            const catalogueCol = {
                key: 'catalogueCol',
                dataIndex: 'detailCode',
                width: BIG_BTN_WIDTH,
                render: (data, elem) => {
                    return (
                        <Tooltip
                            placement='bottom'
                            title={<FormattedMessage id='details_table.details_catalogue' />}
                            zIndex={2001}
                        >
                            <Button
                                data-qa='button_visible_catalogue_detail_storage_modal'
                                disabled={
                                    elem.agreement === 'AGREED' ||
                                    !isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_CATALOG) ||
                                    this.props.isRTN
                                }
                                onClick={() => {
                                    this.setState({
                                        catalogModalRow: elem
                                    });
                                }}
                            >
                                <div
                                    style={{
                                        width: 18,
                                        height: 18,
                                        backgroundColor: 'var(--text)',
                                        mask: `url(${images.bookIcon}) no-repeat center / contain`,
                                        WebkitMask: `url(${images.bookIcon}) no-repeat center / contain`
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    );
                }
            };

            const supplierCol = {
                title: <FormattedMessage id='order_form_table.supplier' />,
                key: 'supplier',
                dataIndex: 'supplierId',
                width: 340,
                render: (supplierId, elem) => {
                    const warehouse = this.props.warehouses.find(({ id }) => id === elem.warehouseId);

                    return (
                        <Tooltip placement='bottom' title={<FormattedMessage id='order_form_table.supplier' />}>
                            <Select
                                data-qa='select_supplierName_order_detail_edit_modal'
                                disabled={this.props.isRTN}
                                getPopupContainer={() => document.querySelector('.addDetailModal')}
                                onSearch={input => this.props.handleSearchSuppliers(input)}
                                onSelect={async value => {
                                    this.changeDataSourceField({ supplierId: value });

                                    // if (elem.purchasePrice) {
                                    //     const newSellingPrice = await fetchAPI(
                                    //         'GET',
                                    //         '/selling_price',
                                    //         {
                                    //             purchasePrice: elem.purchasePrice,
                                    //             storeGroupId: elem.storeGroupId || null,
                                    //             clientId: this.props.clientId || null,
                                    //             code: elem.detailCode || null,
                                    //             supplierId: elem.supplierId || null
                                    //         },
                                    //         {
                                    //             handleErrorInternally: true
                                    //         }
                                    //     );
                                    //     this.setState({
                                    //         newSellingPrice
                                    //     });
                                    // }
                                    // if (this.state.newSellingPrice) {
                                    //     this.changeDataSourceField({
                                    //         price: this.state.newSellingPrice.sellingPrice,
                                    //         sum: elem.price * elem.count
                                    //     });
                                    // }
                                }}
                                optionFilterProp='children'
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.supplier'
                                })}
                                showSearch
                                style={{
                                    width: '100%',
                                    maxWidth: 340,
                                    minWidth: 220
                                }}
                                suffixIcon={
                                    elem.productId && (
                                        <Tooltip
                                            getPopupContainer={trigger => trigger.parentNode}
                                            title={
                                                <React.Fragment>
                                                    <div>
                                                        <FormattedMessage id='navigation.storage' />:{' '}
                                                        {_.get(warehouse, 'name') || (
                                                            <FormattedMessage id='long_dash' />
                                                        )}
                                                    </div>
                                                    <div>
                                                        <FormattedMessage id='wms.cell' />:{' '}
                                                        {elem.cellAddress || <FormattedMessage id='long_dash' />}
                                                    </div>
                                                </React.Fragment>
                                            }
                                            zIndex={9999}
                                        >
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                    )
                                }
                                value={supplierId}
                            >
                                <Option key={0} value={0}>
                                    <FormattedMessage id='navigation.storage' />
                                </Option>
                                {supplierId
                                    ? buildSupplierOptions(this.props.suppliers, {
                                          id: supplierId,
                                          name: elem.supplierName
                                      }).map(({ name, id }) => (
                                          <Option key={id} value={id}>
                                              {name}
                                          </Option>
                                      ))
                                    : this.props.suppliers.map(({ name, id }) => (
                                          <Option key={id} value={id}>
                                              {name}
                                          </Option>
                                      ))}
                            </Select>
                        </Tooltip>
                    );
                }
            };

            const supplierBtn = {
                key: 'supplierBtn',
                dataIndex: 'supplierId',
                width: SMALL_BTN_WIDTH,
                render: (supplierId, elem) => {
                    const warehouse = this.props.warehouses.find(({ id }) => id === elem.warehouseId);

                    return (
                        <DetailSupplierModal
                            brandId={elem.brandId}
                            detailCode={elem.detailCode}
                            disabled={
                                !elem.detailCode ||
                                !elem.brandId ||
                                !isGrantAccessed(this.props.user, grants.DIRECTORIES_PRODUCTS_LIST_SUPPLIERS) ||
                                this.props.isWarehouseOrder ||
                                this.props.isRTN
                            }
                            onSelect={this.setSupplier}
                            partUnitId={elem.partUnitId}
                            productId={this.props.productIdForSupplier}
                            storeGroupId={elem.storeGroupId}
                            tableKey={elem.key}
                            user={this.props.user}
                        />
                    );
                }
            };

            const supplierPriceBtn = {
                key: 'supplierPriceBtn',
                dataIndex: 'supplierId',
                width: SMALL_BTN_WIDTH,
                render: (supplierId, elem) => {
                    const warehouse = this.props.warehouses.find(({ id }) => id === elem.warehouseId);

                    return (
                        <Tooltip
                            placement='bottom'
                            title={<FormattedMessage id='order_form_table.choose_a_warehouse' />}
                            zIndex={2001}
                        >
                            <Button
                                disabled={!elem.productId || this.props.isRTN}
                                icon={<StockIcon />}
                                onClick={() => {
                                    this.setState({ warehousesModalSelectedRow: elem });
                                }}
                            />
                        </Tooltip>
                    );
                }
            };

            const AICol = {
                title: (
                    <div title={this.props.intl.formatMessage({ id: 'order_form_table.AI_title' })}>
                        <FormattedMessage id='order_form_table.AI' />
                    </div>
                ),
                key: 'AI',
                dataIndex: 'store',
                width: SMALL_BTN_WIDTH,
                render: store => {
                    return <AvailabilityIndicator indexArray={store} />;
                }
            };

            const purchasePriceCol = {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                width: NUMBER_CLMN_WIDTH,
                render: (data, elem) => {
                    return (
                        <Tooltip placement='topLeft' title={<FormattedMessage id='order_form_table.purchasePrice' />}>
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className={Styles.detailNumberInput}
                                    data-qa='input_number_purchasePrice_order_detail_edit_modal'
                                    decimalSeparator=','
                                    disabled={
                                        !isGrantAccessed(
                                            this.props.user,
                                            grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
                                        ) || this.props.isRTN
                                    }
                                    formatter={value => numeralFormatter(value)}
                                    min={0}
                                    onBlur={async () => {
                                        this.debounceUpdateDataSource.cancel();
                                        this.changeDataSourceField({ purchasePrice: elem.purchasePrice });
                                        if (elem.agreement !== 'AGREED' && elem.agreement !== 'REJECTED') {
                                            await this.changeDataSourceField({
                                                price: this.state.newSellingPrice.sellingPrice,
                                                sum: elem.price * elem.count
                                            });
                                        }
                                    }}
                                    onChange={async purchasePrice => {
                                        const storeGroup = this.props.allDetails.find(
                                            detail => detail.id === elem.storeGroupId
                                        );
                                        const multiplier =
                                            elem.multiplier || (storeGroup ? storeGroup.priceGroupMultiplier : 1);
                                        let { price } = elem;

                                        if (elem.agreement !== 'AGREED') {
                                            price = Math.round(purchasePrice * multiplier * 10000) / 10000;
                                        }
                                        if (purchasePrice) {
                                            elem.purchasePrice = purchasePrice;
                                            await this.debounceUpdateDataSource({ purchasePrice });
                                        }
                                        if (this.props.isWarehouseOrder) {
                                            this.debounceUpdateDataSource({ price: purchasePrice });
                                        }

                                        if (
                                            elem.purchasePrice &&
                                            elem.agreement !== 'AGREED' &&
                                            elem.agreement !== 'REJECTED'
                                        ) {
                                            await this.handleFetchSellingPrice(
                                                elem.purchasePrice,
                                                elem.storeGroupId,
                                                elem.detailCode,
                                                elem.supplierId,
                                                elem.price,
                                                elem.count,
                                                elem.productId
                                            );
                                        }

                                        // if (elem.purchasePrice) {
                                        //     debounce(
                                        //         1000,
                                        //         this.getMarkupsSellingPrice(
                                        //             elem.purchasePrice,
                                        //             elem.storeGroupId,
                                        //             elem.detailCode,
                                        //             elem.supplierId,
                                        //             elem.price,
                                        //             elem.count
                                        //         )
                                        //     );
                                        //     await this.changeDataSourceField({
                                        //         price: this.state.newSellingPrice.sellingPrice,
                                        //         sum: elem.price * elem.count
                                        //     });
                                        // }
                                    }}
                                    onStep={() => this.setState({})}
                                    parser={value => numeralParser(value)}
                                    precision={4}
                                    style={{
                                        width: '100%',
                                        minWidth: 90,
                                        fontSize: data > 9999 && data !== undefined ? 11 : 13
                                    }}
                                    value={data || 0}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const partRowDiscountCol = {
                title: <FormattedMessage id='order_form_table.discount' />,
                key: 'partRowDiscount',
                dataIndex: 'partRowDiscount',
                width: NUMBER_CLMN_WIDTH,
                render: (data, elem) => {
                    return (
                        <Tooltip placement='topRight' title={<FormattedMessage id='discount_or_markup' />}>
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className='detailNumberInput'
                                    decimalSeparator=','
                                    disabled={
                                        !this.props.disableSparePartsWorkPlace ? elem.agreement === 'AGREED' : null
                                    }
                                    formatter={value => `${value}%`}
                                    max={100}
                                    onBlur={() => {
                                        this.debounceUpdateDataSource.cancel();
                                        this.changeDataSourceField({ partRowDiscount: elem.partRowDiscount });
                                    }}
                                    onChange={partRowDiscount => {
                                        if (partRowDiscount) {
                                            elem.partRowDiscount = partRowDiscount;
                                            this.debounceUpdateDataSource({
                                                partRowDiscount
                                            });
                                        }
                                    }}
                                    parser={value => value.replace('%', '').replace(',', '.')}
                                    precision={2}
                                    style={{
                                        width: '100%',
                                        minWidth: 90,
                                        fontSize: data > 99999 && data !== undefined ? 11 : 14
                                    }}
                                    value={Math.round(data * 100) / 100 || 0}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const priceCol = {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                dataIndex: 'price',
                width: NUMBER_CLMN_WIDTH,
                render: (data, elem) => {
                    return (
                        <Tooltip
                            placement='top'
                            title={
                                <div>
                                    <FormattedMessage id='order_form_table.price' />{' '}
                                    <span
                                        style={{
                                            fontSize: 12,
                                            fontWeight: 400
                                        }}
                                    >
                                        (<FormattedMessage id='without' /> <FormattedMessage id='VAT' />)
                                    </span>
                                </div>
                            }
                        >
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className='detailNumberInput'
                                    data-qa='input_number_price_order_detail_edit_modal'
                                    decimalSeparator=','
                                    disabled={
                                        !isGrantAccessed(
                                            this.props.user,
                                            grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_CHANGE_PURCHASE_AND_SALE_AND_DISCOUNT
                                        ) ||
                                        elem.agreement === 'AGREED' ||
                                        this.props.isWarehouseOrder ||
                                        this.props.isRTN
                                    }
                                    formatter={value => numeralFormatter(value)}
                                    min={0}
                                    onBlur={() => {
                                        this.debounceUpdateDataSource.cancel();
                                        this.changeDataSourceField({ price: elem.price, sum: elem.price * elem.count });
                                    }}
                                    onChange={price => {
                                        if (price) {
                                            elem.price = price;
                                            this.debounceUpdateDataSource({
                                                price,
                                                sum: price * elem.count
                                            });
                                        }
                                    }}
                                    onStep={() => this.setState({})}
                                    parser={value => numeralParser(value)}
                                    precision={4}
                                    style={{
                                        width: '100%',
                                        minWidth: 90,
                                        fontSize: data > 9999 && data !== undefined ? 11 : 13
                                    }}
                                    value={data || 0}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const countCol = {
                title: <FormattedMessage id='order_form_table.count' />,
                key: 'count',
                dataIndex: 'count',
                width: NUMBER_CLMN_WIDTH,
                render: (data, elem) => {
                    return (
                        <Tooltip placement='bottomLeft' title={<FormattedMessage id='order_docs.quantity' />}>
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className='detailNumberInput'
                                    data-qa='input_number_count_order_detail_edit_modal'
                                    decimalSeparator=','
                                    disabled={elem.agreement === 'AGREED'}
                                    formatter={value => numeralFormatter(value)}
                                    min={0.0001}
                                    onBlur={() => {
                                        this.debounceUpdateDataSource.cancel();
                                        this.changeDataSourceField({ count: elem.count, sum: elem.count * elem.price });
                                    }}
                                    onChange={count => {
                                        if (count) {
                                            elem.count = count;
                                            this.debounceUpdateDataSource({ count, sum: count * elem.price });
                                        }
                                    }}
                                    onStep={() => this.setState({})}
                                    parser={value => numeralParser(value)}
                                    precision={4}
                                    style={{
                                        width: '100%',
                                        minWidth: 90,
                                        fontSize: data > 9999 && data !== undefined ? 11 : 13
                                    }}
                                    value={data || 1}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const unitsCol = {
                title: <FormattedMessage id='services_table.units' />,
                key: 'partUnitId',
                dataIndex: 'partUnitId',
                align: 'right',
                width: NUMBER_CLMN_WIDTH,
                render: (data, elem) => {
                    return (
                        <Tooltip
                            getPopupContainer={() => document.querySelector('.addDetailModal')}
                            placement='bottom'
                            title={<FormattedMessage id='navigation.units' />}
                            trigger={['hover']}
                        >
                            <Select
                                allowClear
                                data-qa='select_partUnitId_order_detail_edit_modal'
                                disabled={this.props.isRTN}
                                dropdownStyle={{
                                    zIndex: 2001
                                }}
                                filterOption={(input, option) => {
                                    return (
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.value).indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                getPopupContainer={() => document.querySelector('.addDetailModal')}
                                onChange={async (value, option) => {
                                    const partUnitName = option ? option.children : undefined;
                                    await this.changeDataSourceField({
                                        partUnitId: value,
                                        partUnitName
                                    });
                                    this.getStorageProduct(elem);
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'services_table.units_placeholder'
                                })}
                                showSearch
                                style={{
                                    width: '100%',
                                    float: 'right',
                                    minWidth: 90,
                                    maxWidth: 102
                                }}
                                value={data || 1}
                                zIndex={2001}
                            >
                                {(this.props.units || []).map(elem => (
                                    <Option
                                        key={`${elem.id}-${elem.shortcut}`}
                                        style={{
                                            textAlign: 'right'
                                        }}
                                        value={elem.id}
                                    >
                                        {elem.shortcut}
                                    </Option>
                                ))}
                            </Select>
                        </Tooltip>
                    );
                }
            };

            const sumCol = {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.sum' />
                        <p
                            style={{
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'sum',
                width: NUMBER_CLMN_WIDTH,
                render: elem => {
                    const sum = elem.price * (elem.count || 1) || 0;
                    const discountSum = sum - (sum * elem.partRowDiscount) / 100;

                    return (
                        <Tooltip
                            placement='bottomRight'
                            title={
                                <div>
                                    <FormattedMessage id='order_form_table.sum' />{' '}
                                    <span
                                        style={{
                                            fontSize: 12,
                                            fontWeight: 400
                                        }}
                                    >
                                        (<FormattedMessage id='without' /> <FormattedMessage id='VAT' />)
                                    </span>
                                </div>
                            }
                        >
                            <div className={Styles.detailNumberInput}>
                                <InputNumber
                                    className='detailNumberInput'
                                    data-qa='input_number_sum_order_detail_edit_modal'
                                    decimalSeparator=','
                                    disabled
                                    formatter={value => numeralFormatter(value)}
                                    parser={value => numeralParser(value)}
                                    precision={4}
                                    readOnly
                                    style={{
                                        width: '100%',
                                        minWidth: 90,
                                        maxWidth: 102,
                                        fontSize: discountSum > 9999 || sum > 99999 ? 11 : 13
                                    }}
                                    value={elem.partRowDiscount > 0 ? discountSum.toFixed(4) : sum.toFixed(4)}
                                />
                            </div>
                        </Tooltip>
                    );
                }
            };

            const oeCode = {
                title: <FormattedMessage id='order_form_table.og_detail_code' />,
                key: 'oeCode',
                width: 217,
                render: (data, elem) => {
                    return (
                        <Tooltip placement='top' title={<FormattedMessage id='order_form_table.og_detail_code' />}>
                            <Input
                                onChange={({ target }) => {
                                    const { value } = target;
                                    this.changeDataSourceField({ oeCode: value });
                                    
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.og_detail_code'
                                })}
                                style={{ color: 'black', maxWidth: 217, minWidth: 138 }}
                                value={elem.oeCode}
                                suffix={
                                    <CopyToClipboard text={elem.detailCode}>
                                        <Tooltip placement='topLeft' title={<FormattedMessage id='copy' />}>
                                            <CopyOutlined className={Styles.copyIcon} />
                                        </Tooltip>
                                    </CopyToClipboard>
                                }

                                // onBlur={() => this.getStorageProduct(elem)}
                            />
                        </Tooltip>
                    );
                }
            };

            return {
                upperRow: [
                    storeGroupCol,
                    vinCol,
                    catalogueCol,
                    detailCodeCol,
                    oeCode,
                    brandCol,
                    purchasePriceCol,
                    priceCol,
                    partRowDiscountCol
                ],
                lowerRow: [
                    detailNameCol,
                    commentCol,
                    supplierCol,
                    supplierBtn,
                    supplierPriceBtn,
                    AICol,
                    countCol,
                    unitsCol,
                    sumCol
                ],
                mobileRow: [
                    storeGroupCol,
                    vinCol,
                    detailNameCol,
                    commentCol,
                    brandCol,
                    detailCodeCol,
                    catalogueCol,
                    supplierCol,
                    supplierBtn,
                    priceCol,
                    countCol,
                    sumCol
                ]
            };
        };
    }

    getStorageProduct = async item => {
        const { agreement, detailCode, brandId, price, count } = item;
        if (!detailCode || !brandId) {
            this.changeDataSourceField({
                productId: null,
                supplierId: null
            });

            return;
        }

        const { list } = await fetchAPI(
            'GET',
            'store_products',
            {
                productCode: detailCode,
                brandId,
                pageSize: 15
            },
            null,
            {
                handleErrorInternally: true
            }
        );
        if (list.length) {
            this.changeDataSourceField({
                productId: list[0].id,
                supplierId: 0,
                purchasePrice: list[0].purchasePrice,
                price: agreement === 'AGREED' ? price : list[0].sellingPrice,
                sum: (agreement === 'AGREED' ? price : list[0].sellingPrice) * count,
                multiplier: _.get(list[0], 'priceGroup.multiplier', 1.7)
            });
        } else {
            this.changeDataSourceField({
                productId: null,
                supplierId: null
            });
        }
    };

    getDefaultValues = async storeGroupId => {
        if (!storeGroupId || !this.props.modificationId) {
            return;
        }
        const result = await fetchAPI(
            'GET',
            'store_groups/default_detail',
            {
                storeGroupId,
                modificationId: this.props.modificationId,
                excludePricelist: this.state.editing
            },
            null,
            { handleErrorInternally: true }
        );
        if (result) {
            const brands = this.props.brands.slice(0);
            brands.sort((x, y) => {
                return x.brandId === result.brandId ? -1 : y.brandId === result.brandId ? 1 : 0;
            });
            let fields = {};
            if (!this.state.editing) {
                fields = {
                    brandId: result.brandId,
                    brandName: result.brandName,
                    detailCode: result.partNumber,
                    price: Math.round(result.sellingPrice * 10) / 10,
                    count: 1
                };

                if (result.price) {
                    const {
                        id,
                        supplierBrandId,
                        partUnitId,
                        businessSupplierId,
                        businessSupplierName,
                        store,
                        purchasePrice,
                        supplierOriginalCode,
                        supplierProductNumber,
                        supplierPartNumber,
                        isFromStock,
                        defaultWarehouseId
                    } = result.price;
                    const markup = result.markup ? result.markup : 1.4;

                    fields = {
                        ...fields,
                        supplierBrandId,
                        supplierId: businessSupplierId,
                        supplierName: businessSupplierName,
                        productId: businessSupplierId === 0 ? id : undefined,
                        store,
                        purchasePrice: Math.round(purchasePrice * 10) / 10,
                        price:
                            Math.round(result.sellingPrice * 10) / 10 || Math.round(purchasePrice * markup * 10) / 10,
                        supplierOriginalCode,
                        supplierProductNumber,
                        supplierPartNumber,
                        isFromStock,
                        defaultWarehouseId,
                        partUnitId
                    };
                }
            }
            this.changeDataSourceField(fields, { defaultBrandName: result.brandName });
        }
    };

    getMarkupsSellingPrice = async (purchasePrice, storeGroupId, detailCode, supplierId, price, count, productId) => {
        const newSellingPrice = await fetchAPI(
            'GET',
            '/selling_price',
            {
                purchasePrice,
                storeGroupId: storeGroupId || null,
                clientId: this.props.clientId || null,
                code: detailCode || null,
                supplierId: supplierId || null,
                productId: productId || null
            },
            {
                handleErrorInternally: true
            }
        );
        await this.setState({
            newSellingPrice
        });

        await this.changeDataSourceField({
            price: this.state.newSellingPrice.sellingPrice,
            sum: price * count
        });
    };

    changeDataSourceField = (fields, additionalState = {}) => {
        this.setState(({ dataSource: ds }) => {
            const dataSource = ds;
            Object.entries(fields).forEach(([key, value]) => {
                if (dataSource && dataSource[0]) {
                    dataSource[0][key] = value;
                }
            });

            return {
                dataSource,
                ...additionalState
            };
        });
    };

    vinModalAction = ({ name, oem, storeGroupId }) => {
        const { dataSource } = this.state;
        const fields = {
            detailCode: oem,
            oeCode: _.get(this.state.dataSource, '[0].oeCode') ? _.get(this.state.dataSource, '[0].oeCode') : oem,
            storeGroupId
        };

        if (dataSource[0].agreement !== 'AGREED') {
            fields.detailName = name;
        }

        this.changeDataSourceField(fields);
    };

    setCode = item => {
        const { storeGroupId, partNumber, brandId, salePrice, itemName } = item;
        const { dataSource } = this.state;
        this.unsetSupplier();
        let fields = {
            detailCode: partNumber,
            brandId
        };
        if (dataSource[0].agreement !== 'AGREED') {
            fields = {
                ...fields,
                price: salePrice || dataSource[0].price,
                detailName: dataSource[0].detailName || itemName,
                storeGroupId
            };
        }
        this.changeDataSourceField(fields);
    };

    setSupplier = item => {
        const {
            businessSupplierId,
            supplierName,
            supplierBrandId,
            purchasePrice,
            salePrice,
            store,
            supplierOriginalCode,
            supplierProductNumber,
            supplierPartNumber,
            isFromStock,
            defaultWarehouseId,
            id,
            brandId,
            itemName,
            cellAddress,
            warehouseId
        } = item;
        const { dataSource } = this.state;
        const brand = this.props.brands.find(elem => elem.brandId === brandId);
        let fields = {
            supplierId: businessSupplierId,
            cellAddress,
            warehouseId,
            supplierName,
            supplierBrandId,
            purchasePrice,
            store,
            supplierOriginalCode,
            supplierProductNumber,
            supplierPartNumber,
            isFromStock,
            productId: businessSupplierId === 0 ? id : undefined
        };

        if (brand) {
            fields = { ...fields, brandId, brandName: brand && brand.brandName };
        }

        if (dataSource[0].agreement !== 'AGREED') {
            fields = {
                ...fields,
                price: salePrice || dataSource[0].price,
                detailName: dataSource[0].detailName || itemName
            };
        }

        this.changeDataSourceField(fields);
    };

    unsetSupplier = () => {
        this.changeDataSourceField({
            productId: undefined,
            isFromStock: false,
            supplierId: null,
            supplierName: undefined,
            supplierBrandId: undefined,
            supplierOriginalCode: undefined,
            supplierProductNumber: undefined,
            supplierPartNumber: undefined,
            store: undefined,
            cellAddress: undefined,
            warehouseId: undefined
        });
    };

    handleOk = async () => {
        this.setState({
            loading: true
        });
        const { orderId, updateDataSource, onFinish, product, defaultResponsibleId } = this.props;
        const { dataSource } = this.state;
        if (_.get(product, 'id')) {
            onFinish(dataSource[0]);
        } else {
            const data = {
                insertMode: true,
                details: []
            };
            dataSource.map(element => {
                // const productCode = element.detailCode
                //     .replace(/[^A-Za-z0-9\u0400-\u04FF]/gm, '')
                //     .toUpperCase();
                if (!element.productId) {
                    data.details.push({
                        storeGroupId: element.storeGroupId,
                        partUnitId: element.partUnitId,
                        name: element.detailName,
                        productCode: element.detailCode,
                        supplierId: element.supplierId,
                        supplierBrandId: element.supplierBrandId || element.brandId,
                        supplierOriginalCode: element.supplierOriginalCode,
                        supplierProductNumber: element.supplierProductNumber,
                        supplierPartNumber: element.supplierPartNumber,
                        purchasePrice: element.purchasePrice || 0,
                        count: element.count ? element.count : 1,
                        price: element.price ? element.price : 0,
                        partRowDiscount: element.partRowDiscount ? element.partRowDiscount : 0,
                        oeCode: element.oeCode || null,
                        responsibleId: defaultResponsibleId || undefined,
                        comment: element.comment || {
                            comment: undefined,
                            positions: []
                        }
                    });
                } else {
                    data.details.push({
                        storeGroupId: element.storeGroupId,
                        partUnitId: element.partUnitId || element.productUnitId,
                        name: element.detailName,
                        productId: element.productId,
                        productCode: element.detailCode,
                        supplierBrandId: element.supplierBrandId || element.brandId,
                        purchasePrice: element.purchasePrice || 0,
                        count: element.count ? element.count : 1,
                        price: element.price ? element.price : 0,
                        partRowDiscount: element.partRowDiscount ? element.partRowDiscount : 0,
                        supplierId: element.supplierId,
                        cellAddress: element.cellAddress,
                        warehouseId: element.warehouseId,
                        responsibleId: defaultResponsibleId || undefined,
                        oeCode: element.oeCode || null,
                        comment: element.comment || {
                            comment: undefined,
                            positions: []
                        }
                    });
                }
            });

            if (this.props.isRetailOrder) {
                const { order } = await fetchAPI('GET', `retail/${orderId}`, null, null);

                if (order?.status === 'success') {
                    window.location.reload();

                    return;
                }
            } else {
                const { status } = await fetchAPI('GET', 'orders/status', { orderId }, null);
                if (status === 'success') {
                    window.location.reload();

                    return;
                }
            }

            if (this.props.isRetailOrder) {
                await fetchAPI('PUT', `retail/${orderId}`, null, data, { handleErrorInternally: true });
            } else {
                await fetchAPI('PUT', `orders/${orderId}`, null, data, {
                    handleErrorInternally: true
                });
            }
            updateDataSource();
        }
        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            dataSource: [],
            catalogModalRow: undefined,
            loading: false,
            oilModalVisible: false
        });
        if (this.props.clearOilData) {
            this.props.clearOilData();
        }
        this.props.hideModal();
        this.props.handleSearchSuppliers();
    };

    deleteDetail = async () => {
        const { updateDataSource, product } = this.props;
        const id = _.get(this.state.dataSource, '[0].id', product.id);
        await fetchAPI('DELETE', 'orders/details', undefined, {
            ids: [id]
        });
        this.handleCancel();
        updateDataSource();
    };

    confirmDelete = () => {
        const { formatMessage } = this.props.intl;
        confirm({
            title: formatMessage({ id: 'add_order_form.delete_confirm' }),
            onOk: this.deleteDetail,
            okType: 'danger'
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const { visible, product, showOilModal, detailsDiscount, clientDiscount } = this.props;
        if (!prevProps.visible && this.props.visible) {
            window.addEventListener('keydown', this.handleKeyDown);
        } else if (prevProps.visible && !this.props.visible) {
            window.removeEventListener('keydown', this.handleKeyDown);
        }
        if (!prevProps.showOilModal && showOilModal) {
            this.setState({
                oilModalVisible: true
            });
        }
        if (!prevProps.visible && visible) {
            this.setState({
                dataSource: [
                    {
                        ...product,
                        key: 'OrderDetailModal-key0',
                        brandId: product.brandId == 0 ? null : product.brandId,
                        partRowDiscount: product.partRowDiscount || detailsDiscount || clientDiscount
                    }
                ],
                catalogModalRow: visible === 2 ? product : undefined
            });
        }
        if (!prevState.helperDrawerOpen && this.state.helperDrawerOpen && !this.state.allLinks.length) {
            this.fetchHelperLinks();
        }
    }

    handleKeyDown = e => {
        if (e.key === 'Enter' && this.props.visible) {
            this.handleOk();
        }
    };

    getMobileForm() {
        const { dataSource } = this.state;
        const data = dataSource[0] || {};
        const columns = this.columns();

        return columns.mobileRow.map(({ title, key, render, dataIndex }) => {
            // if ((key === 'purchasePrice' || key === 'AI' || key === 'comment', key === 'partUnitId')) {
            //     return;
            // }

            return (
                <div
                    key={key}
                    className={`mobileTable ${
                        (key === 'storeGroupId' ||
                            key === 'supplier' ||
                            key === 'detailName' ||
                            key === 'detailCode') &&
                        'mobileTableGroup'
                    } ${
                        (key === 'vin' ||
                            key === 'comment' ||
                            key === 'catalogueCol' ||
                            key === 'supplierBtn' ||
                            key === 'supplierPriceBtn') &&
                        'mobileTableVin'
                    } ${(key === 'price' || key === 'count') && 'mobileTableNumber'} ${
                        key === 'sum' && 'mobileTableSum'
                    }`}
                >
                    {title}
                    <div>{dataIndex ? render(data[dataIndex], data) : render(data)}</div>
                </div>
            );
        });
    }

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'add_detail_modal' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    render() {
        const {
            visible,
            isMobile,
            modificationId,
            user,
            brands,
            treeData,
            orderId,
            hideModal,
            suppliers,
            clientVehicleVin,
            selectedVehicle,
            defaultResponsibleId,
            product
        } = this.props;
        const {
            dataSource,
            catalogModalRow,
            oilModalVisible,
            warehousesModalSelectedRow,
            loading,
            helperDrawerOpen,
            allLinks,
            existingOECode,
            newSellingPrice
        } = this.state;

        const columns = this.columns();
        const mobileFooter = (
            <div>
                <span
                    style={{
                        float: 'left'
                    }}
                >
                    {product && product.id && (
                        <Upload
                            action={elem => {
                                this.props.downloadPhoto(elem, product.id, product.detailName);
                            }}
                            beforeUpload={file => {
                                const isPNG = file.type === 'image/png';
                                const isJPG = file.type === 'image/jpeg';
                                if (!isPNG && !isJPG) {
                                    notification.error({
                                        message: `${file.name} ${this.props.intl.formatMessage({
                                            id: 'photo_modal.is_not_an_image'
                                        })}!`
                                    });
                                }

                                return isPNG || isJPG;
                            }}
                            showUploadList={false}
                        >
                            <VideoCameraOutlined />
                        </Upload>
                    )}

                    <Button
                        data-qa='button_confirm_delete_order_detail_edit_modal'
                        onClick={() => this.confirmDelete()}
                        type='danger'
                    >
                        <DeleteOutlined />
                    </Button>
                </span>
                <Button data-qa='button_handle_cancel_order_detail_edit_modal' onClick={() => this.handleCancel()}>
                    <FormattedMessage id='cancel' />
                </Button>
                <Button
                    data-qa='button_handle_ok_order_detail_edit_modal'
                    loading={loading}
                    onClick={() => this.handleOk()}
                    type='primary'
                >
                    <FormattedMessage id='save' />
                </Button>
            </div>
        );

        return (
            <div>
                <Modal
                    okButtonProps={{ loading }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={Boolean(visible)}
                    style={{ minWidth: !isMobile ? 800 : '80%' }}
                    title={<div><FormattedMessage id='add_detail' />
                            <QuestionCircleOutlined
                                onClick={() => {
                                    this.setState({
                                        helperDrawerOpen: true
                                    });
                                }}
                                style={{
                                    fontSize: 22,
                                    marginLeft: 8
                                }}
                            />
                        </div>
                    }
                    width='90%'
                    wrapClassName='addDetailModal'
                    footer={isMobile ? mobileFooter : void 0}
                    // forceRender
                    maskClosable={false}
                >
                    {!isMobile ? (
                        <div className='tableWrap'>
                            <Table
                                className='ordDetailModalTable'
                                columns={columns.upperRow}
                                dataSource={dataSource}
                                pagination={false}
                                showHeader={false}
                                size='small'
                            />
                            <Table
                                className='ordDetailModalTable'
                                columns={columns.lowerRow}
                                dataSource={dataSource}
                                pagination={false}
                                showHeader={false}
                                size='small'
                            />
                        </div>
                    ) : (
                        // <div className='tableWrap'>
                        //     <Table
                        //         bordered
                        //         className='ordDetailModalTable'
                        //         columns={columns}
                        //         dataSource={dataSource}
                        //         pagination={false}
                        //         size='small'
                        //     />
                        // </div>
                        this.getMobileForm()
                    )}
                </Modal>

                <DetailCatalogueModal
                    brandId={_.get(dataSource, '[0].brandId')}
                    brands={brands}
                    clientId={this.props.clientId}
                    closeParentModal={() => {
                        if (visible === 2) hideModal();
                    }}
                    code={_.get(dataSource, '[0].detailCode')}
                    defaultResponsibleId={defaultResponsibleId}
                    existingOECode={_.get(dataSource, '[0].oeCode')}
                    hideModal={() => {
                        this.setState({
                            catalogModalRow: undefined
                        });
                    }}
                    modificationId={modificationId}
                    onSelect={({
                        name,
                        price,
                        productCode,
                        code,
                        purchasePrice,
                        supplierId,
                        supplierName,
                        productId,
                        supplierBrandId,
                        brandId,
                        partUnitId,
                        productUnitId,
                        store,
                        oeCode,
                        cellAddress,
                        storeGroupId,
                        warehouseId,
                        supplierOriginalCode,
                        supplierPartNumber
                    }) => {
                        const groupId =
                            dataSource[0].storeGroupId === 1000000 || !dataSource[0].storeGroupId
                                ? storeGroupId
                                : dataSource[0].storeGroupId || 1000000;
                        let fields = {
                            detailCode: productCode || code,
                            purchasePrice,
                            supplierId,
                            supplierName,
                            productId,
                            brandId:
                                supplierBrandId || brandId ? Number(supplierBrandId) || Number(brandId) : undefined,
                            supplierBrandId:
                                supplierBrandId || brandId ? Number(supplierBrandId) || Number(brandId) : undefined,
                            store,

                            partUnitId: partUnitId || productUnitId,
                            cellAddress,
                            warehouseId,
                            supplierOriginalCode,
                            supplierPartNumber,
                            oeCode
                        };
                        if (dataSource[0].agreement !== 'AGREED') {
                            fields = {
                                ...fields,
                                storeGroupId: groupId,
                                detailName: dataSource[0].detailName || name,
                                price
                            };
                        }
                        this.changeDataSourceField(fields);
                    }}
                    orderId={orderId}
                    partUnitId={_.get(dataSource, '[0].partUnitId')}
                    selectedVehicle={selectedVehicle}
                    setStoreGroupId={storeGroupId => {
                        if (dataSource[0].agreement !== 'AGREED') {
                            dataSource[0].storeGroupId = storeGroupId;
                        }
                    }}
                    setSupplier={this.setSupplier}
                    showOilModal={() => {
                        this.setState({
                            oilModalVisible: true,
                            catalogModalRow: undefined
                        });
                    }}
                    storeGroupId={_.get(dataSource, '[0].storeGroupId')}
                    suppliers={suppliers}
                    treeData={treeData}
                    updateDataSource={this.props.updateDataSource}
                    updateDetail={this.updateDetail}
                    user={user}
                    vin={clientVehicleVin}
                    visible={Boolean(catalogModalRow)}
                />
                <OilModal
                    brands={brands}
                    clearOilData={this.props.clearOilData}
                    disabled={isForbidden(user, permissions.ACCESS_ORDER_DETAILS_OILS)}
                    hideModal={() => {
                        this.setState({
                            oilModalVisible: undefined
                        });
                    }}
                    oilModalData={this.props.oilModalData}
                    onSelect={this.setCode}
                    setSupplier={this.setSupplier}
                    showOilModal={this.props.showOilModal}
                    // codeFilter={elem.detailCode ? String(elem.detailCode).replace(/[ \\|\//|'"*?.,:;+!#@%()<React.Fragment>{}-]/g,'') : undefined}
                    storeGroupId={_.get(dataSource, '[0].storeGroupId')}
                    tableKey={0}
                    tecdocId={modificationId}
                    user={user}
                    visible={oilModalVisible}
                />
                <DetailWarehousesCountModal
                    count={_.get(warehousesModalSelectedRow, 'count', 1)}
                    hideButton
                    hideModal={() => {
                        this.setState({
                            warehousesModalSelectedRow: undefined
                        });
                    }}
                    onSelect={(address, warehouseId, whName, purchasePrice, price) => {
                        dataSource[0].cellAddress = address;
                        dataSource[0].warehouseId = warehouseId;
                        dataSource[0].purchasePrice = purchasePrice;
                        dataSource[0].price = price;
                        this.setState({});
                    }}
                    orderId={orderId}
                    productId={_.get(warehousesModalSelectedRow, 'productId')}
                    visible={Boolean(warehousesModalSelectedRow)}
                />
                <Drawer
                    onClose={() => {
                        this.setState({
                            helperDrawerOpen: false
                        });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, helpId, ogTitle, ogDescription, ogImage }, index) => (
                            <div className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button
                                        style={{
                                            width: '100%'
                                        }}
                                        type='primary'
                                    >
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>
            </div>
        );
    }
}
export default OrderDetailModal;

@injectIntl
class CommentaryButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            currentCommentaryProps: {
                name: props.detail,
                positions: [],
                problems: []
            },
            currentCommentary: undefined
        };
        this.commentaryInput = React.createRef();
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this._isMounted = false;
    }

    showModal = () => {
        this.setState({
            currentCommentary: this.props.commentary.comment ? this.props.commentary.comment : this.props.detail,
            visible: true
        });
        if (this.commentaryInput.current != undefined) {
            this.commentaryInput.current.focus();
        }

        if (!this.props.commentary.comment) {
            this.setState({
                currentCommentaryProps: {
                    name: this.props.detail,
                    positions: [],
                    problems: []
                }
            });
        }
    };

    handleOk = async () => {
        const { currentCommentary, currentCommentaryProps } = this.state;
        this.setState({
            loading: true
        });
        this.props.setComment(currentCommentary, currentCommentaryProps.positions, currentCommentaryProps.problems);
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 500);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            currentCommentary: this.props.detail,
            currentCommentaryProps: {
                name: this.props.detail,
                positions: [],
                problems: []
            }
        });
    };

    renderHeader = () => {
        return (
            <div>
                <p>{this.props.detail}</p>
            </div>
        );
    };

    getCommentary() {
        const { currentCommentaryProps } = this.state;
        let currentCommentary = this.props.detail;

        if (currentCommentaryProps.positions.length) {
            currentCommentary += ' -';
            currentCommentary += `${currentCommentaryProps.positions.map(
                data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
            )};`;
        }
        this.setState({
            currentCommentary
        });
    }

    setCommentaryPosition(position) {
        const { currentCommentaryProps } = this.state;
        const positionIndex = currentCommentaryProps.positions.indexOf(position);
        if (positionIndex == -1) {
            currentCommentaryProps.positions.push(position);
        } else {
            currentCommentaryProps.positions = currentCommentaryProps.positions.filter(
                (value, index) => index != positionIndex
            );
        }
        this.getCommentary();
    }

    componentDidMount() {
        this._isMounted = true;
        const { commentary, detail } = this.props;
        if (this._isMounted) {
            this.setState({
                currentCommentaryProps: {
                    name: detail,
                    positions: commentary.positions || [],
                    problems: commentary.problems || []
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { TextArea } = Input;
        const { visible, loading, currentCommentaryProps, currentCommentary } = this.state;
        const { disabled, commentary } = this.props;
        const { positions } = this;

        return (
            <div>
                {commentary.comment ? (
                    <Button
                        className='commentaryButton'
                        data-qa='button_show_modal_edit_commentary_button_order_detail_edit_modal'
                        icon={<FormOutlined className='commentaryButtonIcon' />}
                        onClick={this.showModal}
                        title={this.props.intl.formatMessage({ id: 'commentary.edit' })}
                        type='primary'
                    />
                ) : (
                    <Tooltip placement='bottom' title={<FormattedMessage id='commentary.add' />} zIndex={2001}>
                        <Button
                            data-qa='button_show_modal_add_commentary_button_order_detail_edit_modal'
                            disabled={disabled}
                            icon={<MessageOutlined />}
                            onClick={this.showModal}
                        />
                    </Tooltip>
                )}
                <Modal
                    footer={
                        disabled
                            ? null
                            : [
                                  <Button
                                      key='back'
                                      data-qa='button_handle_cancel_commentary_button_order_detail_edit_modal'
                                      onClick={this.handleCancel}
                                  >
                                      <FormattedMessage id='cancel' />
                                  </Button>,
                                  <Button
                                      key='submit'
                                      data-qa='button_handle_ok_commentary_button_order_detail_edit_modal'
                                      loading={loading}
                                      onClick={this.handleOk}
                                      type='primary'
                                  >
                                      <FormattedMessage id='save' />
                                  </Button>
                              ]
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='commentary_modal.comment' />}
                    /* title={this.renderHeader()} */
                    visible={visible}
                >
                    <React.Fragment>
                        <div className='commentaryVehicleSchemeWrap'>
                            <p className='commentarySectionHeader'>
                                <FormattedMessage id='commentary_modal.where' />
                            </p>
                            <div className='blockButtonsWrap'>
                                {positions.map((position, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            className='commentaryBlockButton'
                                            data-qa='button_position_commentary_button_order_detail_edit_modal'
                                            onClick={() => {
                                                this.setCommentaryPosition(position);
                                            }}
                                            type={
                                                currentCommentaryProps.positions.findIndex(elem => position == elem) ===
                                                -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <FormattedMessage id={position} />
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <p className='commentarySectionHeader'>
                                <FormattedMessage id='order_form_table.diagnostic.commentary' />
                            </p>
                            <TextArea
                                ref={this.commentaryInput}
                                autoFocus
                                data-qa='text_area_currentCommentary_commentary_button_order_detail_edit_modal'
                                disabled={disabled}
                                onChange={() => {
                                    this.setState({
                                        currentCommentary: event.target.value
                                    });
                                }}
                                placeholder={`${this.props.intl.formatMessage({
                                    id: 'comment'
                                })}...`}
                                style={{ width: '100%', minHeight: '150px', resize: 'none' }}
                                value={currentCommentary}
                            />
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}

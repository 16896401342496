import { SalariesDocumentContainer } from 'containers';
import {
    ACCDocumentsPage,
    AccountPlanPage,
    AddEmployeePage,
    AddInteractionPage,
    AddOrderPage,
    AggregatePage,
    AggregatesPage,
    AgreementPage,
    ApiSettingsPage,
    ApiTelephonyPage,
    AvailabilitiesPage,
    BarcodePage,
    BrandsPage,
    BusinessPackagePage,
    CallsJournalPage,
    CallsPage,
    CarshopDirectories,
    CashBankPage,
    CashFlowPage,
    CashSettingsPage,
    ChartPage,
    ClientHotOperationsPage,
    ClientPage,
    CloseDebtPage,
    ComplexesPage,
    CrewsPage,
    CrossesPage,
    DashboardPage,
    DebtMovementPage,
    DetailStatusSettingsPage,
    DiagnosticPatternsPage,
    DiagnosticsDirectoryPage,
    DirectoriesJournalPage,
    DirectoriesPage,
    DirectoryBrandsPage,
    EditEmployeePage,
    EditMakesPage,
    EmployeesPage,
    EntriesJournalPage,
    ExceptionPage,
    ExchangeRatePage,
    ExpensesPage,
    GallaryPage,
    GraphicReportsPage,
    IncomesPage,
    InteractionsDirectoriesPage,
    InteractionsPage,
    JournalVATPage,
    LaborsPage,
    LocationSettingsPage,
    LocationsDocumentPage,
    LocationsMovementPage,
    LocationsPage,
    LocationsVehiclesPage,
    ManagerReportsPage,
    ManagerRolePage,
    MarkupsPage,
    MyNormativePage,
    NewDocumentPage,
    OnlineServiceBookPage,
    OrderCataloguePage,
    OrderPage,
    OrdersExpensesPage,
    OrdersKanbanPage,
    OrdersPage,
    PackagePage,
    PaymentPage,
    PricelistsPage,
    ProductPage,
    ProductsGroupsPage,
    ProductsPage,
    ProductsTrackingPage,
    ProductsWithoutCellPage,
    ProfilePage,
    QuickRequestsPage,
    ReceivablesAndPayablesPage,
    RefactoredRightsPage,
    ReferenceBookPage,
    RegulationsPage,
    RepairMapSettingPage,
    ReportAnalyticsPage,
    ReportCashFlowPage,
    ReportCashOrdersLogsPage,
    ReportLoadKPIPage,
    ReportOrdersPage,
    ReportTemplateEditor,
    ReportsPage,
    RequisiteSettingPage,
    RetailCheckPage,
    RetailsPage,
    ReviewPage,
    ReviewsPage,
    RolePage,
    SMSSettingsPage,
    SalaryDocumentPage,
    ScmAbcReportsPage,
    ServicesPage,
    SettingsDirectoriesPage,
    SettingsPage,
    SourcePage,
    SparePartsWorkplacePage,
    StorageBalancePage,
    StorageCalculationsPage,
    StorageDocumentPage,
    StorageJournalPage,
    StorageMovementPage,
    StorageOrdersPage,
    StorageTransferPage,
    SubscriptionHistoryPage,
    SubscriptionPackagesPage,
    SupplierPage,
    SuppliersPage,
    SyncExportPage,
    SyncImportPage,
    TaskPage,
    TasksPage,
    UnitsPage,
    UserAgreementPage,
    UserFeedbackPage,
    VehiclePage,
    VehiclesPage,
    WMSPage,
    WarehousesPage,
    WaybillsPage,
    WebSettingsPage
} from 'pages';
import AccPage from 'pages/AccPage';
import HistoryTrackingPage from 'pages/HistoryTrackingPage';
import MonetizationPage from 'pages/MonetizationPage';
import { RTL, RTN } from 'pages/RetailsPage/constants';
import ThemeSettingsPage from 'pages/ThemeSettingsPage';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import book from './book';

/**
 * Those components can be accessed by authentificated users only
 */
export default () => {
    return (
        <Switch>
            {/* Operations */}
            <Route exact path={book.newDocumentPage} render={props => <NewDocumentPage {...props} />} />
            <Route exact path={book.dashboard} render={props => <DashboardPage {...props} />} />
            <Route exact path={book.ordersByStatuses} render={props => <OrdersPage {...props} />} />
            <Route exact path={book.orderId} render={props => <OrderPage key={props.match.params.id} {...props} />} />
            <Route
                exact
                path={book.rtlDoc}
                render={props => <RetailCheckPage key={props.match.params.id} {...props} type={RTL} />}
            />
            <Route
                exact
                path={book.rtnDoc}
                render={props => <RetailCheckPage key={props.match.params.id} {...props} type={RTN} />}
            />
            <Route exact path={book.rtl} render={props => <RetailsPage {...props} type={RTL} />} />
            <Route exact path={book.rtn} render={props => <RetailsPage {...props} type={RTN} />} />
            <Route exact path={book.addOrder} render={props => <AddOrderPage {...props} />} />
            <Route exact path={book.myTasksPage} render={props => <TasksPage {...props} />} />
            <Route exact path={book.ordersKanban} render={props => <OrdersKanbanPage {...props} />} />
            <Route exact path={book.myTaskPage} render={props => <TaskPage {...props} />} />
            <Route exact path={book.barcodePage} render={props => <BarcodePage {...props} />} />
            <Route exact path={book.sparePartsWorkplacePage} render={props => <SparePartsWorkplacePage {...props} />} />
            <Route exact path={book.orderCatalogue} render={props => <OrderCataloguePage {...props} />} />
            {/* Reference book */}
            <Route exact path={book.directoriesPage} render={props => <DirectoriesPage {...props} />} />
            <Route exact path={book.carshop} render={props => <CarshopDirectories {...props} />} />
            <Route exact path={book.settingsDirectoriesPage} render={props => <SettingsDirectoriesPage {...props} />} />
            <Route exact path={book.apiTelephony} render={props => <ApiTelephonyPage {...props} />} />
            <Route exact path={book.journalsPage} render={props => <DirectoriesJournalPage {...props} />} />
            <Route exact path={book.clientId} render={props => <ClientPage {...props} />} />
            <Route exact path={book.debtMovement} render={props => <DebtMovementPage {...props} />} />
            <Route exact path={book.closeDebt} render={props => <CloseDebtPage {...props} />} />
            <Route exact path={book.employeesPage} render={props => <EmployeesPage {...props} />} />
            <Route exact path={book.crewsPage} render={props => <CrewsPage {...props} />} />
            <Route exact path={book.addEmployee} render={props => <AddEmployeePage {...props} />} />
            <Route exact path={book.editEmployee} render={props => <EditEmployeePage {...props} />} />
            <Route exact path={book.suppliersPage} render={props => <SuppliersPage {...props} />} />
            <Route exact path={book.laborsPage} render={props => <LaborsPage {...props} />} />
            <Route exact path={book.myNormative} render={props => <MyNormativePage {...props} />} />
            <Route exact path={book.complexes} render={props => <ComplexesPage {...props} />} />
            <Route exact path={book.warehouses} render={props => <WarehousesPage {...props} />} />
            <Route exact path={book.units} render={props => <UnitsPage {...props} />} />
            <Route exact path={book.quick_requests} render={props => <QuickRequestsPage {...props} />} />
            <Route exact path={book.diagnosticPatterns} render={props => <DiagnosticPatternsPage {...props} />} />
            <Route exact path={book.diagnosticsDirectory} render={props => <DiagnosticsDirectoryPage {...props} />} />
            <Route exact path={book.intDirectories} render={props => <InteractionsDirectoriesPage {...props} />} />
            <Route exact path={book.interactions} render={props => <InteractionsPage {...props} />} />
            <Route exact path={book.addInteraction} render={props => <AddInteractionPage {...props} />} />
            <Route exact path={book.interactionId} render={props => <AddInteractionPage {...props} />} />
            <Route
                exact
                path={book.supplierPage}
                render={props => <SupplierPage id={props.match.params.id} {...props} />}
            />
            <Route exact path={book.crosses} render={props => <CrossesPage {...props} />} />
            {/* Cash */}
            <Route exact path={book.cashSettingsPage} render={props => <CashSettingsPage {...props} />} />
            <Route exact path={book.cashFlowPage} render={props => <CashFlowPage {...props} />} />
            <Route exact path={book.accDocs} render={props => <ACCDocumentsPage {...props} />} />
            <Route exact path={book.accDocId} render={props => <AccPage {...props} />} />
            <Route
                exact
                path={book.receivablesAndPayablesPage}
                render={props => <ReceivablesAndPayablesPage {...props} />}
            />
            <Route exact path={book.clientHotOperations} render={props => <ClientHotOperationsPage {...props} />} />
            <Route exact path={book.cashBankPage} render={props => <CashBankPage {...props} />} />
            {/* Storage */}
            <Route exact path={book.productsGroups} render={props => <ProductsGroupsPage {...props} />} />
            {/* <Route exact path={book.priceGroups} render={props => <PriceGroupsPage {...props} />} /> */}
            <Route exact path={book.priceGroups} render={props => <MarkupsPage {...props} />} />
            <Route exact path={book.products} render={props => <ProductsPage {...props} />} />
            <Route
                exact
                path={book.productPage}
                render={props => <ProductPage id={props.match.params.id} {...props} />}
            />
            <Route exact path={book.exchangeRate} render={props => <ExchangeRatePage {...props} />} />
            <Route exact path={book.storageOrders} render={props => <StorageOrdersPage {...props} />} />
            <Route exact path={book.storageIncomes} render={props => <IncomesPage {...props} />} />
            <Route exact path={book.storageExpenses} render={props => <ExpensesPage {...props} />} />
            <Route exact path={book.storageTransfers} render={props => <StorageTransferPage {...props} />} />
            <Route exact path={book.storageBalance} render={props => <StorageBalancePage {...props} />} />
            <Route exact path={book.storageMovement} render={props => <StorageMovementPage {...props} />} />
            <Route exact path={book.storageJournal} render={props => <StorageJournalPage {...props} />} />
            <Route exact path={book.storageCalculations} render={props => <StorageCalculationsPage {...props} />} />
            {/* <Route exact path={book.storageFunds} render={props => <StorageFundsPage {...props} />} /> */}
            <Route exact path={book.journalVAT} render={props => <JournalVATPage {...props} />} />
            <Route exact path={book.journalEntries} render={props => <EntriesJournalPage {...props} />} />
            <Route exact path={book.callsJournal} render={props => <CallsJournalPage {...props} />} />
            <Route exact path={book.salariesPage} render={props => <SalariesDocumentContainer {...props} />} />
            <Route exact path={book.storageDocument} render={props => <StorageDocumentPage {...props} />} />
            <Route
                exact
                path={book.storageDocumentId}
                render={props => <StorageDocumentPage id={props.match.params.id} {...props} />}
            />
            <Route
                exact
                path={book.productsTracking}
                render={props => <ProductsTrackingPage type={props.location.type} {...props} />}
            />
            <Route exact path={book.repairMapSetting} render={props => <RepairMapSettingPage {...props} />} />
            <Route exact path={book.wms} render={props => <WMSPage {...props} />} />
            <Route exact path={book.vehicleId} render={props => <VehiclePage {...props} />} />
            <Route exact path={book.aggregateId} render={props => <AggregatePage {...props} />} />
            <Route exact path={book.salaryDocumentPageId} render={props => <SalaryDocumentPage {...props} />} />
            <Route exact path={book.brands} render={props => <DirectoryBrandsPage {...props} />} />
            <Route exact path={book.vehicles} render={props => <VehiclesPage {...props} />} />
            <Route exact path={book.aggregatesPage} render={props => <AggregatesPage {...props} />} />
            <Route exact path={book.onlineServiceBookWithHash} render={props => <OnlineServiceBookPage {...props} />} />
            <Route exact path={book.productsWithoutCell} render={props => <ProductsWithoutCellPage {...props} />} />
            <Route exact path={book.waybillsPage} render={props => <WaybillsPage {...props} />} />
            <Route exact path={book.storageOrdersExpenses} render={props => <OrdersExpensesPage {...props} />} />
            {/* Locations */}
            <Route exact path={book.locationsPage} render={props => <LocationsPage {...props} />} />
            <Route exact path={book.locationSettings} render={props => <LocationSettingsPage {...props} />} />
            <Route exact path={book.locationsDocument} render={props => <LocationsDocumentPage {...props} />} />
            <Route exact path={book.locationsVehicles} render={props => <LocationsVehiclesPage {...props} />} />
            <Route exact path={book.locationsMovement} render={props => <LocationsMovementPage {...props} />} />

            {/* Statistics and reports */}
            <Route exact path={book.reportsPage} render={props => <ReportsPage {...props} />} />
            <Route exact path={book.chart} render={props => <ChartPage {...props} />} />
            <Route exact path={book.feedback} render={props => <ReviewsPage {...props} />} />
            <Route exact path={book.feedbackId} render={props => <ReviewPage {...props} />} />
            <Route exact path={book.calls} render={props => <CallsPage {...props} />} />
            <Route exact path={book.analytics} render={props => <ReportAnalyticsPage {...props} />} />
            <Route exact path={book.reportOrders} render={props => <ReportOrdersPage {...props} />} />
            <Route exact path={book.reportLoadKPI} render={props => <ReportLoadKPIPage {...props} />} />
            <Route exact path={book.reportCashFlow} render={props => <ReportCashFlowPage {...props} />} />
            <Route exact path={book.reportCashOrdersLogs} render={props => <ReportCashOrdersLogsPage {...props} />} />
            <Route exact path={book.accountPlan} render={props => <AccountPlanPage {...props} />} />
            <Route exact path={book.reportGraphic} render={props => <GraphicReportsPage {...props} />} />
            <Route exact path={book.managerReports} render={props => <ManagerReportsPage {...props} />} />
            <Route exact path={book.reportTemplateEditor} render={props => <ReportTemplateEditor {...props} />} />
            <Route exact path={book.abcReportsPage} render={props => <ScmAbcReportsPage {...props} />} />

            {/* Payment */}
            <Route exact path={book.paymentPage} render={props => <PaymentPage {...props} />} />
            <Route exact path={book.subscriptionHistoryPage} render={props => <SubscriptionHistoryPage {...props} />} />
            <Route
                exact
                path={book.subscriptionPackagesPage}
                render={props => <SubscriptionPackagesPage {...props} />}
            />
            <Route exact path={book.referenceBookPage} render={props => <ReferenceBookPage {...props} />} />
            {/* Settings */}
            <Route exact path={book.settings} render={props => <SettingsPage {...props} />} />
            <Route exact path={book.webSettings} render={props => <WebSettingsPage {...props} />} />
            <Route exact path={book.requisites} render={props => <RequisiteSettingPage {...props} />} />
            <Route exact path={book.smsSettings} render={props => <SMSSettingsPage {...props} />} />
            <Route exact path={book.detailStatusSettings} render={props => <DetailStatusSettingsPage {...props} />} />
            <Route exact path={book.apiSettings} render={props => <ApiSettingsPage {...props} />} />
            <Route exact path={book.regulations} render={props => <RegulationsPage {...props} />} />
            <Route exact path={book.source} render={props => <SourcePage {...props} />} />
            <Route exact path={book.themeSettings} render={props => <ThemeSettingsPage {...props} />} />
            {/* Roles */}
            <Route exact path={book.packagePage} render={props => <PackagePage {...props} />} />
            <Route exact path={book.rolePage} render={props => <RolePage {...props} />} />
            <Route exact path={book.businessPackagePage} render={props => <BusinessPackagePage {...props} />} />
            <Route exact path={book.managerRolePage} render={props => <ManagerRolePage {...props} />} />
            {/* Administration */}
            <Route exact path={book.servicesPage} render={props => <ServicesPage {...props} />} />
            <Route exact path={book.brandsPage} render={props => <BrandsPage {...props} />} />
            <Route exact path={book.availabilitiesPage} render={props => <AvailabilitiesPage {...props} />} />
            <Route exact path={book.pricelistsPage} render={props => <PricelistsPage {...props} />} />
            {/* Global */}
            <Route exact path={book.profile} render={props => <ProfilePage {...props} />} />
            <Route exact path={book.userAgreement} render={props => <UserAgreementPage {...props} />} />
            <Route path={book.exceptionStatusCode} render={props => <ExceptionPage {...props} />} />
            <Route exact path={book.agreement} render={props => <AgreementPage {...props} />} />
            <Route exact path={book.syncImportPage} render={props => <SyncImportPage {...props} />} />
            <Route exact path={book.syncExportPage} render={props => <SyncExportPage {...props} />} />
            <Route exact path={book.editMakes} render={props => <EditMakesPage {...props} />} />
            <Route exact path={book.rights} render={props => <RefactoredRightsPage {...props} />} />
            <Route exact path={book.monetization} render={props => <MonetizationPage {...props} />} />
            <Route exact path={book.historyTrackingPage} render={props => <HistoryTrackingPage {...props} />} />
            <Route exact path={book.userFeedback} render={props => <UserFeedbackPage {...props} />} />
            <Route exact path={book.gallaryPage} render={props => <GallaryPage {...props} />} />
            <Route exact path='/' render={() => <Redirect to={book.ordersAppointments} />} />
            <Route path={`${book.exception}/404`} render={() => <Redirect to={`${book.exception}/404`} />} />
            <Route exact path='*' render={() => <Redirect to={book.dashboard} />} />
        </Switch>
    );
};

import { Form } from '@ant-design/compatible';
import { Button, Checkbox, Col, InputNumber, Modal, Row, Select } from 'antd';
import { resetModal, selectModal } from 'core/modals/duck';
import { createSupplier } from 'core/suppliers/duck';
import JsBarcode from 'jsbarcode'; // https://github.com/lindell/JsBarcode/wiki/Options
import _ from 'lodash';
import React, { Component } from 'react';
import ReactBarcode from 'react-barcode';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { getCurrency } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modal: selectModal(state)
});

const mapDispatchToProps = {
    createSupplier,
    resetModal
};

const { Option } = Select;

function getLastPart(str) {
    const parts = str.split('-');

    return parts[parts.length - 1];
}

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class NewBarcodePrintModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resolution: '60x40',
            cf: 2.9,
            barcodeFontSize: 12,
            displayBarcode: true
        };

        this.id = _.uniqueId('barcode-');

        this.componentRef = React.createRef();

        this.defaultModalBarcodeOptions = () => ({
            // format: "EAN13",
            lineColor: '#000',
            background: '#ffffff',
            width: 3,
            height: 30,
            fontSize: 14,
            fontOptions: '',
            textAlign: 'center',
            textPosition: 'bottom',
            textMargin: 2,
            margin: 2,
            // flat: true,
            displayValue: false
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible && this.props.visible && this.props.code) {
            this.updateBarcode();
        }
    }

    updateBarcode = () => {
        const { code } = this.props;
        const { id } = this;

        try {
            JsBarcode(`#${id}`, code, this.defaultModalBarcodeOptions());
        } catch (e) {}
    };

    render() {
        const {
            visible,
            hideModal,
            product: pr,
            printProductsData,
            intl: { formatMessage },
            empData,
            code: propsCode
        } = this.props;
        const product = pr || {};
        const products = printProductsData || [product];
        const { resolution, code, name, price, surname, position, cf, fullSize, barcodeFontSize, displayBarcode } =
            this.state;

        const width = fullSize ? '100%' : parseInt(resolution.split('x')[0], 10) * cf;
        const height = fullSize ? '100%' : parseInt(resolution.split('x')[1], 10) * cf;
        const originalWidth = fullSize ? '100%' : parseInt(resolution.split('x')[0], 10);
        const originalHeight = fullSize ? '100%' : parseInt(resolution.split('x')[1], 10);

        const barcodeOptions = this.defaultModalBarcodeOptions();

        return (
            <Modal
                cancelText={<FormattedMessage id='cancel' />}
                destroyOnClose
                footer={
                    <ReactToPrint
                        content={() => this.componentRef.current}
                        pageStyle={`@media print {
                                        html, body {
                                            height: auto !important;
                                            margin: 0 !important;
                                            padding: 0 !important;
                                            overflow: hidden;
                                        }
                                        @page {
                                            size: auto !important;
                                            margin: 0 !important;
                                        }
                                    }
                                    @media print and (orientation: landscape) {
                                        @page {
                                            margin: 0 !important;
                                        }
                                    }    
                                    `}
                        trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.

                            return (
                                <div>
                                    <Button
                                        // onClick={hideModal}
                                        type='primary'
                                    >
                                        <FormattedMessage id='print' />
                                    </Button>
                                </div>
                            );
                        }}
                    />
                }
                maskClosable={false}
                onCancel={hideModal}
                open={visible}
                title={<FormattedMessage id='barcode.print_settings' />}
            >
                <Form>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='barcode.format' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Select
                                onChange={async resolution => {
                                    await this.setState({ resolution });
                                    this.updateBarcode();
                                }}
                                value={resolution}
                            >
                                <Option value='40x25'>40x25</Option>
                                <Option value='60x20'>60x20</Option>
                                <Option value='60x30'>60x30</Option>
                                <Option value='60x40'>60x40</Option>
                                <Option value='60x50'>60x50</Option>
                                <Option value='60x60'>60x60</Option>
                                <Option value='122x30'>122x30</Option>
                                <Option value='122x60'>122x60</Option>
                            </Select>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    {/* <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='100%' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Checkbox checked={fullSize} onChange={() => this.setState({ fullSize: !fullSize })} />
                        </Col>
                        <Col span={6}></Col>
                    </Row> */}
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='barcode.multiplier' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <InputNumber
                                disabled={fullSize}
                                max={10}
                                min={1}
                                onChange={async cf => {
                                    await this.setState({ cf });
                                    this.updateBarcode();
                                }}
                                step={0.1}
                                value={cf}
                            />
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='barcode.font_size' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <InputNumber
                                onChange={async barcodeFontSize => {
                                    await this.setState({ barcodeFontSize });
                                    this.updateBarcode();
                                }}
                                value={barcodeFontSize}
                            />
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row className={Styles.row}>
                        <Col span={6}>
                            {' '}
                            <div className={Styles.colText}>
                                <FormattedMessage id='barcode.show' />
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={Styles.colText}>
                                <FormattedMessage id='navigation.barcode' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <Checkbox
                                checked={displayBarcode}
                                onChange={() => this.setState({ displayBarcode: !displayBarcode })}
                            />
                        </Col>
                    </Row>
                    {!empData && (
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.product_code' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={code} onChange={() => this.setState({ code: !code })} />
                            </Col>
                        </Row>
                    )}
                    {!empData && (
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.name' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={name} onChange={() => this.setState({ name: !name })} />
                            </Col>
                        </Row>
                    )}
                    {!empData && (
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='storage_document.sell_price' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={price} onChange={() => this.setState({ price: !price })} />
                            </Col>
                        </Row>
                    )}

                    {empData && (
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.name_surname' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={surname} onChange={() => this.setState({ surname: !surname })} />
                            </Col>
                        </Row>
                    )}
                    {empData && (
                        <Row className={Styles.row}>
                            <Col span={6}></Col>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='barcode.position' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Checkbox checked={position} onChange={() => this.setState({ position: !position })} />
                            </Col>
                        </Row>
                    )}
                    <Row style={{ transform: 'scale(1.3) translate(56px, 11px)' }}>
                        <div ref={this.componentRef}>
                            {products.map((product, ind) => (
                                <div
                                    key={product.id}
                                    className={Styles.barcode}
                                    style={{
                                        overflow: 'hidden',
                                        border: '1px solid black',
                                        display: !ind ? 'flex' : 'none',
                                        // width: width + 32,
                                        // maxWidth: width + 32,
                                        // height: height + 32,
                                        // maxHeight: height,
                                        margin: 0,
                                        padding: 0,
                                        justifyContent: 'space-between',
                                        flexDirection: 'column',

                                        width: `${originalWidth}mm`,
                                        height: `${originalHeight}mm`
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: 2
                                        }}
                                    >
                                        {price && (
                                            <div
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: barcodeFontSize + 2,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {product
                                                    ? (
                                                          _.get(product, 'purchasePrice') ||
                                                          _.get(product, 'sellingPrice', 0)
                                                      )?.toFixed(2)
                                                    : price}{' '}
                                                {getCurrency()}
                                            </div>
                                        )}
                                        {name && (
                                            <div style={{ fontWeight: 400, fontSize: barcodeFontSize }}>
                                                {product ? product.name || product.product?.name : name}
                                            </div>
                                        )}
                                        {code && (
                                            <div
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: barcodeFontSize,
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {product ? product.code || product.product?.code : code}
                                            </div>
                                        )}
                                        {surname && (
                                            <div style={{ fontWeight: 400, fontSize: barcodeFontSize }}>
                                                {empData.name} {empData.surname}
                                            </div>
                                        )}
                                        {position && (
                                            <div style={{ fontWeight: 400, fontSize: barcodeFontSize }}>
                                                {formatMessage({
                                                    id: `employee_salary_rules.${_.get(empData.posts, '[0].postName')}`
                                                })}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {displayBarcode && (
                                            <div
                                                style={{
                                                    // borderTop: '1px solid black',
                                                    // padding: 4
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {(printProductsData || []).length ? (
                                                    product.barcode &&
                                                    getLastPart(product.barcode) && (
                                                        <ReactBarcode
                                                            // height={height * 0.5 * window.devicePixelRatio}
                                                            renderer='canvas'
                                                            style={{
                                                                width,
                                                                height: height * 0.5
                                                            }}
                                                            value={
                                                                product.barcode ? getLastPart(product.barcode) : null
                                                            }
                                                            // width={width * window.devicePixelRatio}

                                                            {...barcodeOptions}
                                                            width={2}
                                                        />
                                                    )
                                                ) : (
                                                    // <canvas
                                                    //     height={height * 0.5 * window.devicePixelRatio}
                                                    //     id={this.id}
                                                    //     style={{
                                                    //         width,
                                                    //         height: height * 0.5
                                                    //     }}
                                                    //     width={width * window.devicePixelRatio} // Crisp rendering
                                                    // ></canvas>
                                                    <ReactBarcode
                                                        // height={height * 0.5 * window.devicePixelRatio}
                                                        renderer='canvas'
                                                        style={{
                                                            width,
                                                            height: height * 0.5
                                                        }}
                                                        value={(propsCode && getLastPart(propsCode)) || propsCode}
                                                        // width={width * window.devicePixelRatio}

                                                        {...barcodeOptions}
                                                        width={2}
                                                    />
                                                )}
                                            </div>
                                        )}

                                        {/* <div style={{ fontSize: barcodeFontSize, textAlign: 'center', padding: 0 }}>
                                    {propsCode}
                                </div> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

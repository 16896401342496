/* eslint-disable func-names */
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Spin, Table, Tooltip, TreeSelect, notification } from 'antd';
import { Numeral } from 'commons';
import { AvailabilityIndicator } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DeliveryTruckIcon } from 'theme';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';

const { TreeNode } = TreeSelect;
const spinIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;

@injectIntl
class DetailSupplierModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            visible: false,
            dataSource: []
        };
        this.columns = [
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'code',
                dataIndex: 'partNumber',
                width: '10%',
                sorter: (a, b) => a.partNumber.localeCompare(b.partNumber),
                sortDirections: ['descend', 'ascend']
            },
            {
                title: <FormattedMessage id='order_form_table.supplier_code' />,
                key: 'supplierCode',
                dataIndex: 'supplierPartNumber',
                width: '10%'
            },
            {
                title: <FormattedMessage id='order_form_table.detail_name' />,
                key: 'name',
                dataIndex: 'itemName',
                width: '15%'
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brand',
                dataIndex: 'brandName',
                width: '10%',
                sorter: (a, b) => a.brandName.localeCompare(b.brandName),
                sortDirections: ['descend', 'ascend']
            },
            {
                title: <FormattedMessage id='order_form_table.supplier' />,
                key: 'supplier',
                dataIndex: 'businessSupplierName',
                width: '10%'
            },
            {
                title: <FormattedMessage id='date' />,
                key: 'date',
                dataIndex: 'pricelistDate',
                width: '10%',
                render: date => `${dayjs(date).format('YYYY-MM-DD')}`
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                dataIndex: 'purchasePrice',
                width: '10%',
                render: (data, row) => {
                    const strVal = String(Math.round(row.purchasePrice * 10) / 10);
                    const currentSup = this.state.updatedData
                        ? this.state.updatedData.filter(({ supplier }) => supplier == row.businessSupplierName)
                        : undefined;
                    const checkedPrice = _.get(currentSup, '[0].price')
                        ? Number(_.get(currentSup, '[0].price'))
                        : strVal;

                    return (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <Numeral mask='0,0.00'>{Number(checkedPrice)}</Numeral>
                        </span>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.updated_price_API' />,
                key: 'indicatorPrice',
                align: 'center',
                width: 'auto%',
                render: (data, row) => {
                    const currentSup = this.state.updatedData
                        ? this.state.updatedData.filter(({ supplier }) => supplier == row.businessSupplierName)
                        : undefined;

                    const checkIconColor = this.props.user.palette?.enabled || 'green';
                    const closeIconColor = this.props.user.palette?.disabled || '#FF7E7E';

                    return _.get(currentSup, '[0].price') ? (
                        <CheckCircleOutlined
                            style={{
                                color: checkIconColor,
                                fontSize: 24
                            }}
                        />
                    ) : (
                        <CloseCircleOutlined
                            style={{
                                color: closeIconColor,
                                fontSize: 24
                            }}
                        />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'price',
                width: '10%',
                sorter: (a, b) => Number(a.sellingPrice) - Number(b.sellingPrice),
                sortDirections: ['descend', 'ascend'],
                render: (data, elem) => {
                    const currentSup = this.state.updatedData
                        ? this.state.updatedData.filter(({ supplier }) => supplier == elem.businessSupplierName)
                        : undefined;
                    const price = Number(elem.sellingPrice);
                    const strVal = String(Math.round(price * 10) / 10);
                    const checkedPrice = _.get(currentSup, '[0].price')
                        ? Number(_.get(currentSup, '[0].price')) * Number(elem.markup)
                        : strVal;

                    return (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <Numeral mask='0,0.00'>{Number(checkedPrice)}</Numeral>
                        </span>
                    );
                }
            },

            {
                title: (
                    <div title={this.props.intl.formatMessage({ id: 'order_form_table.AI_title' })}>
                        <FormattedMessage id='order_form_table.AI' />
                    </div>
                ),
                key: 'store',
                width: '10%',
                sorter: (a, b) => Number(a.availableIn0) - Number(b.availableIn0),
                sortDirections: ['descend', 'ascend'],
                render: elem => {
                    return (
                        <AvailabilityIndicator
                            indexArray={[elem.availableIn0, elem.availableIn1, elem.availableIn2, elem.availableIn3]}
                        />
                    );
                }
            },
            {
                key: 'select',
                width: 'auto',
                render: (data, elem) => {
                    const currentSup = this.state.updatedData
                        ? this.state.updatedData.filter(({ supplier }) => supplier == elem.businessSupplierName)
                        : undefined;
                    const markup = elem.markup ? elem.markup : 1.4;
                    const price = Number(elem.sellingPrice);
                    const checkedPrice = _.get(currentSup, '[0].price')
                        ? Number(_.get(currentSup, '[0].price')) * Number(markup)
                        : price;

                    const store = [elem.availableIn0, elem.availableIn1, elem.availableIn2, elem.availableInx];

                    const selectedPrice =
                        _.get(this.state.productInfo, 'getSellingPriceFrom') === 'FIXED'
                            ? _.get(this.state.productInfo, 'fixedSellingPrice')
                            : checkedPrice || price;

                    if (this.props.detailSearchSelectButtons) {
                        const itm = {
                            ...elem,
                            purchasePrice: _.get(currentSup, '[0].price') || elem.purchasePrice,
                            price: checkedPrice || price,
                            store,
                            key: this.props.tableKey,
                            name: elem.itemName
                        };

                        return (
                            <Space.Compact>
                                <Button
                                    onClick={() => {
                                        this.props.orderSelectFunc(itm);
                                        this.handleCancel();
                                    }}
                                >
                                    <FormattedMessage id='order_shr' />
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.props.storeDocSelectFunc(itm);
                                        this.handleCancel();
                                    }}
                                >
                                    <FormattedMessage id='store_srt' />
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.props.rtlCreateFunc(itm);
                                        this.handleCancel();
                                    }}
                                >
                                    RTL+
                                </Button>
                            </Space.Compact>
                        );
                    }

                    return (
                        <Button
                            data-qa='button_onSelect_detail_supplier_modal'
                            onClick={() => {
                                if (this.props.onSelect) {
                                    this.props.onSelect({
                                        ...elem,
                                        purchasePrice: _.get(currentSup, '[0].price') || elem.purchasePrice,
                                        price: selectedPrice,
                                        store,
                                        key: this.props.tableKey,
                                        name: elem.itemName
                                        // elem.businessSupplierId,
                                        // elem.businessSupplierName,
                                        // elem.supplierBrandId,
                                        // elem.purchasePrice,
                                        // price,
                                        // store,
                                        // elem.supplierOriginalCode,
                                        // elem.supplierProductNumber,
                                        // elem.supplierPartNumber,
                                        // this.props.tableKey,
                                        // elem.isFromStock,
                                        // elem.defaultWarehouseId,
                                        // elem.id,
                                        // elem.brandId,
                                        // elem.itemName
                                    });
                                } else {
                                    this.props.setStoreSupplier({
                                        ...elem,
                                        price,
                                        store,
                                        key: this.props.keyValue,
                                        name: elem.itemName
                                        // elem.businessSupplierId,
                                        // elem.businessSupplierName,
                                        // elem.supplierBrandId,
                                        // elem.purchasePrice,
                                        // price,
                                        // store,
                                        // elem.supplierOriginalCode,
                                        // elem.supplierProductNumber,
                                        // elem.supplierPartNumber,
                                        // this.props.keyValue,
                                        // elem.isFromStock,
                                        // elem.defaultWarehouseId,
                                        // elem.id
                                    });
                                }
                                this.handleCancel();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='select' />
                        </Button>
                    );
                }
            }
        ];
    }

    handleCancel = () => {
        this.setState({
            fetched: false,
            dataSource: [],
            visible: false,
            productInfo: undefined,
            updatedData: undefined
        });
        if (this.props.hideModal) {
            this.props.hideModal();
        }
    };

    fetchData = async () => {
        const { detailCode: partNumber, brandId, storeGroupId, clientId } = this.props;
        const dataSource = await fetchAPI(
            'GET',
            'business_suppliers/pricelists',
            {
                partNumber,
                brandId,
                storeGroupId,
                clientId
            },
            null,
            { handleErrorInternally: true }
        );

        this.setState({
            fetched: true,
            dataSource: dataSource.filter(({ isFromStock }) => !isFromStock)
        });
    };

    fetchProductInfo = async () => {
        const { productId } = this.props;
        if (productId) {
            const res = await fetchAPI('GET', `/store_products/${productId}`, null, null, {
                handleErrorInternally: true
            });

            this.setState({
                productInfo: res
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.visible && !prevProps.visible) {
            this.fetchData();
            this.setState({
                visible: true
            });
        }

        if (!this.state.productInfo && this.state.visible) {
            this.fetchProductInfo();
        }
    }

    render() {
        const { children } = this.props;
        const { dataSource, updatedData, productInfo } = this.state;
        const { disabled } = this.props;

        const currentSuppliers = dataSource.filter(
            ({ businessSupplierName }) => _.last(_.split(businessSupplierName, ' ', 3)) == 'S'
        );

        const arrayOfSuppliers = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const el of currentSuppliers) {
            const codeNumber =
                el.businessSupplierName == 'Омега S' || el.businessSupplierName == 'Бус Маркет S'
                    ? el.partNumber
                    : el.supplierPartNumber;

            const obj = {
                supplier: el.businessSupplierName,
                brand: el.brandName,
                code: codeNumber,
                withAttributes: true
            };

            arrayOfSuppliers.push(obj);
        }

        return (
            <span>
                {!this.props.hideButton && (
                    <Tooltip
                        placement='bottom'
                        title={<FormattedMessage id='details_table.details_supplier' />}
                        zIndex={2001}
                    >
                        <Button
                            data-qa='button_visible_detail_supplier_modal'
                            disabled={disabled}
                            icon={<DeliveryTruckIcon />}
                            onClick={() => {
                                this.fetchData();
                                this.setState({
                                    visible: true
                                });
                            }}
                        />
                    </Tooltip>
                )}
                {children && (
                    <Tooltip title={<FormattedMessage id='order_form_table.availability_supplier' />} zIndex={9999}>
                        <span
                            onClick={() => {
                                this.fetchData();
                                this.setState({
                                    visible: true
                                });
                            }}
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            {children}
                        </span>
                    </Tooltip>
                )}
                <Modal
                    footer={null}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    title={<FormattedMessage id='order_form_table.supplier' />}
                    visible={this.state.visible}
                    width='85%'
                >
                    {this.state.fetched ? (
                        <React.Fragment>
                            <Button
                                disabled={
                                    !isGrantAccessed(
                                        this.props.user,
                                        grants.OPERATIONS_ORDER_DOCUMENT_PARTS_PRICE_UPDATE_BY_API
                                    )
                                }
                                onClick={async () => {
                                    try {
                                        const updatedData = await fetchAPI(
                                            'POST',
                                            'external_api/get_prices',
                                            undefined,
                                            arrayOfSuppliers,
                                            { handleErrorInternally: true }
                                        );

                                        this.setState({
                                            updatedData
                                        });
                                    } catch (error) {
                                        if (error) {
                                            notification.error({
                                                message: this.props.intl.formatMessage({
                                                    id: 'order_form_table.error_API'
                                                })
                                            });
                                        }
                                    }
                                    await this.fetchData();
                                }}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    marginBottom: 8
                                }}
                            >
                                <FormattedMessage id='order_form_table.check_price_API' />
                            </Button>
                            <Table
                                bordered
                                columns={this.columns}
                                dataSource={this.state.dataSource}
                                pagination={false}
                                rowKey='id'
                                size='small'
                            />
                        </React.Fragment>
                    ) : (
                        <Spin indicator={spinIcon} />
                    )}
                </Modal>
            </span>
        );
    }
}
export default DetailSupplierModal;

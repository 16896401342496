export const LANGUAGES = [
    { countryCode: 'en', label: 'English' },
    { countryCode: 'ua', label: 'Ukrainian' },
    { countryCode: 'bg', label: 'Bulgarian' },
    { countryCode: 'cz', label: 'Czech' },
    { countryCode: 'fr', label: 'French' },
    { countryCode: 'pl', label: 'Polish' }
];

export const DAY_OFF_TYPES = [
    { value: 'day_off', label: 'day_off' },
    { value: 'vacation', label: 'vacation' },
    { value: 'sick_leave', label: 'sick_leave' },
    { value: 'other', label: 'other' }
];

export const HOURS_REGEX_FORMAT = 'HH:mm';
export const DATE_YMD_FORMAT = 'YYYY-MM-DD';
export const DATE_AND_HOUR_FORMAT = 'YYYY-MM-DD HH:mm';
